import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import * as yup from 'yup'
import { useSnapshot } from 'valtio'

import {
  GetUserDocument,
  TipsterStatus,
  UpdateUserDocument,
  UserPermission,
  UserRoles,
  UserStatus
} from '@app/graphql'
import { ROUTES } from '@app/constants'
import { PageLayout } from '@app/components'
import { CreateUserValues, FormFieldDataType, FormFieldType } from '@app/types'
import { DialogResult } from '@app/ui'
import { useCallbackPrompt, useGetPermissions } from '@app/hooks'
import { FormElementCreation } from '@app/components'
import { FormHelper } from '@app/helpers'
import { CurrentUserState } from '@app/storage'

const emailField = 'email'
const displayNameField = 'displayName'
const countryField = 'country'
const roleField = 'role'

interface UserCreatePageProps {
  editMode?: boolean
}

const UserCreatePage: FC<UserCreatePageProps> = ({ editMode }) => {
  const [ blockLeaving, setBlockLeaving ] = useState(true)
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ loading, setLoading ] = useState(true)
  const { userId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const { dialog } = useCallbackPrompt(blockLeaving)
  const { id: currentUserId } = useSnapshot(CurrentUserState)
  const { checkPermission, loading: permsLoading } = useGetPermissions({ userId: currentUserId })
  /* QUERIES AND MUTATIONS */
  const [
    getUser,
    { data: userData, loading: userLoading, error: userError }
  ] = useLazyQuery(GetUserDocument, { fetchPolicy: 'network-only' })
  const [ updateUser ] = useMutation(UpdateUserDocument)

  useEffect(() => {
    if (editMode) getUser({ variables: { id: userId } })
    setLoading(false)
  }, [])

  const onSubmit = async (values: CreateUserValues) => {
    try {
      setLoading(true)
      setBlockLeaving(false)
      if (editMode && userData?.user?.role === UserRoles.User) {
        const newRole = values.role === UserRoles.Tipster ?
          { role: UserRoles.Tipster, tipsterStatus: TipsterStatus.Active } :
          {}
        const { data, errors } = await updateUser({
          variables: {
            id: userData?.user?.id,
            input: {
              emailAddress: values.email,
              displayName: values.displayName,
              countryId: values.country,
              ...newRole,
            },
          },
        })
        if (data?.userUpdate?.id && !errors) {
          setSuccessMessage(t('updatingSuccess'))
        } else {
          throw new Error()
        }
      } else {
        // todo: add creation of new user (awaiting backend)
        throw new Error()
      }
    } catch (e) {
      setBlockLeaving(true)
      setErrorMessage(editMode ? t('updatingFailure') : t('creationFailure'))
    } finally {
      setLoading(false)
    }
  }
  const onCancel = () => navigate(-1)
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(ROUTES.users.short)
    }
  }
  const onChangeStatus = (status: UserStatus) => async (isActiveStatus: boolean) => {
    if (!hasPermStatus) return

    let newStatus = UserStatus.Active
    if (!isActiveStatus) newStatus = status

    try {
      setLoading(true)
      const result = await updateUser({ variables: {
        id: userData.user.id,
        input: { userStatus: newStatus }
      }
      })
      if (result.data.userUpdate.id) await getUser({ variables: { id: userData.user.id } })
    } catch (e) {
      setErrorMessage(t('updatingFailure'))
    } finally {
      setLoading(false)
    }
  }

  const pageTitle = editMode ? t('editUser', { ns: 'pageTitles' }) :
    t('createUser', { ns: 'pageTitles' })
  const title = editMode ? t('editUser') : t('createNewUser')
  const fields: FormFieldDataType[] = [
    {
      name: emailField,
      label: t('email'),
      type: FormFieldType.text,
      initValue: userData?.user?.emailAddress || '',
      validation: FormHelper.getEmailSchemaField(),
      required: true,
    },
    {
      name: displayNameField,
      label: t('displayName'),
      type: FormFieldType.text,
      initValue: userData?.user?.displayName || '',
      validation: FormHelper.getRequiredStringSchema().max(100, t('max100LengthError', { ns: 'forms' })),
      required: true,
    },
    {
      name: countryField,
      label: t('country'),
      type: FormFieldType.country,
      initValue: userData?.user?.countryData?.id || userData?.user?.countryId,
      validation: FormHelper.getRequiredStringSchema(),
      required: true,
    },
    {
      name: roleField,
      label: t('role'),
      type: FormFieldType.select,
      elements: FormHelper.userRoleList,
      initValue: userData?.user?.role,
      validation: yup.string(),
    },
  ]
  const hasPermStatus = !permsLoading && checkPermission(UserPermission.ChangeUserStatus)

  if (editMode && (!userId || userError)) navigate(-1)

  return (
    <PageLayout title={pageTitle} loading={(editMode && userLoading) || loading}>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h4" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ width: '700px' }}>
          <FormElementCreation
            fields={fields}
            state={{
              muted: userData?.user?.userStatus === UserStatus.Muted,
              banned: userData?.user?.userStatus === UserStatus.Banned,
            }}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onBan={hasPermStatus ? onChangeStatus(UserStatus.Banned) : null}
            onMute={hasPermStatus ? onChangeStatus(UserStatus.Muted) : null}
          />
        </Box>
      </Box>

      {/* DIALOG */}
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
      { dialog }
    </PageLayout>
  )
}

export default UserCreatePage
