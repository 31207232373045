import { PermissionItem } from '@app/types'
import { UserPermission } from '@app/graphql'
import i18n from '@app/i18n'

export const TipsterPermissionList: PermissionItem[] = [
  { id: UserPermission.AddSubs, name: i18n.t('addSubscription', { ns: 'forms' }) },
  { id: UserPermission.EditSubs, name: i18n.t('editSubscription', { ns: 'forms' }) },
  { id: UserPermission.AddPromo, name: i18n.t('addPromoCode', { ns: 'forms' }) },
  { id: UserPermission.AddPost, name: i18n.t('addPost', { ns: 'forms' }) },
  { id: UserPermission.EditPost, name: i18n.t('editPost', { ns: 'forms' }) },
  { id: UserPermission.CreateInvite, name: i18n.t('createInviteLink', { ns: 'forms' }) },
  { id: UserPermission.LimitInvites, name: i18n.t('setLimitInvites', { ns: 'forms' }) },
  { id: UserPermission.ChangeUserStatus, name: i18n.t('changeUserStatus', { ns: 'forms' }) },
  { id: UserPermission.CreateTipster, name: i18n.t('createTipster', { ns: 'forms' }) },
  { id: UserPermission.EditTipster, name: i18n.t('editTipster', { ns: 'forms' }) },
  { id: UserPermission.TipsterMessaging, name: i18n.t('tipsterMessaging', { ns: 'forms' }) },
]
