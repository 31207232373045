import { FC, Fragment, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Grid, Box, Button, Link as MatLink, styled, Chip } from '@mui/material'
import parse from 'html-react-parser'

import { Ads, AffilateLinkModel, ChannelModel, UserModel } from '@app/graphql'
import { CalendarHelper } from '@app/helpers'
import { ServiceViewPageData, SubscriptionViewPageData, TableField, TableFieldType } from '@app/types'
import { AvatarImage, DialogConfirm, DialogResult } from '@app/ui'
import { Loader } from '@app/components'
import { CurrentUserState } from '@app/storage'
import { useSnapshot } from 'valtio'

interface ViewPageTemplateProps {
  editRoute: string
  userData?: Partial<Omit<UserModel, 'country'> & { country: string, [TableField.tipsterChannels]?: string[] }>
  advertData?: Partial<Ads>
  channelData?: ChannelModel & { country: string }
  serviceData?: ServiceViewPageData
  affiliateLinkData?: Partial<Omit<AffilateLinkModel, 'channel' | 'users'>> & {
    channelName: string,
    tipsterName?: string,
  }
  subscriptionData?: SubscriptionViewPageData
  dataFields: { name: TableField, label: string, type: TableFieldType }[]
  routeAfterDeletion?: string
  additionalButtons?: ReactNode
  editDisabled?: boolean
  onDelete?: () => void
  onDeletePromise?: () => Promise<boolean>
}

const ViewPageTemplate: FC<ViewPageTemplateProps> = ({
  editRoute,
  userData,
  advertData,
  channelData,
  serviceData,
  affiliateLinkData,
  subscriptionData,
  dataFields,
  routeAfterDeletion,
  additionalButtons,
  editDisabled,
  onDelete,
  onDeletePromise,
}) => {
  const { isAdmin } = useSnapshot(CurrentUserState)
  dataFields = dataFields.filter(field => isAdmin? field : ![
    TableField.id,
    TableField.anonymized,
    TableField.channelType,
  ].includes(field.name))
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ openConfirmation, setOpenConfirmation ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation([ 'translation' ])

  const onBack = () => navigate(-1)
  const onOpenDeletion = () => {
    if (!editDisabled) setOpenConfirmation(true)
  }
  const onCloseDeletion = () => setOpenConfirmation(false)
  const handleDeletion = async () => {
    if (editDisabled) return
    if (onDelete) {
      onDelete()
    } else if (onDeletePromise) {
      try {
        setLoading(true)
        const result = await onDeletePromise()
        if (result) {
          setSuccessMessage(t('deleteSuccess'))
        } else {
          throw new Error()
        }
      } catch (e) {
        setErrorMessage(t('deleteError'))
      } finally {
        setLoading(false)
      }
    }
  }
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success && routeAfterDeletion) {
      navigate(routeAfterDeletion)
    }
  }

  const transformData = (value: unknown, type: TableFieldType): string | ReactNode => {
    switch (type) {
      case TableFieldType.bool:
        return value ? t('YES') : t('NO')
      case TableFieldType.date:
        return CalendarHelper.isDate(new Date(value as string)) ?
          CalendarHelper.getDateStringWithMonthName(new Date(value as string)) :
          t('None')
      case TableFieldType.externalLink:
        if (!value) return t('None')
        return (
          <a href={value as string} target="_blank" className="vb-font not-decor">
            <MatLink component="button">{value as string}</MatLink>
          </a>
        )
      case TableFieldType.num:
        return value?.toString() as string || '0'
      case TableFieldType.chips:
        return (
          <StyledChipContainer>
            {
              (value as string[])?.map(v => <Chip label={v} key={v}/>)
            }
          </StyledChipContainer>
        )
      case TableFieldType.profileBackground:
        return (
          value && typeof value === 'string' ?
            <img src={value as string} alt="Background" width={360} height={120}/> :
            t('None')
        )
      case TableFieldType.html:
        return (
          value && typeof value ==='string'?
            <Box>{parse(value)}</Box> :
            t('None')
        )
      default:
        return value as string || t('None')
    }
  }

  const data = userData || advertData || channelData || serviceData || affiliateLinkData || subscriptionData

  if (!data) return (<div></div>)

  if (loading) return (
    <StyledLoader className="vb-flex-row-c-c">
      <Loader/>
    </StyledLoader>
  )

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {
          userData ?
            <>
              <Grid item xs={3} className="vb-flex-row-fe-c">
                <AvatarImage altText={t('profilePhoto')} src={userData.avatarUrl}/>
              </Grid>
              <Grid item xs={9} className="vb-flex-row-fs-c vb-font xBig w700 black">
                {userData.firstName} {userData.lastName}
              </Grid>
            </>:
            null
        }
        {
          userData ?
            <>
              <Grid item xs={3} className="vb-flex-row-fe-c vb-font normal black w700">
                {t('alternativeAvatar')}
              </Grid>
              <Grid item xs={9} className="vb-flex-row-fs-c">
                <AvatarImage altText={t('profilePhoto')} src={userData.alternativeAvatarUrl}/>
              </Grid>
            </> :
            null
        }
        {
          advertData?.imageUrl ?
            <>
              <Grid item xs={3} className="vb-flex-row-fe-c vb-font normal black w700">
                {t('advertBanner')}
              </Grid>
              <Grid item xs={9} className="vb-flex-row-fs-c">
                <img src={advertData.imageUrl} alt="Ad Banner" width={200} height={50}/>
              </Grid>
            </> :
            null
        }
        {
          channelData?.logo ?
            <>
              <Grid item xs={3} className="vb-flex-row-fe-c">
                <img className="vb-circle" src={channelData.logo} alt="Logo" width={80} height={80}/>
              </Grid>
              <Grid item xs={9}></Grid>
            </> :
            null
        }
        {
          serviceData?.imageLink ?
            <>
              <Grid item xs={3}></Grid>
              <Grid item xs={9} className="vb-flex-row-fs-c">
                <img src={serviceData.imageLink} alt="Service Image" width={360} height={280}/>
              </Grid>
            </> :
            null
        }
        {
          data &&
          dataFields.map(field => (
            <Fragment key={field.name}>
              <Grid item xs={3} className="vb-flex-row-fe-c vb-font normal black w700">
                {t(field.label)}
              </Grid>
              <Grid item xs={9} className="vb-flex-row-fs-c vb-font normal black">
                {transformData(data[field.name], field.type)}
              </Grid>
            </Fragment>
          ))
        }
        <Grid item xs={3} sx={{ mt: 2 }}/>
        <Grid item xs={9} className="vb-flex-row-fs-c" sx={{ mt: 2 }}>
          <Button variant="contained" color="secondary" onClick={onBack}>
            {t('buttonBack')}
          </Button>
          {
            isAdmin? (
              <>
                <Box sx={{ ml: 2, mr: 2 }}>
                  <Link to={editDisabled ? '' : editRoute} className="vb-font not-decor">
                    <Button variant="contained" disabled={editDisabled}>
                      {t('buttonEdit')}
                    </Button>
                  </Link>
                </Box>
                <Button
                  variant="contained"
                  color="error"
                  disabled={editDisabled}
                  onClick={onOpenDeletion}
                >
                  {t('buttonDelete')}
                </Button>
              </>
            ): null}
          { additionalButtons || null }
        </Grid>
      </Grid>

      {/* DIALOG */}
      <DialogConfirm
        open={openConfirmation}
        header={t('attention')}
        message={t('deleteConfirmation')}
        onCancel={onCloseDeletion}
        onConfirm={handleDeletion}
      />
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
    </>
  )
}

const StyledChipContainer = styled(Box)`
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: .5rem;
`

const StyledLoader = styled(Box)`
  width: 100%;
  height: calc(100vh - 80px);
`

export default ViewPageTemplate
