import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import * as yup from 'yup'

import { FormElementCreation, PageLayout } from '@app/components'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  AdsCreationInput,
  AdsUpdate,
  CreateAdvertDocument,
  GetAdvertByIdDocument,
  Placement,
  UpdateAdvertDocument,
  UploadImageDocument
} from '@app/graphql'
import { CreateAdvertValues, FormFieldDataType, FormFieldType } from '@app/types'
import { ROUTES } from '@app/constants'
import { DialogResult } from '@app/ui'
import { useCallbackPrompt } from '@app/hooks'
import { FormHelper } from '@app/helpers'

const titleField = 'title'
const imageField = 'imageFile'
const placementField = 'placement'
const startDateField = 'startDate'
const endDateField = 'endDate'
const referralField = 'referral'
const visibleField = 'visible'
const activeField = 'active'
const advertTargetField = 'advertTarget'

interface AdvertCreatePageProps {
  editMode?: boolean
}

const AdvertCreatePage: FC<AdvertCreatePageProps> = ({ editMode }) => {
  const [ blockLeaving, setBlockLeaving ] = useState(true)
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ loading, setLoading ] = useState(false)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const navigate = useNavigate()
  const { advertId } = useParams()
  const { dialog } = useCallbackPrompt(blockLeaving)
  const [
    getAdvert,
    { data: advertData, loading: advertLoading }
  ] = useLazyQuery(GetAdvertByIdDocument, { fetchPolicy: 'network-only' })
  const [ uploadImage ] = useMutation(UploadImageDocument, { context: { operation: 'imageUpload' } })
  const [ createAdvert ] = useMutation(CreateAdvertDocument)
  const [ updateAdvert ] = useMutation(UpdateAdvertDocument)

  useEffect(() => {
    if (editMode) getAdvert({ variables: { id: advertId } })
  }, [])

  const onCancel = () => navigate(-1)
  const onSubmit = async (values: CreateAdvertValues) => {
    values.advertTarget = values?.advertTarget?.filter(t => t)
      .map(({ channelId, countryIds }) => ({ channelId, countryIds }))
    try {
      if(!values.advertTarget?.length){
        throw Error()
      }
      setLoading(true)
      setBlockLeaving(false)
      let resultId
      let path
      if (values.imageFile) {
        const uploadResult = await uploadImage({ variables: { image: values.imageFile } })
        path = uploadResult?.data?.imageUpload
      }
      const input: AdsUpdate = {
        expirationDate: values.endDate,
        isActive: values.active,
        placement: values.placement as Placement[],
        referral: values.referral,
        startDate: values.startDate,
        title: values.title,
        visible: values.visible,
        targets: values.advertTarget,
      }
      if (path) {
        input.imageUrl = path
      }
      if (editMode) {
        const updateResult = await updateAdvert({ variables: { input, id: advertId } })
        resultId = updateResult.data.adsUpdate.id
      } else {
        const createAdsInput = { ...input  } as AdsCreationInput
        const createResult = await createAdvert({ variables: { input: createAdsInput } })
        resultId = createResult.data.adsCreate.id
      }

      if (!resultId) throw new Error()

      setSuccessMessage(editMode ? t('updatingSuccess') : t('creationSuccess'))
    } catch (e) {
      setBlockLeaving(true)
      setErrorMessage(
        editMode ?
          t('updatingFailure', {
            message: !values.advertTarget?.length? 'Ad Target requred' : '',
          }) :
          t('creationFailure')
      )
    } finally {
      setLoading(false)
    }
  }
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(ROUTES.adverts.short)
    }
  }

  const pageTitle = editMode ? t('editAdvert', { ns: 'pageTitles' }) :
    t('createAdvert', { ns: 'pageTitles' })
  const title = editMode ? t('editAdvert') : t('createNewAdvert')
  const fields: FormFieldDataType[] = [
    {
      name: titleField,
      label: t('title'),
      type: FormFieldType.text,
      initValue: advertData?.adsById?.title || '',
      validation: FormHelper.getRequiredStringSchema()
        .max(100, t('max100LengthError', { ns: 'forms' })),
      required: true,
    },
    {
      name: imageField,
      label: t('uploadBanner'),
      type: FormFieldType.image,
      initValue: null,
      validation: yup.mixed(),
      required: true,
      image: {
        link: advertData?.adsById?.imageUrl,
        alt: 'Ad Banner',
        width: 200,
        height: 50,
      },
    },
    {
      name: placementField,
      label: t('placement'),
      type: FormFieldType.select,
      elements: useMemo(
        () => Object.values(Placement).map((value) => ({ value })), []
      ),
      initValue: advertData?.adsById?.placement || [],
      validation: yup.array().of(yup.string()).min(1, t('requiredError', { ns: 'forms' })),
      required: true,
      multiple: true,
    },
    {
      name: startDateField,
      label: t('startDate'),
      type: FormFieldType.datepicker,
      initValue: new Date(advertData?.adsById?.startDate || new Date()),
      validation: yup.date().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: endDateField,
      label: t('endDate'),
      type: FormFieldType.datepicker,
      initValue: new Date(advertData?.adsById?.endDate || new Date()),
      validation: yup.date().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: advertTargetField,
      label: t('advert target'),
      type: FormFieldType.advertTarget,
      initValue: advertData?.adsById?.targets ?? [],
      required: true,
      validation: yup.mixed(),
    },
    {
      name: referralField,
      label: t('referralLink'),
      type: FormFieldType.text,
      initValue: advertData?.adsById?.referral || '',
      validation: yup.string(),
    },
    {
      name: visibleField,
      label: t('visibleToTipster'),
      type: FormFieldType.checkbox,
      initValue: advertData?.adsById?.visible || false,
      validation: yup.boolean(),
    },
    {
      name: activeField,
      label: t('active'),
      type: FormFieldType.checkbox,
      initValue: advertData?.adsById?.active || false,
      validation: yup.boolean(),
    },
  ]

  return (
    <PageLayout
      title={pageTitle}
      loading={loading || advertLoading}
    >
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h4" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ width: '700px' }}>
          <FormElementCreation
            fields={fields}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </Box>
      </Box>

      {/* DIALOG */}
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
      { dialog }
    </PageLayout>
  )
}

export default AdvertCreatePage
