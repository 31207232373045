import { FC } from 'react'
import { FormControl, InputLabel, Select, MenuItem, SxProps, FormHelperText } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ALL_VALUES } from '@app/constants'
import { useFormik } from 'formik'
import { SelectElementType } from '@app/types'

interface FormikSelectProps {
  elements: SelectElementType[]
  fieldName: string
  formik: ReturnType<typeof useFormik<any>>
  label: string
  sxProps?: SxProps
  withAllValue?: boolean
  multiple?: boolean
  disabled?: boolean
  required?: boolean
}

const FormikSelect: FC<FormikSelectProps> = ({
  elements,
  fieldName,
  formik,
  label,
  sxProps,
  withAllValue,
  multiple,
  disabled,
  required,
}) => {
  const { t } = useTranslation([ 'translation', 'forms' ])

  const hasError = formik.touched[fieldName] && Boolean(formik.errors[fieldName])

  return (
    <FormControl
      fullWidth
      sx={sxProps}
      error={hasError}
      disabled={disabled}
    >
      <InputLabel id={fieldName}>
        {t(label) + (required ? '*' : '')}
      </InputLabel>
      <Select
        labelId={fieldName}
        label={t(label)}
        name={fieldName}
        value={formik.values[fieldName]}
        multiple={multiple}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        {
          withAllValue &&
          <MenuItem value={ALL_VALUES}>
            {t('All')}
          </MenuItem>
        }
        {
          elements.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item?.label || item.value}
            </MenuItem>
          ))
        }
      </Select>
      {
        hasError &&
        <FormHelperText>
          {t(formik.errors[fieldName] as string)}
        </FormHelperText>
      }
    </FormControl>
  )
}

export default FormikSelect
