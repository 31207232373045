import { FC } from 'react'
import { AccountCircle } from '@mui/icons-material'

interface AvatarImageProps {
  altText?: string
  src?: string
}

const AvatarImage: FC<AvatarImageProps> = ({ altText, src }) => {
  return (
    <>
      {
        src ?
          <img className="vb-circle" src={src} alt={altText} width={80} height={80}/> :
          <AccountCircle sx={{ fontSize: 80 }} color="primary"/>
      }
    </>
  )
}

export default AvatarImage
