import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useSnapshot } from 'valtio'

import { PageLayout } from '@app/components'
import FreeTrialCreateForm from './FreeTrialCreateForm'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CreatePriceDocument,
  CreateServiceDurationDocument,
  CreateSubscriptionDocument,
  GetCurrenciesDocument,
  GetSubscriptionByIdDocument,
  SubscriptionCreateInput,
  SubscriptionUpdateInput,
  UpdateSubscriptionDocument,
  UserPermission,
} from '@app/graphql'
import { CreateFreeTrialValues } from '@app/types'
import { ROUTES } from '@app/constants'
import { DialogResult } from '@app/ui'
import { CurrentUserState } from '@app/storage'
import { useGetPermissions } from '@app/hooks'

interface FreeTrialCreatePageProps {}

const FreeTrialCreatePage: FC<FreeTrialCreatePageProps> = () => {
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ loading, setLoading ] = useState(false)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const navigate = useNavigate()
  const { serviceId, subscriptionId } = useParams()
  const { id: currentUserId } = useSnapshot(CurrentUserState)
  const { checkPermission, loading: permsLoading } = useGetPermissions({ userId: currentUserId })
  /* QUERIES AND MUTATIONS */
  const [
    getSubscription,
    { data: subscriptionData, loading: subscriptionLoading },
  ] = useLazyQuery(GetSubscriptionByIdDocument, { fetchPolicy: 'network-only' })
  const [ getCurrencies ] = useLazyQuery(GetCurrenciesDocument)
  const [ createPrice ] = useMutation(CreatePriceDocument)
  const [ createDuration ] = useMutation(CreateServiceDurationDocument)
  const [ createSubscription ] = useMutation(CreateSubscriptionDocument)
  const [ updateSubscription ] = useMutation(UpdateSubscriptionDocument)

  useEffect(() => {
    if (subscriptionId) getSubscription({ variables: { id: subscriptionId } })
  }, [])

  const onCancel = () => navigate(-1)
  const submitDisabled = (
    subscriptionId ? !checkPermission(UserPermission.EditSubs) : !checkPermission(UserPermission.AddSubs)
  ) || permsLoading
  const onSubmit = async (values: CreateFreeTrialValues) => {
    if (submitDisabled) return
    try {
      setLoading(true)
      let success

      const input: SubscriptionUpdateInput = {
        freeTrialPeriod: values.freeTrialPeriod,
      }
      if (subscriptionId) {
        const result = await updateSubscription({
          variables: {
            id: subscriptionId,
            input,
          },
        })
        success = result.data.subscriptionUpdate
      } else {
        const resultCurrencies = await getCurrencies({ variables: { limit: 10000, skip: 0 } })
        const currencyId = resultCurrencies.data.currencies.edges[0].node.id
        const [ resultPrice, resultDuration ] = await Promise.all([
          createPrice({ variables: { input: { price: 0, currencyId } } }),
          createDuration({ variables: { input: { duration: 0 } } })
        ])
        input.serviceId = serviceId
        input.priceId = resultPrice.data.priceCreate.id
        input.serviceDurationId = resultDuration.data.serviceDurationCreate.id
        const result = await createSubscription({ variables: { input: input as SubscriptionCreateInput } })
        success = Boolean(result.data.subscriptionCreate.id)
      }

      if (!success) throw new Error()
      setSuccessMessage(subscriptionId ? t('updatingSuccess') : t('creationSuccess'))
    } catch (e) {
      setErrorMessage(subscriptionId ? t('updatingFailure') : t('creationFailure'))
    } finally {
      setLoading(false)
    }
  }
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(ROUTES.services.short)
    }
  }

  const title = subscriptionId ? t('editFreeTrialSub') : t('createNewFreeTrialSub')
  const initialValues: CreateFreeTrialValues = subscriptionData?.subscription ? {
    freeTrialPeriod: subscriptionData.subscription.freeTrialPeriod,
  } : null

  return (
    <PageLayout
      title={title}
      loading={loading || subscriptionLoading}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" component="h4" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ width: '70%' }}>
          <FreeTrialCreateForm
            initialValues={initialValues}
            submitDisabled={submitDisabled}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </Box>
      </Box>

      {/* DIALOG */}
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
    </PageLayout>
  )
}

export default FreeTrialCreatePage
