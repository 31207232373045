import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { ActionsBlock, FormikTextField } from '@app/ui'
import { CreateFreeTrialValues } from '@app/types'

const freeTrialPeriodField = 'freeTrialPeriod'

interface FreeTrialCreateFormProps {
  initialValues?: CreateFreeTrialValues
  submitDisabled?: boolean
  onSubmit: (values: CreateFreeTrialValues) => void
  onCancel: () => void
}

const FreeTrialCreateForm: FC<FreeTrialCreateFormProps> = ({
  initialValues,
  submitDisabled,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation([ 'translation', 'forms' ])
  const formik = useFormik({
    initialValues: {
      [freeTrialPeriodField]: initialValues?.freeTrialPeriod || 0,
    },
    validationSchema: yup.object().shape({
      // [freeTrialPeriodField]: yup.number().min(1, t('minNumberOneError', { ns: 'forms' })),
    }),
    onSubmit: values => onSubmit(values),
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormikTextField
        fieldName={freeTrialPeriodField}
        formik={formik}
        label={t('freeTrialPeriod') + ' (' + t('days') + ')'}
        sxProps={{ mb: 1 }}
        type="number"
        required
      />
      <ActionsBlock
        withSubmit
        submitDisabled={!formik.isValid || !formik.dirty || submitDisabled}
        onCancel={onCancel}
      />
    </form>
  )
}

export default FreeTrialCreateForm
