import { FC, KeyboardEvent,ChangeEvent } from 'react'
import { FormControl, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'

interface SearchProps {
  handleKeyDown?: (event: KeyboardEvent<HTMLInputElement>)=> void
  onChangeSearch?: (event: ChangeEvent<HTMLInputElement>)=>void
  label?:string
}

const SearchField: FC<SearchProps> = ({ handleKeyDown, onChangeSearch, label }) => (
  <FormControl fullWidth>
    <TextField
      id="outlined-basic"
      label={label}
      variant="outlined"
      onKeyDown={handleKeyDown}
      onChange={onChangeSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}/>
  </FormControl>
)


export default SearchField
