import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'

import { PageLayout, TableTemplate } from '@app/components'
import { ROUTES, TableAdvertKeyField } from '@app/constants'
import { CalendarHelper, TableHelper } from '@app/helpers'
import {
  AdsFilter,
  AdsSort,
  AdsUpdate,
  GetAdvertsDocument,
  Placement,
  SortMethod,
  UpdateAdvertDocument,
} from '@app/graphql'
import {
  DualSelectOption,
  TableChangeItemValue,
  TableField,
  TableFilterData,
  TableFilterFlags,
  TableSelectElements
} from '@app/types'

enum FilterStatuses {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
}

type UpdatedAdsSort = Omit<AdsSort, 'expirationDate' | 'imageUrl'> & { endDate?: SortMethod, active?: SortMethod }

interface AdvertListPageProps {}

const AdvertListPage: FC<AdvertListPageProps> = () => {
  const [ filterOptions, setFilterOptions ] = useState<TableFilterData>({})
  const [ sortOptions, setSortOptions ] = useState<UpdatedAdsSort>(null)
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ loading, setLoading ] = useState(false)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const [
    getAdverts,
    { data: advertData, loading: advertLoading }
  ] = useLazyQuery(GetAdvertsDocument, { fetchPolicy: 'network-only' })
  const [ updateAdvert ] = useMutation(UpdateAdvertDocument)

  useEffect(() => {
    getAdverts({
      variables: {
        sort: prepareSort(sortOptions),
        filter: prepareFilter(filterOptions),
        ...getPagination(),
      },
    })
  }, [ filterOptions, sortOptions, pageNumber, rowsPerPage ])

  const getPagination = () => {
    const row = localStorage.getItem('per_page')
    const letLimit = Number(row) ?? rowsPerPage
    return { limit: letLimit, skip: pageNumber * rowsPerPage }
  }
  const prepareFilter = (filter: TableFilterData): AdsFilter => ({
    isActive: filter.status ?
      filter.status === FilterStatuses.active :
      null,
  })
  const prepareSort = (sort: UpdatedAdsSort): AdsSort => {
    if (!sort) return null
    const preparedSort = {
      ...sort,
      expirationDate: sort.endDate,
      isActive: sort.active,
    }
    delete preparedSort.endDate
    delete preparedSort.active
    return preparedSort
  }
  const onPaginate = (page: number, rowsPage: number) => {
    setPageNumber(page)
    setRowsPerPage(rowsPage)
  }
  const onFilter = (filter: TableFilterData = {}) => setFilterOptions(filter)
  const onSort = (sort: UpdatedAdsSort) => setSortOptions(sort)
  const onChange: TableChangeItemValue = async (field, id, newValue) => {
    try {
      if (!field || !id) return
      setLoading(true)
      let input: AdsUpdate = null
      switch (field) {
        case TableField.visible:
          input = { visible: newValue === DualSelectOption.yes }
          break
        case TableField.active:
          input = { isActive: newValue === DualSelectOption.yes }
          break
        case TableField.placement:
          input = { placement: newValue as Placement[] }
          break
        case TableField.startDate:
          if (CalendarHelper.isDate(newValue)) {
            input = { startDate: CalendarHelper.getDayAsString(newValue) }
          }
          break
        case TableField.endDate:
          if (CalendarHelper.isDate(newValue)) {
            input = { expirationDate: CalendarHelper.getDayAsString(newValue) }
          }
          break
      }
      if (input) {
        const result = await updateAdvert({
          variables: {
            id,
            input,
          },
        })
        if (result.data.adsUpdate.id) {
          getAdverts({
            variables: {
              filter: prepareFilter(filterOptions),
              sort: prepareSort(sortOptions),
              ...getPagination(),
            },
          })
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const columns = useMemo(() => TableHelper.parseAdvertFields(), [])
  const selectElements: TableSelectElements = {
    elements: {
      [TableField.visible]: [ { value: DualSelectOption.yes }, { value: DualSelectOption.no } ],
      [TableField.active]: [ { value: DualSelectOption.yes }, { value: DualSelectOption.no } ],
      [TableField.placement]: Object.values(Placement).map(p => ({ value: p })),
    },
    dualOption: {
      [TableField.visible]: true,
      [TableField.active]: true,
    },
    multiple: {
      [TableField.placement]: true,
    },
  }
  const filterFlags: TableFilterFlags = { status: true }

  return (
    <PageLayout
      title={t('advertList', { ns: 'pageTitles' })}
      loading={advertLoading || loading}
    >
      <TableTemplate
        columns={columns}
        rows={advertData?.ads?.ads}
        selectElements={selectElements}
        fieldKey={TableAdvertKeyField}
        title={t('advertList')}
        createText={t('buttonCreateAdvert')}
        totalAmount={advertData?.ads?.count}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        createRoute={ROUTES.advertCreate.full}
        filterFlags={filterFlags}
        filterOptions={filterOptions}
        statuses={Object.values(FilterStatuses)}
        sortOptions={sortOptions}
        onSort={onSort}
        onFilter={onFilter}
        onPaginate={onPaginate}
        onChange={onChange}
      />
    </PageLayout>
  )
}

export default AdvertListPage
