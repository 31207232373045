import { FC, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import {
  ChannelFilterInput,
  ChannelSortInput,
  ChannelStatus,
  ChannelUpdate,
  GetChannelsDocument,
  UpdateChannelDocument
} from '@app/graphql'
import { PageLayout, TableTemplate } from '@app/components'
import { TableHelper } from '@app/helpers'
import { ROUTES, TableChannelKeyField, TableChannelSortingFields } from '@app/constants'
import {
  DualSelectOption,
  TableChangeItemValue,
  TableField,
  TableFilterData,
  TableFilterFlags,
  TableSelectElements
} from '@app/types'
import { useGetCountryById } from '@app/hooks'
import { CurrentUserState } from '@app/storage'
import { useSnapshot } from 'valtio'

interface ChannelListPageProps {}

const ChannelListPage: FC<ChannelListPageProps> = () => {
  const [ loading, setLoading ] = useState(false)
  const [ filterOptions, setFilterOptions ] = useState<TableFilterData>({})
  const [ sortOptions, setSortOptions ] = useState<ChannelSortInput>({})
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ pageNumber, setPageNumber ] = useState(0)
  const { loading: countriesLoading, getCountryById } = useGetCountryById()
  const [
    getChannels,
    { data: channelsData, loading: channelsLoading },
  ] = useLazyQuery(GetChannelsDocument, { fetchPolicy: 'no-cache' })
  const [ updateChannel ] = useMutation(UpdateChannelDocument)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const { isAdmin } = useSnapshot(CurrentUserState)
  const { id: userId } = useSnapshot(CurrentUserState)

  useEffect(() => {
    getChannels({
      variables: {
        filter: prepareFilter(filterOptions),
        sort: sortOptions,
        ...getPagination(),
      },
    })
  }, [ filterOptions, sortOptions, pageNumber, rowsPerPage ])

  const onSort = (sort: ChannelSortInput = {}) => setSortOptions(sort)
  const onFilter = (filter: TableFilterData = {}) => setFilterOptions(filter)
  const onPaginate = (page: number, rowsPage: number) => {
    setPageNumber(page)
    setRowsPerPage(rowsPage)
  }
  const onChange: TableChangeItemValue = async (field, id, newValue) => {
    try {
      if (!field || !id) return
      setLoading(true)
      let input: ChannelUpdate = null
      switch (field) {
        case TableField.channelStatus:
          input = { channelStatus: newValue as ChannelStatus }
          break
      }
      if (input) {
        const result = await updateChannel({
          variables: {
            channelId: id,
            input,
          },
        })
        if (result.data.channelUpdate.updated) {
          getChannels({
            variables: {
              filter: prepareFilter(filterOptions),
              sort: sortOptions,
              ...getPagination(),
            },
          })
        }
      }
    } finally {
      setLoading(false)
    }
  }
  const prepareFilter = (filter: TableFilterData): ChannelFilterInput => ({
    countryId: filter.countryId || null,
    tipsterId: isAdmin? null: userId,
    channelName: filter.channelName || null,
    channelStatus: filter.status as ChannelStatus || null,
    anonymized: filter.anonymity ?
      filter.anonymity === DualSelectOption.yes :
      null
  })

  const getPagination = () => {
    const row = localStorage.getItem('per_page')
    const letLimit = Number(row) ?? rowsPerPage
    return { limit: letLimit, skip: pageNumber * rowsPerPage }
  }

  const columns = useMemo(() => TableHelper.parseChannelFields(isAdmin), [])
  const rows = useMemo(() => {
    return channelsData?.channels?.channels?.map(ch => {
      return ({
        ...ch,
        country: getCountryById(ch.countryId),
        tipsterCount: ch.tipsterCount ?? 0,
        usersCount: ch.usersCount ?? 0,
      })
    }) || []
  }, [ channelsData, countriesLoading ])
  const selectElements: TableSelectElements = {
    elements: {
      [TableField.channelStatus]: Object.values(ChannelStatus).map(s => ({ value: s })),
    },
  }
  const filterFlags: TableFilterFlags = {
    country: true,
    status: true,
    anonymity: true,
    search: true
  }

  return (
    <PageLayout
      title={t('channelList', { ns: 'pageTitles' })}
      loading={channelsLoading || countriesLoading || loading}
    >
      <TableTemplate
        columns={columns}
        rows={rows}
        selectElements={selectElements}
        fieldKey={TableChannelKeyField}
        title={t('channelList')}
        createText={t('buttonCreateChannel')}
        resetText={t('buttonReset')}
        createRoute={ROUTES.channelCreate.full}
        sortOptions={sortOptions}
        sortingFields={TableChannelSortingFields}
        filterFlags={filterFlags}
        filterOptions={filterOptions}
        statuses={Object.values(ChannelStatus)}
        totalAmount={channelsData?.channels?.count}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        onSort={onSort}
        onFilter={onFilter}
        onPaginate={onPaginate}
        onChange={onChange}
      />
    </PageLayout>
  )
}

export default ChannelListPage
