import { FC, MouseEvent, useRef, useState, memo, useMemo } from 'react'
import { Box, IconButton, InputAdornment, TextField, ClickAwayListener, styled } from '@mui/material'
import { CalendarMonth } from '@mui/icons-material'

import Calendar from './Calendar'
import { CalendarHelper } from '@app/helpers'

interface DatepickerProps {
  name?: string
  label?: string
  value: Date
  startDate?: Date
  endDate?: Date
  onSelect: (date: Date) => void
}

const Datepicker: FC<DatepickerProps> = ({
  name = '',
  label = '',
  value,
  startDate,
  endDate,
  onSelect,
}) => {
  const [ openCalendar, setOpenCalendar ] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const onToggleCalendar = () => setOpenCalendar(prev => !prev)
  const onCloseCalendar = () => setOpenCalendar(false)
  const onPreventMouseDown = (e: MouseEvent<HTMLButtonElement>) => e.preventDefault()
  const onSelectDate = (d: Date) => {
    onSelect(d)
    onCloseCalendar()
  }

  const { top, bottom } = useMemo(() => {
    if (inputRef?.current) {
      const rectangle = inputRef.current.getBoundingClientRect()
      const bottomOffset = window?.innerHeight - rectangle?.bottom
      if (bottomOffset > 310 || rectangle?.bottom < 0) {
        return { top: inputRef.current.offsetHeight, bottom: 0 }
      }
      return { top: 0, bottom: inputRef.current.offsetHeight }
    }
    return { top: 56, bottom: 0 }
  }, [ inputRef?.current?.getBoundingClientRect()?.bottom ])

  return (
    <ClickAwayListener onClickAway={onCloseCalendar}>
      <Box sx={{ position: 'relative' }}>
        <TextField
          fullWidth
          name={name}
          label={label}
          value={CalendarHelper.getDateStringWithMonthName(value)}
          type="text"
          inputRef={inputRef}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="calendar datepicker"
                  edge="end"
                  onMouseDown={onPreventMouseDown}
                >
                  <CalendarMonth/>
                </IconButton>
              </InputAdornment>
            )
          }}
          onClick={onToggleCalendar}
        />
        {
          openCalendar &&
          <StyledCalendar $top={top} $bottom={bottom} sx={{ zIndex: 'tooltip' }}>
            <Calendar
              value={CalendarHelper.beginningOfDay(value || new Date())}
              startDate={startDate}
              endDate={endDate}
              width={inputRef?.current?.offsetWidth < 250 ? 250 : inputRef?.current?.offsetWidth}
              onSelect={onSelectDate}
            />
          </StyledCalendar>
        }
      </Box>
    </ClickAwayListener>
  )
}

const StyledCalendar = styled(Box)<{ $top: number, $bottom: number }>`
  position: absolute;
  ${p => p.$top ? `top: ${p.$top}px;` : ''}
  ${p => p.$bottom ? `bottom: ${p.$bottom}px;` : ''}
`

export default memo(Datepicker)
