import { FC, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  GetSubsByTipsterAndChannelDocument,
  GetUsersByIdsDocument,
  ServiceSubscriptionStatus,
  UpdateUserDocument,
  UserStatus
} from '@app/graphql'
import { PageLayout, TableTemplate } from '@app/components'
import { TableHelper } from '@app/helpers'
import { SEARCH_PARAMS, TableUserKeyField } from '@app/constants'
import { TableChangeItemValue, TableField } from '@app/types'
import { CurrentUserState } from '@app/storage'
import { useSnapshot } from 'valtio'

interface UserListPageProps {}

const UserListPage: FC<UserListPageProps> = () => {
  const [ loading, setLoading ] = useState(false)
  const [ pagination, setPagination ] = useState({ rowsPerPage: 10, pageNumber: 0 })
  const [ totalCount, setTotalCount ] = useState(0)
  const [ tipsterId, setTipsterId ] = useState<string>(null)
  const [ channelId, setChannelId ] = useState<string>(null)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const [ searchParams ] = useSearchParams()
  const navigate = useNavigate()
  const [ getSubs ] = useLazyQuery(GetSubsByTipsterAndChannelDocument, { fetchPolicy: 'network-only' })
  const [
    getUsers,
    { data: usersData, loading: usersLoading }
  ] = useLazyQuery(GetUsersByIdsDocument, { fetchPolicy: 'network-only' })
  const [ updateUser ] = useMutation(UpdateUserDocument)
  const { isAdmin } = useSnapshot(CurrentUserState)

  useEffect(() => {
    setLoading(true)
    const tipsterIdParam = searchParams.get(SEARCH_PARAMS.FINANCIAL_TIPSTER)
    const channelIdParam = searchParams.get(SEARCH_PARAMS.FINANCIAL_CHANNEL)

    if (tipsterIdParam && channelIdParam) {
      setTipsterId(tipsterIdParam)
      setChannelId(channelIdParam)
    } else {
      navigate(-1)
    }
  }, [])

  useEffect(() => {
    loadList()
  }, [ tipsterId, channelId, pagination ])

  const loadList = async () => {
    try {
      setLoading(true)
      if (tipsterId && channelId) {
        const response = await getSubs({
          variables: {
            channelId,
            tipsterId,
            statuses: [ ServiceSubscriptionStatus.Active ],
          },
        })
        const ids = response.data.serviceSubscriptionsByChannelIdAndTipserId.edges.map(({ node }) => node.userId)
        getUsers({
          variables: {
            userIds: ids,
          },
        })
        setTotalCount(response.data.serviceSubscriptionsByChannelIdAndTipserId.pageInfo.totalCount)
      }
    } finally {
      setLoading(false)
    }
  }


  const onPaginate = (page: number, rowsPage: number) => setPagination({ rowsPerPage: rowsPage, pageNumber: page })
  const onChange: TableChangeItemValue = async (field, id, newValue) => {
    try {
      if (!field || !id) return
      setLoading(true)
      if (field === TableField.userStatus) {
        const result = await updateUser({
          variables: {
            id,
            input: { userStatus: newValue as UserStatus }
          },
        })
        if (result.data.userUpdate.id) {
          await loadList()
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const columns = useMemo(() => TableHelper.parseUserFields(isAdmin), [])
  const rows = useMemo(() => {
    if (usersData?.usersByIds) {
      return usersData.usersByIds.map(user => ({ ...user, country: user.countryData?.name }))
    }
    return []
  }, [ usersData ])
  const selectElements = {
    elements: { [ TableField.userStatus ]: Object.values(UserStatus).map(s => ({ value: s })) }
  }

  return (
    <PageLayout title={t('userList', { ns: 'pageTitles' })} loading={usersLoading || loading}>
      <TableTemplate
        columns={columns}
        rows={rows}
        selectElements={selectElements}
        fieldKey={TableUserKeyField}
        title={t('userListWithSub')}
        sortingFields={{}}
        statuses={Object.values(UserStatus)}
        totalAmount={totalCount}
        pageNumber={pagination.pageNumber}
        rowsPerPage={pagination.rowsPerPage}
        onPaginate={onPaginate}
        onChange={onChange}
      />
    </PageLayout>
  )
}

export default UserListPage
