import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { ROUTES } from '@app/constants'
import AffiliateLinkListPage from './AffiliateLinkListPage'
import AffiliateLinkCreatePage from './AffiliateLinkCreatePage'
import AffiliateLinkViewPage from './AffiliateLinkViewPage'

interface AffiliateLinkRoutesProps {}

const AffiliateLinkRoutes: FC<AffiliateLinkRoutesProps> = () => (
  <Routes>
    <Route index element={<AffiliateLinkListPage/>}/>
    <Route path={ROUTES.affiliateLinkEdit.short} element={<AffiliateLinkCreatePage editMode/>}/>
    <Route path={ROUTES.affiliateLinkCreate.short} element={<AffiliateLinkCreatePage/>}/>
    <Route path={ROUTES.affiliateLinkViewShort} element={<AffiliateLinkViewPage/>}/>
  </Routes>
)

export default AffiliateLinkRoutes
