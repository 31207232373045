import { FC, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { UpdateUserDocument,UserRoles, UserStatus } from '@app/graphql'
import { PageLayout, TableTemplate } from '@app/components'
import { TableHelper } from '@app/helpers'
import { ROUTES, TableUserKeyField, TableUserSortingFields } from '@app/constants'
import { useLoadUsers } from '@app/hooks'
import { TableChangeItemValue, TableField, TableFilterFlags } from '@app/types'
import { CurrentUserState } from '@app/storage'
import { useSnapshot } from 'valtio'

interface UserListPageProps {}

const UserListPage: FC<UserListPageProps> = () => {
  const [ loading, setLoading ] = useState(false)
  const [ updateUser ] = useMutation(UpdateUserDocument)

  const {
    usersData,
    onPaginate,
    onFilter,
    onSort,
    loadUsers,
    filterOptions,
    sortOptions,
    totalCount,
    pageNumber,
    rowsPerPage,
    loading: usersLoading,
    allChannels
  } = useLoadUsers({ role: UserRoles.User })

  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const { isAdmin } = useSnapshot(CurrentUserState)

  const onChange: TableChangeItemValue = async (field, id, newValue) => {
    try {

      if (!field || !id) return
      setLoading(true)
      if (field === TableField.userStatus) {
        const result = await updateUser({
          variables: {
            id,
            input: { userStatus: newValue as UserStatus }
          },
        })
        if (result.data.userUpdate.id) loadUsers()
      }
    } finally {
      setLoading(false)
    }
  }

  const columns = useMemo(() => TableHelper.parseUserFields(isAdmin), [])
  const rows = useMemo(() => {
    if (usersData) {
      return usersData.map((user) => ({ ...user, country: user.countryData?.name }))
    }
    return []
  }, [ usersData ])
  const selectElements = {
    elements: { [ TableField.userStatus ]: Object.values(UserStatus).map(s => ({ value: s })) }
  }
  const filterFlags: TableFilterFlags = {
    channel: true,
    period: true,
    status: true,
    country: true,
    dateRange: true,
    search: true,
    subscriptionStatus: true,
    freeTrial: true
  }

  return (
    <PageLayout title={t('userList', { ns: 'pageTitles' })}
      loading={usersLoading || loading}>
      <TableTemplate
        columns={columns}
        rows={rows}
        selectElements={selectElements}
        fieldKey={TableUserKeyField}
        title={t('userList')}
        createText={null}
        createRoute={ROUTES.userCreate.full}
        sortOptions={sortOptions}
        sortingFields={TableUserSortingFields}
        filterFlags={filterFlags}
        filterOptions={filterOptions}
        statuses={Object.values(UserStatus)}
        totalAmount={totalCount}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        onSort={onSort}
        onFilter={onFilter}
        onPaginate={onPaginate}
        onChange={onChange}
        channelsData={allChannels}
      />
    </PageLayout>
  )
}

export default UserListPage
