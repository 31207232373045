import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import * as yup from 'yup'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useSnapshot } from 'valtio'

import { FormElementCreation, PageLayout } from '@app/components'
import {
  CreatePriceDocument,
  CreateServiceDurationDocument,
  CreateSubscriptionDocument,
  GetCurrenciesDocument,
  GetDurationsDocument,
  GetPricesDocument,
  GetServicesMinDataDocument,
  GetSubscriptionByIdDocument,
  SubscriptionCreateInput,
  UpdateSubscriptionDocument,
  UserPermission,
} from '@app/graphql'
import { CreateSubscriptionValues, FormFieldDataType, FormFieldType, SelectElementType } from '@app/types'
import { ROUTES } from '@app/constants'
import { DialogResult } from '@app/ui'
import { useCallbackPrompt, useGetPermissions } from '@app/hooks'
import { FormHelper } from '@app/helpers'
import { CurrentUserState } from '@app/storage'

const serviceField = 'serviceId'
const priceIdField = 'priceId'
const priceValueField = 'priceValue'
const currencyIdField = 'currencyId'
const isNewPriceField = 'withNewPrice'
const isNewDurationField = 'withNewDuration'
const durationIdField = 'durationId'
const durationValueField = 'durationValue'

interface SubscriptionCreatePageProps {
  editMode?: boolean
}

const SubscriptionCreatePage: FC<SubscriptionCreatePageProps> = ({ editMode }) => {
  const [ blockLeaving, setBlockLeaving ] = useState(true)
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ serviceElements, setServiceElements ] = useState<SelectElementType[]>([])
  const [ priceElements, setPriceElements ] = useState<SelectElementType[]>([])
  const [ durationElements, setDurationElements ] = useState<SelectElementType[]>([])
  const [ currencyElements, setCurrencyElements ] = useState<SelectElementType[]>([])
  const [ loading, setLoading ] = useState(false)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const navigate = useNavigate()
  const { subscriptionId } = useParams()
  const { dialog } = useCallbackPrompt(blockLeaving)
  const { id: currentUserId } = useSnapshot(CurrentUserState)
  const { checkPermission, loading: permsLoading } = useGetPermissions({ userId: currentUserId })
  /* QUERIES AND MUTATIONS */
  const [
    getSubscription,
    { data: subscriptionData, loading: subscriptionLoading },
  ] = useLazyQuery(GetSubscriptionByIdDocument, { fetchPolicy: 'network-only' })
  const [ getServices ] = useLazyQuery(GetServicesMinDataDocument)
  const [ getPrices ] = useLazyQuery(GetPricesDocument)
  const [ getCurrencies ] = useLazyQuery(GetCurrenciesDocument)
  const [ getDurations ] = useLazyQuery(GetDurationsDocument)
  const [ createSubscription ] = useMutation(CreateSubscriptionDocument)
  const [ updateSubscription ] = useMutation(UpdateSubscriptionDocument)
  const [ createPrice ] = useMutation(CreatePriceDocument)
  const [ createDuration ] = useMutation(CreateServiceDurationDocument)

  useEffect(() => {
    if (editMode) getSubscription({ variables: { id: subscriptionId } })
    const load = async () => {
      try {
        setLoading(true)
        const [
          priceResult,
          serviceResult,
          currencyResult,
          durationResult,
        ] = await Promise.all([
          getPrices({ variables: { limit: 10000 } }),
          getServices({ variables: { limit: 10000 } }),
          getCurrencies({ variables: { limit: 10000, skip: 0 } }),
          getDurations({ variables: { limit: 10000, skip: 0 } }),
        ])
        // get prices
        const newPriceElements: SelectElementType[] = priceResult?.data?.prices?.edges?.map(({ node }) => ({
          value: node.id,
          label: `${node.price} - ${node?.currency?.name || ''}`,
        }))
        if (newPriceElements?.length) {
          setPriceElements(newPriceElements)
        }
        // get services
        const newServiceElements: SelectElementType[] = serviceResult?.data?.services?.edges?.map(({ node }) => ({
          ...node,
        }))
        if (newServiceElements?.length) {
          setServiceElements(newServiceElements)
        }
        // get currencies
        const newCurrencyElements: SelectElementType[] = currencyResult?.data?.currencies?.edges?.map(({ node }) => ({
          value: node?.id,
          label: node?.name,
        }))
        if (newCurrencyElements?.length) {
          setCurrencyElements(newCurrencyElements)
        }
        // get duration
        const newDurationElements: SelectElementType[] = durationResult?.data?.serviceDurations?.edges
          ?.map(({ node }) => ({
            value: node?.id,
            label: (node?.duration || '0') + ' days',
          }))
        if (newDurationElements?.length) {
          setDurationElements(newDurationElements)
        }
      } finally {
        setLoading(false)
      }
    }
    load()
  }, [])

  const onCancel = () => navigate(-1)
  const addPricePromise = async (value: number, currencyId: string): Promise<string> => {
    const result = await createPrice({
      variables: {
        input: {
          price: value,
          currencyId,
        },
      },
    })
    return result.data.priceCreate.id
  }
  const addDurationPromise = async (duration: number): Promise<string> => {
    if (!duration) return Promise.resolve(null)
    const result = await createDuration({ variables: { input: { duration } } })
    return result.data.serviceDurationCreate.id
  }
  const onSubmit = async (values: CreateSubscriptionValues) => {
    if (editMode ? !checkPermission(UserPermission.EditSubs) : !checkPermission(UserPermission.AddSubs)) return
    try {
      setLoading(true)
      setBlockLeaving(false)
      let success
      let priceId
      let durationId

      if (values.withNewPrice || values.withNewDuration) {
        const [ responsePriceId, responseDurationId ] = await Promise.all([
          values.withNewPrice ? addPricePromise(values.priceValue, values.currencyId) : Promise.resolve(null),
          values.withNewDuration ?
            addDurationPromise(values.durationValue) :
            Promise.resolve(null)
        ])
        priceId = responsePriceId
        durationId = responseDurationId
      }

      const input: SubscriptionCreateInput = {
        serviceId: values.serviceId,
        priceId: priceId || values.priceId,
        serviceDurationId: durationId || values.durationId,
      }

      if (editMode) {
        const result = await updateSubscription({ variables: { id: subscriptionId, input } })
        success = result.data.subscriptionUpdate
      } else {
        const result = await createSubscription({ variables: { input } })
        success = Boolean(result.data.subscriptionCreate.id)
      }

      if (!success) throw new Error()
      setSuccessMessage(editMode ? t('updatingSuccess') : t('creationSuccess'))
    } catch (e) {
      setBlockLeaving(true)
      setErrorMessage(editMode ? t('updatingFailure') : t('creationFailure'))
    } finally {
      setLoading(false)
    }
  }
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(ROUTES.subscriptions.short)
    }
  }

  const title = editMode ? t('editSubscription') : t('createSubscription')
  const fields: FormFieldDataType[] = [
    {
      name: serviceField,
      label: t('service'),
      type: FormFieldType.select,
      elements: serviceElements,
      initValue: subscriptionData?.subscription?.service?.id || '',
      validation: FormHelper.getRequiredStringSchema(),
      required: true,
    },
    {
      name: isNewPriceField,
      label: t('newPrice'),
      type: FormFieldType.switch,
      initValue: false,
      validation: yup.boolean(),
    },
    {
      name: priceIdField,
      label: t('price'),
      type: FormFieldType.select,
      elements: priceElements,
      initValue: subscriptionData?.subscription?.price?.id || '',
      validation: yup.string(),
      required: true,
      dependent: {
        field: isNewPriceField,
        value: false,
      },
    },
    {
      name: priceValueField,
      label: t('price'),
      type: FormFieldType.number,
      initValue: 0,
      validation: yup.number().min(0, t('minNumberZeroError', { ns: 'forms' })),
      required: true,
      dependent: {
        field: isNewPriceField,
        value: true,
      },
    },
    {
      name: currencyIdField,
      label: t('currency'),
      type: FormFieldType.select,
      elements: currencyElements,
      initValue: '',
      validation: yup.string(),
      required: true,
      dependent: {
        field: isNewPriceField,
        value: true,
      },
    },
    {
      name: isNewDurationField,
      label: t('newDuration'),
      type: FormFieldType.switch,
      initValue: false,
      validation: yup.boolean(),
    },
    {
      name: durationIdField,
      label: t('duration'),
      type: FormFieldType.select,
      elements: durationElements,
      initValue: subscriptionData?.subscription?.serviceDuration?.id || '',
      validation: yup.string(),
      required: true,
      dependent: {
        field: isNewDurationField,
        value: false,
      },
    },
    {
      name: durationValueField,
      label: t('duration'),
      type: FormFieldType.number,
      initValue: 0,
      validation: yup.number().min(0, t('minNumberZeroError', { ns: 'forms' })),
      required: true,
      dependent: {
        field: isNewDurationField,
        value: true,
      },
    },
  ]
  const validate = (values) => {
    const errors = {}

    if (values) {
      if (!values[serviceField]) errors[serviceField] = t('requiredError', { ns: 'forms' })

      if (values[isNewPriceField]) {
        if (values[priceValueField] < 0) errors[priceValueField] = t('minNumberZeroError', { ns: 'forms' })
        if (!values[currencyIdField]) errors[currencyIdField] = t('requiredError', { ns: 'forms' })
      } else {
        if (!values[priceIdField]) errors[priceIdField] = t('requiredError', { ns: 'forms' })
      }

      if (values[isNewDurationField]) {
        if (values[durationValueField] < 0) errors[durationValueField] = t('minNumberZeroError', { ns: 'forms' })
      } else {
        if (!values[durationIdField]) errors[durationIdField] = t('requiredError', { ns: 'forms' })
      }
    }

    return errors
  }
  const hasPerm = editMode ? checkPermission(UserPermission.EditSubs) : checkPermission(UserPermission.AddSubs)

  return (
    <PageLayout
      title={title}
      loading={loading || subscriptionLoading || permsLoading}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" component="h4" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ width: '70%' }}>
          <FormElementCreation
            fields={fields}
            submitDisabled={!hasPerm}
            validate={validate}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </Box>
      </Box>

      {/* DIALOG */}
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
      { dialog }
    </PageLayout>
  )
}

export default SubscriptionCreatePage
