import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useSnapshot } from 'valtio'

import { PageLayout, ViewPageTemplate } from '@app/components'
import { ROUTES, SubscriptionDataFields } from '@app/constants'
import {
  DeleteSubscriptionDocument,
  GetSubscribersDataDocument,
  GetSubscriptionByIdDocument,
  GetUserSubscriptionsDocument,
  RenewSubscriptionDocument,
  UserModel,
  UserPermission
} from '@app/graphql'
import { SubscriptionViewPageData } from '@app/types'
import SubscriberList from './SubscriberList'
import { CurrentUserState } from '@app/storage'
import { useGetPermissions } from '@app/hooks'

enum TabOptions {
  view = 'VIEW',
  subscribers = 'SUBSCRIBERS',
}

interface SubscriptionViewPageProps {}

const SubscriptionViewPage: FC<SubscriptionViewPageProps> = () => {
  const [ loading, setLoading ] = useState(false)
  const [
    subscribersMap,
    setSubscribersMap,
  ] = useState(new Map<string, Pick<UserModel, 'id' | 'displayName' | 'emailAddress'>>())
  const [ currentTab, setCurrentTab ] = useState(TabOptions.view)
  const { subscriptionId } = useParams()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const { id: currentUserId } = useSnapshot(CurrentUserState)
  const { checkPermission, loading: permsLoading } = useGetPermissions({ userId: currentUserId })
  /* QUERIES AND MUTATIONS */
  const [
    getSubscription,
    { data: subscriptionData, loading: subscriptionLoading },
  ] = useLazyQuery(GetSubscriptionByIdDocument, { fetchPolicy: 'network-only' })
  const [
    getUserSubscriptions,
    { data: userSubsData, loading: userSubsLoading }
  ] = useLazyQuery(GetUserSubscriptionsDocument, { fetchPolicy: 'network-only' })
  const [
    getSubscribersData,
    { loading: subscribersLoading }
  ] = useLazyQuery(GetSubscribersDataDocument, { fetchPolicy: 'network-only' })
  const [ deleteSubscription ] = useMutation(DeleteSubscriptionDocument)
  const [ renewSubscription ] = useMutation(RenewSubscriptionDocument)

  useEffect(() => {
    getSubscription({ variables: { id: subscriptionId } })
    loadSubscribers()
  }, [])

  const onDelete = async () => {
    const result = await deleteSubscription({ variables: { id: subscriptionId } })
    return result?.data?.subscriptionDelete
  }
  const onChangeTab = (e: SyntheticEvent, newValue: TabOptions) => setCurrentTab(newValue)
  const handleRenewSubscription = async (subId: string) => {
    try {
      if (subId) {
        setLoading(true)
        const result = await renewSubscription({ variables: { id: subId } })
        if (result.data.serviceSubsciptionRenew.id) {
          await getUserSubscriptions({ variables: { limit: 10000, filter: { subscriptionId } } })
        }
      }
    } finally {
      setLoading(false)
    }
  }
  const loadSubscribers = async () => {
    try {
      setLoading(true)
      const subsResult = await getUserSubscriptions({ variables: { limit: 10000, filter: { subscriptionId } } })
      const userIds: string[] = subsResult.data.serviceSubscriptions.edges.map(({ node }) => node.userId)
      const usersResult = await getSubscribersData({ variables: { userIds } })
      const map = new Map<string, Pick<UserModel, 'id' | 'displayName' | 'emailAddress'>>()
      usersResult.data.usersByIds.forEach(u => map.set(u.id, u))
      setSubscribersMap(map)
    } finally {
      setLoading(false)
    }
  }

  const data: SubscriptionViewPageData = subscriptionData?.subscription ? {
    id: subscriptionData.subscription.id,
    serviceName: subscriptionData.subscription.service?.serviceName,
    price: subscriptionData.subscription.price?.price,
    currency: subscriptionData.subscription.price?.currency?.name,
    duration: (subscriptionData.subscription.serviceDuration?.duration || '0') + ' days',
  } : null
  const tabTitle = currentTab === TabOptions.view ? t('subscriptionView') : t('subscribers')

  return (
    <PageLayout
      title={t('subscriptionViewPage', { ns: 'pageTitles' })}
      loading={loading || subscriptionLoading || userSubsLoading || subscribersLoading}
    >
      <Typography variant="h4" component="h4" gutterBottom>
        {tabTitle}
      </Typography>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Tabs
          value={currentTab}
          onChange={onChangeTab}
        >
          <Tab
            label={t('subscriptionView')}
            value={TabOptions.view}
          />
          <Tab
            label={t('subscribers')}
            value={TabOptions.subscribers}
          />
        </Tabs>
      </Box>
      {
        currentTab === TabOptions.view ?
          <ViewPageTemplate
            editRoute={ROUTES.subscriptionEdit.full + '/' + subscriptionId}
            dataFields={SubscriptionDataFields}
            subscriptionData={data}
            routeAfterDeletion={ROUTES.subscriptions.short}
            editDisabled={permsLoading || !checkPermission(UserPermission.EditSubs)}
            onDeletePromise={onDelete}
          /> :
          null
      }
      {/* {
        currentTab === TabOptions.subscribers ?
          <SubscriberList
            userSubscriptions={userSubsData?.serviceSubscriptions?.edges}
            subscribersMap={subscribersMap}
            renewSubscription={handleRenewSubscription}
          /> :
          null
      } */}
    </PageLayout>
  )
}

export default SubscriptionViewPage
