import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Button, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'

import { FormHelper } from '@app/helpers'

const emailField = 'email'

interface SubmitEmailProps {
  onSubmit: (username: string) => void
}

const SubmitEmail: FC<SubmitEmailProps> = ({ onSubmit }) => {
  const { t } = useTranslation([ 'translation', 'forms' ])
  const formik = useFormik({
    initialValues: {
      [emailField]: '',
    },
    validationSchema: yup.object().shape({
      [emailField]: FormHelper.getEmailSchemaField(),
    }),
    onSubmit: values => onSubmit(values[emailField]),
  })

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <Typography variant="h5" component="h5" align="center" gutterBottom>
        {t('emailForRecoveryTitle')}
      </Typography>
      <Typography variant="body1" component="p" align="center" gutterBottom>
        {t('emailForRecoveryLabel')}
      </Typography>
      <TextField
        className="text-field"
        fullWidth
        id={emailField}
        name={emailField}
        label={t('yourEmailLabel', { ns: 'forms' })}
        value={formik.values[emailField]}
        error={formik.touched[emailField] && Boolean(formik.errors[emailField])}
        helperText={formik.touched[emailField] && t(formik.errors[emailField])}
        type="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      <div className="vb-flex-row-c-c">
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={Boolean(formik.errors[emailField])}
        >
          {t('buttonSubmit')}
        </Button>
      </div>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  width: 40%;

  .text-field {
    margin-bottom: 10px;
  }
`

export default SubmitEmail
