import { TableField, TableFieldMap, TableFieldType } from '@app/types'
import i18n from '@app/i18n'

export const ALL_VALUES = 'all'

export const TableFieldsMap: TableFieldMap = new Map(
  [
    [
      TableField.id,
      {
        field: TableField.id,
        label: i18n.t('columnHeaderId'),
        type: TableFieldType.link,
      },
    ],
    [
      TableField.instagramName,
      {
        field: TableField.instagramName,
        label: i18n.t('instagramName'),
        type: TableFieldType.str,
      },
    ],
    [
      TableField.instagramURL,
      {
        field: TableField.instagramURL,
        label: i18n.t('instagramURL'),
        type: TableFieldType.str,
      },
    ],
    [
      TableField.instagramFollowers,
      {
        field: TableField.instagramFollowers,
        label: i18n.t('instagramFollowers'),
        type: TableFieldType.str,
      },
    ],
    [
      TableField.telegramURL,
      {
        field: TableField.telegramURL,
        label: i18n.t('telegramURL'),
        type: TableFieldType.str,
      },
    ],
    [
      TableField.tipsterInviteLink,
      {
        field: TableField.tipsterInviteLink,
        label: i18n.t('tipsterInviteLink'),
        type: TableFieldType.str,
      },
    ],
    [
      TableField.tipsterRegistered,
      {
        field: TableField.tipsterRegistered,
        label: i18n.t('tipsterRegistered'),
        type: TableFieldType.bool,
      },
    ],
    [
      TableField.subscribers,
      {
        field: TableField.subscribers,
        type: TableFieldType.num,
        label: i18n.t('columnHeaderSubscribers')
      }
    ],
    [ TableField.email, { field: TableField.email, label: i18n.t('columnHeaderEmail'), type: TableFieldType.str } ],
    [
      TableField.lastName,
      { field: TableField.lastName, label: i18n.t('columnHeaderLastName'), type: TableFieldType.str },
    ],
    [
      TableField.dateSubscribed,
      { field: TableField.dateSubscribed, label: i18n.t('columnHeaderDateSubscribed'), type: TableFieldType.date }
    ],
    [
      TableField.dateJoined,
      { field: TableField.dateJoined, label: i18n.t('columnHeaderDateJoined'), type: TableFieldType.date }
    ],

    [
      TableField.buttonEdit,
      {
        field: TableField.buttonEdit,
        label: i18n.t('columnHeaderEdit'),
        type: TableFieldType.btnLink,
      }
    ],
    [
      TableField.displayName,
      { field: TableField.displayName, label: i18n.t('columnHeaderDisplayName'), type: TableFieldType.str }
    ],
    [
      TableField.birthday,
      { field: TableField.birthday, label: i18n.t('columnHeaderBirthday'), type: TableFieldType.date },
    ],
    [
      TableField.country,
      { field: TableField.country, label: i18n.t('country'), type: TableFieldType.str }
    ],
    [
      TableField.userStatus,
      { field: TableField.userStatus, label: i18n.t('status'), type: TableFieldType.str }
    ],
    [
      TableField.tipsterStatus,
      { field: TableField.tipsterStatus, label: i18n.t('tipsterStatus'), type: TableFieldType.str }
    ],
    [
      TableField.title,
      { field: TableField.title, label: i18n.t('columnHeaderTitle'), type: TableFieldType.str }
    ],
    [
      TableField.frequency,
      { field: TableField.frequency, label: i18n.t('columnHeaderFrequency'), type: TableFieldType.num }
    ],
    [
      TableField.startDate,
      { field: TableField.startDate, label: i18n.t('columnHeaderStartDate'), type: TableFieldType.date }
    ],
    [
      TableField.endDate,
      { field: TableField.endDate, label: i18n.t('columnHeaderEndDate'), type: TableFieldType.date }
    ],
    [
      TableField.referral,
      { field: TableField.referral, label: i18n.t('columnHeaderReferral'), type: TableFieldType.str }
    ],
    [
      TableField.visible,
      { field: TableField.visible, label: i18n.t('columnHeaderVisible'), type: TableFieldType.bool }
    ],
    [
      TableField.language,
      { field: TableField.language, label: i18n.t('columnHeaderLanguage'), type: TableFieldType.str }
    ],
    [
      TableField.logo,
      { field: TableField.logo, label: i18n.t('logo'), type: TableFieldType.circleImage }
    ],
    [
      TableField.channelName,
      { field: TableField.channelName, label: i18n.t('channelName'), type: TableFieldType.str }
    ],
    [
      TableField.channelType,
      { field: TableField.channelType, label: i18n.t('channelType'), type: TableFieldType.str }
    ],
    [
      TableField.anonymized,
      { field: TableField.anonymized, label: i18n.t('anonymity'), type: TableFieldType.bool }
    ],
    [
      TableField.channelDeal,
      { field: TableField.channelDeal, label: i18n.t('channelDeal'), type: TableFieldType.num }
    ],
    [
      TableField.channelStatus,
      { field: TableField.channelStatus, label: i18n.t('channelStatus'), type: TableFieldType.str }
    ],
    [
      TableField.tipsterChannels,
      { field: TableField.tipsterChannels, label: i18n.t('channels'), type: TableFieldType.chips }
    ],
    [
      TableField.placement,
      { field: TableField.placement, label: i18n.t('placement'), type: TableFieldType.chips }
    ],
    [
      TableField.active,
      { field: TableField.active, label: i18n.t('active'), type: TableFieldType.bool }
    ],
    [
      TableField.internalName,
      { field: TableField.internalName, label: i18n.t('internalName'), type: TableFieldType.str }
    ],
    [
      TableField.status,
      { field: TableField.status, label: i18n.t('status'), type: TableFieldType.str }
    ],
    [
      TableField.autoRenewal,
      { field: TableField.autoRenewal, label: i18n.t('autorenewal'), type: TableFieldType.bool }
    ],
    [
      TableField.dateStart,
      { field: TableField.dateStart, label: i18n.t('dateStart'), type: TableFieldType.date }
    ],
    [
      TableField.tipsterName,
      { field: TableField.tipsterName, label: i18n.t('tipsterName'), type: TableFieldType.str }
    ],
    [
      TableField.channelName,
      { field: TableField.channelName, label: i18n.t('channelName'), type: TableFieldType.str }
    ],
    [
      TableField.dateCreated,
      { field: TableField.dateCreated, label: i18n.t('dateCreated'), type: TableFieldType.date }
    ],
    [
      TableField.expirationDate,
      { field: TableField.expirationDate, label: i18n.t('expirationDate'), type: TableFieldType.date }
    ],
    [
      TableField.registrations,
      { field: TableField.registrations, label: i18n.t('registrations'), type: TableFieldType.num }
    ],
    [
      TableField.usersLimit,
      { field: TableField.usersLimit, label: i18n.t('usersLimit'), type: TableFieldType.num }
    ],
    [
      TableField.usersNumber,
      { field: TableField.usersNumber, label: i18n.t('usersNumber'), type: TableFieldType.num }
    ],
    [
      TableField.tipstersNumber,
      { field: TableField.tipstersNumber, label: i18n.t('tipstersNumber'), type: TableFieldType.num }
    ],
    [
      TableField.freeTips,
      { field: TableField.freeTips, label: i18n.t('freeTips'), type: TableFieldType.num }
    ],
    [
      TableField.vipTips,
      { field: TableField.vipTips, label: i18n.t('vipTips'), type: TableFieldType.num }
    ],
    [
      TableField.serviceName,
      { field: TableField.serviceName, label: i18n.t('serviceName'), type: TableFieldType.str }
    ],
    [
      TableField.price,
      { field: TableField.price, label: i18n.t('price'), type: TableFieldType.num }
    ],
    [
      TableField.currency,
      { field: TableField.currency, label: i18n.t('currency'), type: TableFieldType.str }
    ],
    [
      TableField.duration,
      { field: TableField.duration, label: i18n.t('duration'), type: TableFieldType.str }
    ],
    [
      TableField.expiration,
      { field: TableField.expiration, label: i18n.t('expiration'), type: TableFieldType.date }
    ],
    [
      TableField.buttonRenew,
      { field: TableField.buttonRenew, label: i18n.t('activate'), type: TableFieldType.btn }
    ],
    [
      TableField.userId,
      { field: TableField.userId, label: i18n.t('userId'), type: TableFieldType.str }
    ],
    [
      TableField.source,
      { field: TableField.source, label: i18n.t('userSource'), type: TableFieldType.str }
    ],
    [
      TableField.hasFreeTrial,
      { field: TableField.hasFreeTrial, label: i18n.t('withFreeTrial'), type: TableFieldType.bool }
    ],
    [
      TableField.freeTrialPeriod,
      { field: TableField.freeTrialPeriod, label: i18n.t('freeTrialPeriod'), type: TableFieldType.str }
    ],
    [
      TableField.countryChannel,
      { field: TableField.countryChannel, label: i18n.t('channelCountry'), type: TableFieldType.str }
    ],
    [
      TableField.countryTipster,
      { field: TableField.countryTipster, label: i18n.t('tipsterCountry'), type: TableFieldType.str }
    ],
    [
      TableField.fee,
      { field: TableField.fee, label: i18n.t('fee'), type: TableFieldType.str }
    ],
    [
      TableField.paymentOption,
      { field: TableField.paymentOption, label: i18n.t('paymentOption'), type: TableFieldType.str }
    ],
    [
      TableField.revenueVB,
      { field: TableField.revenueVB, label: i18n.t('VBRevenue'), type: TableFieldType.num }
    ],
    [
      TableField.subsStatus,
      { field: TableField.subsStatus, label: i18n.t('subscriptionStatus'), type: TableFieldType.str }
    ],
    [
      TableField.subsType,
      { field: TableField.subsType, label: i18n.t('subscriptionType'), type: TableFieldType.str }
    ],
    [
      TableField.users,
      { field: TableField.users, label: i18n.t('users'), type: TableFieldType.num }
    ],
    [
      TableField.subscriptionManual,
      { field: TableField.subscriptionManual, label: i18n.t('subscriptionManual'), type: TableFieldType.str }
    ],
    [
      TableField.subscriptionAutomatic,
      { field: TableField.subscriptionAutomatic, label: i18n.t('subscriptionAutomatic'), type: TableFieldType.str }
    ],
    [
      TableField.lastTransaction,
      { field: TableField.lastTransaction, label: i18n.t('lastTransaction'), type: TableFieldType.str }
    ],
    [
      TableField.channelId,
      { field: TableField.channelId, label: i18n.t('channelId'), type: TableFieldType.str }
    ],
    [
      TableField.tipsterId,
      { field: TableField.tipsterId, label: i18n.t('tipsterId'), type: TableFieldType.str }
    ],
    [
      TableField.buttonPaid,
      { field: TableField.buttonPaid, label: i18n.t('paid'), type: TableFieldType.btn }
    ],
    [
      TableField.buttonUndoPaid,
      { field: TableField.buttonUndoPaid, label: i18n.t('undo'), type: TableFieldType.btn }
    ],
    [
      TableField.paymentOptions,
      { field: TableField.paymentOptions, label: i18n.t('paymentOptions'), type: TableFieldType.str }
    ],
    [
      TableField.tipsterCount,
      { field: TableField.tipsterCount, label: i18n.t('tipsters'), type: TableFieldType.num }
    ],
    [
      TableField.usersCount,
      { field: TableField.usersCount, label: i18n.t('users'), type: TableFieldType.num }
    ],
    [
      TableField.feeForPayment,
      { field: TableField.feeForPayment, label: i18n.t('fee'), type: TableFieldType.num }
    ],
    [
      TableField.tipster,
      { field: TableField.tipster, label: i18n.t('tipster'), type: TableFieldType.str }
    ],
    [
      TableField.channel,
      { field: TableField.channel, label: i18n.t('channel'), type: TableFieldType.str }
    ],
    [
      TableField.user,
      { field: TableField.user, label: i18n.t('user'), type: TableFieldType.str }
    ],
    [
      TableField.subscription,
      { field: TableField.subscription, label: i18n.t('subscription'), type: TableFieldType.str }
    ],
    [
      TableField.amount,
      { field: TableField.amount, label: i18n.t('amount'), type: TableFieldType.num }
    ],
    [
      TableField.commission,
      { field: TableField.commission, label: i18n.t('commission'), type: TableFieldType.num }
    ],
    [
      TableField.earned,
      { field: TableField.earned, label: i18n.t('earned'), type: TableFieldType.num }
    ],
    [
      TableField.settled,
      { field: TableField.settled, label: i18n.t('settled'), type: TableFieldType.str }
    ],
  ]
)

export const DisabledCellButton = 'disabledCellButton'

// SUPERADMIN
export const TableSuperadminKeyField = TableField.id

export const TableSuperadminFields = [
  TableField.id,
  TableField.email,
  TableField.lastName,
  TableField.dateJoined,
  TableField.buttonEdit,
]

// USER
export const TableUserKeyField = TableField.id

export const TableUserFields = [
  TableField.id,
  TableField.displayName,
  TableField.birthday,
  TableField.country,
  TableField.userStatus,
  TableField.source,
  TableField.dateJoined,
  TableField.buttonEdit,
]

export const TableUserSortingFields = {
  [TableField.id]: true,
  [TableField.displayName]: true,
  [TableField.birthday]: true,
  [TableField.country]: true,
  [TableField.userStatus]: true,
  [TableField.dateJoined]: true,
}

// TIPSTER
export const TableTipsterKeyField = TableField.id

export const TableTipsterFields = [
  TableField.id,
  TableField.displayName,
  TableField.country,
  TableField.tipsterStatus,
  TableField.dateJoined,
  TableField.tipsterChannels,
  TableField.buttonEdit,
]

// ADVERT
export const TableAdvertKeyField = TableField.id

export const TableAdvertFields = [
  TableField.id,
  TableField.title,
  TableField.startDate,
  TableField.endDate,
  TableField.placement,
  TableField.referral,
  TableField.visible,
  TableField.active,
  TableField.buttonEdit,
]

// TIPSTER INVITE

export const TableTipsterInvitationKeyField = TableField.id
export const TableTipsterInvitationFields = [
  TableField.instagramName,
  TableField.instagramURL,
  TableField.instagramFollowers,
  TableField.telegramURL,
  TableField.tipsterInviteLink,
  TableField.tipsterRegistered,
  TableField.tipsterId,
]

// CHANNEL
export const TableChannelKeyField = TableField.id

export const TableChannelFields = [
  TableField.id,
  TableField.logo,
  TableField.channelName,
  TableField.dateJoined,
  TableField.channelType,
  TableField.anonymized,
  TableField.channelDeal,
  TableField.channelStatus,
  TableField.usersLimit,
  TableField.country,
  TableField.paymentOptions,
  TableField.tipsterCount,
  TableField.usersCount,
  TableField.feeForPayment,
  // number of tipsters
  // number of users
  TableField.buttonEdit,
]

export const TableChannelSortingFields = {
  [TableField.id]: true,
  [TableField.channelName]: true,
  [TableField.channelType]: true,
  [TableField.channelStatus]: true,
  [TableField.anonymized]: true,
  [TableField.channelDeal]: true,
  [TableField.usersLimit]: false,
}

// SERVICE
export const TableServiceKeyField = TableField.id

export const TableServiceFields = [
  TableField.internalName,
  TableField.status,
  TableField.autoRenewal,
  TableField.dateStart,
  TableField.tipsterName,
  TableField.channelName,
  TableField.hasFreeTrial,
  TableField.buttonEdit,
  TableField.subscribers
]

// todo: change sort fields
export const TableServiceSortingFields = {
  [TableField.id]: true,
  // [TableField.internalName]: true,
  [TableField.status]: true,
}

// AFFILIATE LINKS
export const TableAffiliateLinkKeyField = TableField.id

export const TableAffiliateLinkFields = [
  TableField.id,
  TableField.internalName,
  TableField.status,
  TableField.dateCreated,
  TableField.expirationDate,
  TableField.tipsterName,
  TableField.channelName,
  TableField.registrations,
  TableField.buttonEdit,
]

export const TableAffiliateLinksSortingFields = {
  [TableField.id]: true,
  [TableField.internalName]: true,
  [TableField.channelName]: true,
  [TableField.dateCreated]: true,
  [TableField.expirationDate]: true,
}

// ANALYTICS
export const TableAnalyticsKeyField = TableField.id

export const TableAnalyticsFields = [
  TableField.channelName,
  TableField.channelStatus,
  TableField.country,
  TableField.usersNumber,
  TableField.tipstersNumber,
  TableField.freeTips,
  TableField.vipTips,
]

// SUBSCRIPTION
export const TableSubscriptionKeyField = TableField.id

export const TableSubscriptionFields = [
  TableField.serviceName,
  TableField.price,
  TableField.currency,
  TableField.duration,
  TableField.buttonEdit,
]

// todo: change sort fields
export const TableSubscriptionSortingFields = {
  [TableField.id]: true,
}

// SUBSCRIBERS
export const TableSubscriberKeyField = TableField.id

export const TableSubscriberFields = [
  TableField.userId,
  TableField.displayName,
  TableField.email,
  TableField.status,
  TableField.expiration,
  TableField.serviceName,
  TableField.price,
  TableField.duration,
  TableField.dateSubscribed,
  TableField.buttonRenew,
]

export const TableSubscriberSortingFields = {
  [TableField.id]: true,
}

// FINANCIAL
export const TableFinancialKeyField = TableField.id

export const TableFinancialFields = [
  TableField.id,
  TableField.channelName,
  // TableField.channelStatus,
  TableField.tipsterName,
  // TableField.tipsterStatus,
  // TableField.countryChannel,
  // TableField.countryTipster,
  TableField.subsStatus,
  // TableField.subsType,
  TableField.revenueVB,
  TableField.users,
  TableField.currency,
  TableField.paymentOption,
  TableField.fee,
  TableField.subscriptionManual,
  TableField.subscriptionAutomatic,
  TableField.lastTransaction,
  TableField.buttonPaid,
  TableField.buttonUndoPaid,
]
export const TableNewFinancialFields = [
  // TableField.id,
  TableField.tipster,
  TableField.channel,
  TableField.user,
  TableField.subscription,
  TableField.amount,
  TableField.currency,
  TableField.expiration,
  TableField.commission,
  TableField.earned,
  TableField.settled,
]

export const TableFinancialSortingFields = {
  [TableField.channelName]: true,
  [TableField.channelStatus]: true,
  [TableField.countryChannel]: true,
  [TableField.tipsterName]: true,
  [TableField.tipsterStatus]: true,
  [TableField.countryTipster]: true,
  [TableField.users]: true,
  [TableField.fee]: true,
  [TableField.lastTransaction]: true,
}
