import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { ROUTES } from '@app/constants'
import UserListPage from './UserListPage'
import UserViewPage from './UserViewPage'
import UserCreatePage from './UserCreatePage'
import UserFinancialListPage from './UserFinancialListPage'

interface UserRoutesProps {
  isAdmin: boolean
}

const UserRoutes: FC<UserRoutesProps> = (isAdmin) => {
  return (
    <Routes>
      <Route index element={<UserListPage/>}/>
      <Route path={ROUTES.userViewShort} element={<UserViewPage/>}/>
      <Route path={ROUTES.usersWithSubs.short} element={<UserFinancialListPage/>}/>
      {
        isAdmin? (
          <>
            <Route path={ROUTES.userEdit.short} element={<UserCreatePage editMode/>}/>
            <Route path={ROUTES.userCreate.short} element={<UserCreatePage/>}/>
          </>
        ): null
      }
    </Routes>
  )
}

export default UserRoutes
