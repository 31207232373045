import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PageLayout, TableTemplate } from '@app/components'
import { ROUTES, TableServiceKeyField, TableServiceSortingFields } from '@app/constants'
import { CalendarHelper, TableHelper } from '@app/helpers'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GetChannelsWithMinDataDocument,
  GetServicesDocument,
  GetUsersWithMinDataDocument,
  ServiceFilterInput,
  ServiceStatus,
  ServiceUpdate,
  SortMethod,
  UpdateServiceDocument,
  UserRoles,
} from '@app/graphql'
import {
  DualSelectOption,
  TableChangeItemValue,
  TableField,
  TableFilterData,
  TableFilterFlags,
  TableSelectElements
} from '@app/types'
import { CurrentUserState } from '@app/storage'
import { useSnapshot } from 'valtio'

interface ServiceListPageProps {}

const ServiceListPage: FC<ServiceListPageProps> = () => {
  const [ filterOptions, setFilterOptions ] = useState<TableFilterData>({})
  const [ sortOptions, setSortOptions ] = useState<{ [key: string]: SortMethod }>(null)
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ channelsMap, setChannelsMap ] = useState(new Map<string, string>())
  const [ tipstersMap, setTipstersMap ] = useState(new Map<string, string>())
  const [ loading, setLoading ] = useState(false)
  const [ pageNumber, setPageNumber ] = useState(0)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const [
    getServices,
    { data: serviceData, loading: serviceLoading }
  ] = useLazyQuery(GetServicesDocument, { fetchPolicy: 'network-only' })
  const [ getUsers ] = useLazyQuery(GetUsersWithMinDataDocument)
  const [ getChannels ] = useLazyQuery(GetChannelsWithMinDataDocument)
  const [ updateService ] = useMutation(UpdateServiceDocument)
  const { id: userId, isAdmin } = useSnapshot(CurrentUserState)

  useEffect(() => {
    getServices({
      variables: {
        ...getSort(),
        filter: prepareFilter(filterOptions),
        ...getPagination(),
      },
    })
  }, [ sortOptions, filterOptions, pageNumber, rowsPerPage ])

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true)
        const [ tipstersResult, channelsResult ] = await Promise.all([
          getUsers({
            variables: {
              filter: { role: UserRoles.Tipster },
              sort: {},
              limit: 10000,
              skip: 0,
            },
          }),
          getChannels({
            variables: {
              filter: {},
              sort:{},
              limit: 10000,
              skip: 0,
            },
          })
        ])

        const tMap = new Map<string, string>()
        tipstersResult?.data?.users?.users?.forEach(u => tMap.set(u.value, u.label))
        setTipstersMap(tMap)
        const cMap = new Map<string, string>()
        channelsResult?.data?.channels?.channels?.forEach(c => cMap.set(c.value, c.label))
        setChannelsMap(cMap)
      } finally {
        setLoading(false)
      }
    }
    load()
  }, [])

  const getPagination = () => {
    const row = localStorage.getItem('per_page')
    const letLimit = Number(row) ?? rowsPerPage
    return { limit: letLimit, skip: pageNumber * rowsPerPage }
  }
  const prepareFilter = (filter: TableFilterData): ServiceFilterInput => ({
    status: filter?.status as ServiceStatus,
    dateStart: {
      startDate: CalendarHelper.getDayAsString(filter?.dateStart) || null,
    },
    tipsterId: isAdmin? null: userId
  })
  const getSort = (sort?: { [key: string]: SortMethod }): { reverse?: boolean, sortKey?: string } => {
    if (!sort && !sortOptions) {
      return {}
    }
    const current = sort || sortOptions
    const keys = Object.keys(current)
    const key = keys[0]

    return { sortKey: key, reverse: current[key] === SortMethod.Desc }
  }
  const onFilter = (filter: TableFilterData = {}) => setFilterOptions(filter)
  const onSort = (sort: { [key: string]: SortMethod }) => setSortOptions(sort)
  const onPaginate = (page: number, rowsPage: number) => {
    setPageNumber(page)
    setRowsPerPage(rowsPage)
  }
  const onChange: TableChangeItemValue = async (field, id, newValue) => {
    try {
      if (!field || !id) return
      setLoading(true)
      let input: ServiceUpdate = null
      switch (field) {
        case TableField.status:
          input = { status: newValue as ServiceStatus }
          break
        case TableField.autoRenewal:
          input = { autoRenewal: newValue === DualSelectOption.yes }
          break
        case TableField.dateStart: {
          if (CalendarHelper.isDate(newValue)) {
            input = { dateStart: (newValue as Date).toISOString() }
          }
          break
        }
      }
      if (input) {
        const result = await updateService({
          variables: {
            id,
            input,
          },
        })
        if (result.data.serviceUpdate) {
          getServices({
            variables: {
              filter: prepareFilter(filterOptions),
              ...getSort(),
              ...getPagination(),
            },
          })
        }
      }
    } finally {
      setLoading(false)
    }
  }

  const columns = useMemo(() => TableHelper.parseServiceFields(isAdmin), [])
  const rows = useMemo(() => {
    return serviceData?.services?.edges ?
      serviceData.services.edges.map(({ node: s }) => ({
        ...s,
        [TableField.tipsterName]: tipstersMap.get(s.tipsterId) || s.tipsterId,
        [TableField.channelName]: channelsMap.get(s.channelId) || s.channelId,
      })) :
      []
  }, [ serviceData, tipstersMap, channelsMap ])
  const selectElements: TableSelectElements = {
    elements: {
      [TableField.status]: Object.values(ServiceStatus).map(s => ({ value: s })),
      [TableField.autoRenewal]: [ { value: DualSelectOption.yes }, { value: DualSelectOption.no } ],
    },
    dualOption: {
      [TableField.autoRenewal]: true,
    },
  }
  const filterFlags: TableFilterFlags = {
    status: true,
    dateStart: true,
  }

  return (
    <PageLayout
      title={t('serviceList', { ns: 'pageTitles' })}
      loading={serviceLoading || loading}
    >
      <TableTemplate
        columns={columns}
        rows={rows}
        selectElements={selectElements}
        fieldKey={TableServiceKeyField}
        title={t('serviceList')}
        createText={t('buttonCreateService')}
        totalAmount={serviceData?.services?.pageInfo?.totalCount}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        filterFlags={filterFlags}
        filterOptions={filterOptions}
        statuses={Object.values(ServiceStatus)}
        sortOptions={sortOptions}
        createRoute={ROUTES.serviceCreate.full}
        sortingFields={TableServiceSortingFields}
        onFilter={onFilter}
        onSort={onSort}
        onPaginate={onPaginate}
        onChange={onChange}
      />
    </PageLayout>
  )
}

export default ServiceListPage
