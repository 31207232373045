import { ServiceSubscriptionStatus } from '@app/graphql'
import { SelectElementType } from '@app/types'

export enum TableField {
  instagramName = 'instagramName',
  instagramURL = 'instagramURL',
  instagramFollowers = 'instagramFollowers',
  telegramURL = 'telegramURL',
  tipsterInviteLink = 'tipsterInviteLink',
  tipsterRegistered= 'tipsterRegistered',
  id = 'id',
  email = 'emailAddress',
  lastName = 'lastName',
  displayName = 'displayName',
  birthday = 'dateOfBirth',
  country = 'country',
  userStatus = 'userStatus',
  tipsterStatus = 'tipsterStatus',
  dateJoined = 'createdAt',
  firstName = 'firstName',
  gender = 'gender',
  phoneNumber = 'phoneNumber',
  title = 'title',
  frequency = 'frequency',
  startDate = 'startDate',
  endDate = 'endDate',
  referral = 'referral',
  visible = 'visible',
  buttonEdit = 'buttonEdit',
  language = 'language',
  alternativeName = 'alternativeName',
  bio = 'bio',
  trialSubscription = 'trialSubscription',
  paymentOptions = 'paymentOptions',
  tipsterChannels = 'tipsterChannels',
  placement = 'placement',
  active = 'active',
  source = 'source',
  background = 'background',
  // FOR CHANNELS
  anonymized = 'anonymized',
  channelDeal = 'channelDeal',
  channelName = 'channelName',
  channelStatus = 'channelStatus',
  channelType = 'channelType',
  feeForPayment = 'feeForPayment',
  logo = 'logo',
  tipsterCount = 'tipsterCount',
  usersCount = 'usersCount',
  usersLimit = 'usersLimit',
  // FOR SERVICES
  paymentMethod = 'paymentMethod',
  internalName = 'internalName',
  status = 'status',
  dateStart = 'dateStart',
  tipsterName = 'tipsterName',
  subtitle = 'subTitle',
  CTATitle = 'ctaTitle',
  CTASubtitle = 'ctaSubTitle',
  contactEmail = 'email',
  duration = 'duration',
  price = 'price',
  currency = 'currency',
  autoRenewal = 'autoRenewal',
  expirationAlert = 'expirationAlert',
  subscribers = 'subscribers',
  hasFreeTrial = 'hasFreeTrial',
  freeTrialPeriod = 'freeTrialPeriod',
  info = 'info',
  // FOR AFFILIATE LINKS
  dateCreated = 'dateCreated',
  expirationDate = 'expirationDate',
  registrations = 'registrations',
  // FOR ANALYTICS
  tipstersNumber = 'tipstersNumber',
  usersNumber = 'usersNumber',
  freeTips = 'freeTips',
  vipTips = 'vipTips',
  // FOR SUBSCRIPTIONS
  serviceName = 'serviceName',
  expiration = 'expiration',
  buttonRenew = 'buttonRenew',
  userId = 'userId',
  dateSubscribed = 'createdAt',
  // FOR FINANCIAL
  countryChannel = 'countryChannel',
  countryTipster = 'countryTipster',
  fee = 'fee',
  paymentOption = 'paymentOption',
  revenueVB = 'revenueVB',
  subsStatus = 'subsStatus',
  subsType = 'subsType',
  users = 'users',
  subscriptionAutomatic = 'subscriptionAutomatic',
  subscriptionManual = 'subscriptionManual',
  lastTransaction = 'lastTransaction',
  channelId = 'channelId',
  tipsterId = 'tipsterId',
  buttonPaid = 'buttonPaid',
  buttonUndoPaid = 'buttonUndoPaid',
  settled = 'settled',
  paymentType = 'paymentType',
  tipster= 'tipster',
  channel= 'channel',
  user= 'userDisplayName',
  subscription= 'subscription',
  amount = 'amount',
  commission = 'commission',
  earned = 'earned',
}

export enum TableFieldType {
  num = 'num',
  str = 'str',
  date = 'date',
  datepicker = 'datepicker',
  bool = 'bool',
  btnLink = 'btnLink',
  btn = 'btn',
  link = 'link',
  externalLink = 'externalLink',
  circleImage = 'circleImage',
  chips = 'chips',
  dropdown = 'dropdown',
  profileBackground = 'profileBackground',
  html = 'html',
}

export interface TableFieldDataType {
  field: TableField
  label: string
  type: TableFieldType
  link?: {
    base: string
    field?: TableField
    params?: TableLinkSearchParam[]
  }
}

export type TableFieldMap = Map<TableField, TableFieldDataType>

export interface TableFilterFlags {
  status?: boolean
  country?: boolean
  dateRange?: boolean
  dateRangeFilter?: boolean
  anonymity?: boolean
  dateStart?: boolean
  withCustom?: boolean
  search?: boolean
  period?: boolean
  subscriptionStatus?: boolean
  subscriptionType?: boolean
  freeTrial?: boolean
  channel?: boolean
  currency?: boolean
  paymentType?: boolean
  paymentMethod?: boolean
  settled?: boolean
}

export interface TableCustomFilterType {
  field: TableField
  label: string
  withAll?: boolean
  elements: SelectElementType[]
}

export interface TableFilterData {
  dates?: {
    dateFrom?: Date
    dateTo?: Date
  }
  createdAt?: {
    startDate?: Date
    endDate?: Date
  }
  country?: string
  channel?: string
  period?: string
  freeTrial?: string
  subscriptionStatus?: ServiceSubscriptionStatus
  subscriptionType?: string
  countryId?: string
  status?: string
  anonymity?: DualSelectOption
  dateStart?: Date
  channelName?: string
  searchQuery?: string
  currency?: string
  paymentType?: string
  paymentMethod?: string
  settled?: string
  [key: string]: unknown
}

export enum DualSelectOption {
  yes = 'yes',
  no = 'no',
}
export enum PeriodSelectOption {
  today = 'today',
  last30DaysLabel = '30',
  last90DaysLabel = '90',
}
export enum SubscriptionStatusSelectOption {
  active = 'ACTIVE',
  expired = 'INACTIVE',
  pending = 'ONHOLD',
}

export enum SubscriptionTypeOption {
  tipster = 'Tipster(individual service)',
  channel = '(Group service)',
}

export enum PaymentTypeOption {
  manual = 'Manual',
  automatic = 'Automatic',
}

export enum FreeTrialSelectOption {
  notUsed = 'INACTIVE',
  active = 'ACTIVE',
  expired = 'ONHOLD',

}

export type TableSelectElements = {
  elements: { [key in TableField]?: SelectElementType[] }
  multiple?: { [key in TableField]?: boolean }
  dualOption?: { [key in TableField]?: boolean }
}

export type TableChangeItemValue = (field: TableField, id: string, newValue: any) => void

export interface TableLinkSearchParam {
  name: string
  field: TableField
}
