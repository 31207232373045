import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'

import { PageLayout, ViewPageTemplate } from '@app/components'
import { ROUTES, AdvertDataFields } from '@app/constants'
import { DeleteAdvertDocument, GetAdvertByIdDocument } from '@app/graphql'
import { DialogConfirm, DialogResult } from '@app/ui'

interface AdvertViewPageProps {}

const AdvertViewPage: FC<AdvertViewPageProps> = () => {
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ openConfirmation, setOpenConfirmation ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const { advertId } = useParams()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const [
    getAdvert,
    { data: advertData, loading: advertLoading }
  ] = useLazyQuery(GetAdvertByIdDocument, { fetchPolicy: 'network-only' })
  const [ deleteAdvert ] = useMutation(DeleteAdvertDocument)
  const navigate = useNavigate()

  useEffect(() => {
    getAdvert({ variables: { id: advertId } })
  }, [])

  const onDelete = async () => {
    try {
      setLoading(true)
      const deleteResult = await deleteAdvert({ variables: { id: advertId } })
      if (!deleteResult.data.adsDelete) throw new Error()
      setSuccessMessage(t('deleteSuccess'))
    } catch (e) {
      setErrorMessage(t('deleteError'))
    } finally {
      setLoading(false)
    }
  }
  const onOpenDeletion = () => setOpenConfirmation(true)
  const onCloseDeletion = () => setOpenConfirmation(false)
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(ROUTES.adverts.short)
    }
  }

  return (
    <PageLayout
      title={t('advertViewPage', { ns: 'pageTitles' })}
      loading={advertLoading || loading}
    >
      <ViewPageTemplate
        editRoute={ROUTES.advertEdit.full + '/' + advertId}
        advertData={advertData?.adsById[0] ?? {}}
        dataFields={AdvertDataFields}
        onDelete={onOpenDeletion}
      />

      {/* DIALOG */}
      <DialogConfirm
        open={openConfirmation}
        header={t('attention')}
        message={t('deleteConfirmation')}
        onCancel={onCloseDeletion}
        onConfirm={onDelete}
      />
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
    </PageLayout>
  )
}

export default AdvertViewPage
