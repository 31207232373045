import { Edit } from '@mui/icons-material'
import { Box, Button, Chip, Link as MatLink, styled } from '@mui/material'
import { Link } from 'react-router-dom'

import {
  DualSelectOption,
  TableChangeItemValue,
  TableField,
  TableFieldDataType,
  TableFieldType,
  TableSelectElements
} from '@app/types'
import { TableSelect } from '@app/ui'
import { isDate, getDateStringWithMonthName } from './calendar'
import { Datepicker } from '@app/components'
import { DisabledCellButton } from '@app/constants'
import { CurrentUserState } from '@app/storage'
import { useSnapshot } from 'valtio'

export const fillCell = (
  col: TableFieldDataType,
  row: unknown,
  options?: { selectElements: TableSelectElements, onChange: TableChangeItemValue }
) => {
  const { isAdmin } = useSnapshot(CurrentUserState)
  const extractLink = () => {
    const id = (col.link?.field && row?.[col.link.field]) || ''
    let link = col.link?.base ? `${col.link.base}/${id}` : ''
    const icon = col.field === TableField.buttonEdit ? <Edit/> : null
    if (col.link?.params?.length && link) {
      col.link.params.forEach((param, i) => {
        link += i === 0 ? '?' : '&'
        link += `${param.name}=${row[param.field]}`
      })
    }
    return { link, icon }
  }
  const value = col?.field && row?.[col.field]

  switch (col.type) {
    case TableFieldType.num:
      return value ? String(value) : '0'
    case TableFieldType.str:
      return value || ''
    case TableFieldType.bool:
      return value ? 'YES' : 'NO'
    case TableFieldType.btn: {
      if (!value) return null
      const onClick = typeof value === 'function' ? value : null
      return (
        <Button variant="contained" size="small" onClick={onClick} disabled={value === DisabledCellButton}>
          {col?.label}
        </Button>
      )
    }
    case TableFieldType.btnLink: {
      const { link, icon } = extractLink()

      return (
        <Link to={link} className="vb-font not-decor">
          <Button variant="contained" size="small" disabled={!link}>
            {icon}
          </Button>
        </Link>
      )
    }
    case TableFieldType.date:
      if (!value || !isDate(new Date(value))) return ''
      return getDateStringWithMonthName(new Date(value))
    case TableFieldType.link: {
      const { link } = extractLink()

      if (!value) return <p>{value ?? ''}</p>

      return (
        <Link to={link} className="vb-font not-decor">
          <MatLink component="button">{value}</MatLink>
        </Link>
      )
    }
    case TableFieldType.circleImage:
      if (value) return <img className="vb-circle" src={value} alt="Preview" width={40} height={40}/>
      return null
    case TableFieldType.chips:
      return value?.length ?
        <StyledChipContainer>
          {
            value.map(label => <Chip label={label} key={label}/>)
          }
        </StyledChipContainer> :
        null
    case TableFieldType.dropdown: {
      let v = value ?? ''
      const elements = options?.selectElements?.elements?.[col.field] || []
      const multiple = options?.selectElements?.multiple?.[col.field]
      const dualOption = options?.selectElements?.dualOption?.[col.field]
      const onChange = options?.onChange ?
        (newValue: string | string[]) => options.onChange(col.field, row?.[TableField.id], newValue) :
        null
      if (multiple) {
        v = value || []
      } else if (dualOption) {
        v = value ? DualSelectOption.yes : DualSelectOption.no
      }
      const render = isAdmin? (
        <TableSelect
          elements={elements}
          value={v}
          multiple={multiple}
          onChange={onChange}
        />
      ): elements.filter(
        item => (Array.isArray(v)? v : [ v ]).includes(item.value)
      ).map( item => item.label ?? item.value)
      return render
    }
    case TableFieldType.datepicker: {
      const v = isDate(new Date(value)) ? new Date(value) : null
      const onChange = options?.onChange ?
        (newValue: Date) => options.onChange(col.field, row?.[TableField.id], newValue) :
        null
      return (
        <Datepicker value={v} onSelect={onChange}/>
      )
    }
  }
}

const StyledChipContainer = styled(Box)`
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 200px;
  gap: .5rem;
`
