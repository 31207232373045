import { FC, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Button, InputAdornment, IconButton, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'

import { FormHelper } from '@app/helpers'

const passwordField = 'password'
const repeatField = 'repeat'
const verifyField = 'verificationCode'

interface CreateNewPasswordProps {
  withVerificationCode?: boolean
  onSubmit: (password: string, verificationCode: string) => void
  onLogin?: () => void
}

const CreateNewPassword: FC<CreateNewPasswordProps> = ({ withVerificationCode, onSubmit, onLogin }) => {
  const [ showPassword, setShowPassword ] = useState(false)
  const [ showRepeat, setShowRepeat ] = useState(false)
  const { t } = useTranslation([ 'translation', 'forms' ])
  const formik = useFormik({
    initialValues: {
      [passwordField]: '',
      [repeatField]: '',
      [verifyField]: '',
    },
    validationSchema: yup.object().shape({
      [passwordField]: FormHelper.getNewPasswordSchemaField(),
      [repeatField]: yup.string().required(t('passwordRequiredError', { ns: 'forms' }))
        .oneOf([ yup.ref(passwordField), '' ], t('notEqualNewPasswordAndRepeatError', { ns: 'forms' })),
      [verifyField]: withVerificationCode ? yup.string().required(t('verificationCodeRequiredError', { ns: 'forms' }))
        .matches(/^\d+$/gi, t('verificationCodeOnlyDigitsError', { ns: 'forms' })) : null,
    }),
    onSubmit: values => onSubmit(values[passwordField], values[verifyField]),
  })

  const onShowPassword = () => setShowPassword(prevState => !prevState)
  const onMouseDownPrevent = (e: MouseEvent<HTMLButtonElement>) => e.preventDefault()
  const onShowRepeat = () => setShowRepeat(prevState => !prevState)

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <Typography variant="h5" component="h5" align="center" gutterBottom>
        {t('newPasswordRecoveryTitle')}
      </Typography>
      <Typography variant="body1" component="p" align="center" gutterBottom>
        {t('newPasswordRecoveryLabel')}
      </Typography>
      {
        onLogin ?
          <div className="login vb-flex-row-fe-c">
            <Button size="small" variant="outlined" onClick={onLogin}>
              {t('buttonLogin')}
            </Button>
          </div> :
          null
      }
      <TextField
        className="text-field"
        fullWidth
        id={passwordField}
        name={passwordField}
        label={t('newPasswordLabel', { ns: 'forms' })}
        value={formik.values[passwordField]}
        error={formik.touched[passwordField] && Boolean(formik.errors[passwordField])}
        helperText={formik.touched[passwordField] && t(formik.errors[passwordField])}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={onShowPassword}
                onMouseDown={onMouseDownPrevent}
              >
                {showPassword ? <VisibilityOff/> : <Visibility/>}
              </IconButton>
            </InputAdornment>
          )
        }}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <TextField
        className="text-field"
        fullWidth
        id={repeatField}
        name={repeatField}
        label={t('repeatPasswordLabel', { ns: 'forms' })}
        value={formik.values[repeatField]}
        error={formik.touched[repeatField] && Boolean(formik.errors[repeatField])}
        helperText={formik.touched[repeatField] && formik.errors[repeatField]}
        type={showRepeat ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={onShowRepeat}
                onMouseDown={onMouseDownPrevent}
              >
                {showRepeat ? <VisibilityOff/> : <Visibility/>}
              </IconButton>
            </InputAdornment>
          )
        }}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {
        withVerificationCode &&
        <TextField
          className="text-field"
          fullWidth
          id={verifyField}
          name={verifyField}
          label={t('verificationCodeLabel', { ns: 'forms' })}
          value={formik.values[verifyField]}
          error={formik.touched[verifyField] && Boolean(formik.errors[verifyField])}
          helperText={formik.touched[verifyField] && formik.errors[verifyField]}
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      }
      <div className="vb-flex-row-c-c">
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={
            Boolean(formik.errors[repeatField]) ||
            Boolean(formik.errors[passwordField]) ||
            (Boolean(formik.errors[verifyField]) && withVerificationCode)
          }
        >
          {t('buttonSubmit')}
        </Button>
      </div>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  width: 40%;

  .text-field, .login {
    margin-bottom: 10px;
  }
`

export default CreateNewPassword
