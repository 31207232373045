import { FC, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import {
  SimplifiedLoginPage,
  SuperadminRoutes,
  UserRoutes,
  TipsterRoutes,
  AdvertRoutes,
  ChannelRoutes,
  ServiceRoutes,
  NotificationPage,
  AffiliateLinkRoutes,
  AnalyticReportLinkRoutes,
  SubscriptionRoutes,
  FinancialRoutes,
  TipsterInvitationRoutes,
} from '@app/pages'
import { ROUTES } from '@app/constants'
import { GetCurrentUserDocument, UserRoles } from '@app/graphql'
import { CurrentUserState, resetCurrentUserState, updateCurrentUserState } from '@app/storage'
import { PageLayout, ProtectedRoute } from '@app/components'
import { PermissionHelper } from '@app/helpers'
import { useSnapshot } from 'valtio'

interface ApplicationProps {}

const Application: FC<ApplicationProps> = () => {
  const [ loading, setLoading ] = useState(true)
  const [ getCurrentUser ] = useLazyQuery(GetCurrentUserDocument)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const { isAdmin } = useSnapshot(CurrentUserState)

  useEffect(() => {
    const load = async () => {
      try {
        const result = await getCurrentUser()
        const {
          id,
          displayName,
          role,
          tipsterStatus,
          permissions,
          channelsWhereUserIsTipster
        } = result.data.userCurrent
        const userPerms = PermissionHelper.checkPermissions(permissions, role)
        updateCurrentUserState({
          ...userPerms,
          displayName,
          id,
          role,
          tipsterStatus,
          isAdmin: role === UserRoles.Admin,
          isTipster: role === UserRoles.Tipster,
          channelsWhereUserIsTipster: channelsWhereUserIsTipster ?? [],
        })
      } catch (e) {
        resetCurrentUserState()
      } finally {
        setLoading(false)
      }
    }
    load()
  }, [])

  if (loading) return <PageLayout title={t('login', { ns: 'pageTitles' })} hideHeader loading/>

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Navigate to={ROUTES.login} replace/>}/>
        <Route
          path={ROUTES.login}
          element={<ProtectedRoute element={<SimplifiedLoginPage/>} isLogin/>}
        />
        <Route
          path={ROUTES.superadmins.root}
          element={<ProtectedRoute element={<SuperadminRoutes/>} onlyAdmin/>}
        />
        <Route
          path={ROUTES.users.root}
          element={<ProtectedRoute element={<UserRoutes isAdmin={isAdmin}/>}/>}
        />
        <Route
          path={ROUTES.tipsters.root}
          element={<ProtectedRoute element={<TipsterRoutes/>}/>}
        />
        <Route
          path={ROUTES.adverts.root}
          element={<ProtectedRoute element={<AdvertRoutes/>} onlyAdmin/>}
        />
        <Route
          path={ROUTES.channels.root}
          element={<ProtectedRoute element={<ChannelRoutes isAdmin={isAdmin}/>}/>}
        />
        <Route
          path={ROUTES.services.root}
          element={<ProtectedRoute element={<ServiceRoutes/>}/>}
        />
        <Route
          path={ROUTES.notifications.short}
          element={<ProtectedRoute element={<NotificationPage/>} onlyAdmin/>}
        />
        <Route
          path={ROUTES.affiliateLinks.root}
          element={<ProtectedRoute element={<AffiliateLinkRoutes/>} onlyAdmin/>}
        />
        <Route
          path={ROUTES.analytics.root}
          element={<ProtectedRoute element={<AnalyticReportLinkRoutes/>} onlyAdmin/>}
        />
        <Route
          path={ROUTES.subscriptions.root}
          element={<ProtectedRoute element={<SubscriptionRoutes/>}/>}
        />
        <Route
          path={ROUTES.financial.root}
          element={<ProtectedRoute element={<FinancialRoutes/>}/>}
        />
        <Route
          path={ROUTES.tipsterInvite.root}
          element={<ProtectedRoute element={<TipsterInvitationRoutes/>}/>}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Application
