import { FC, Fragment } from 'react'
import { Grid, Switch } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { NotificationItemType } from '@app/types'

interface NotificationListProps {
  items: NotificationItemType[]
  onChange: (notificationId: string, value: boolean) => void
}

const NotificationList: FC<NotificationListProps> = ({
  items,
  onChange,
}) => {
  const { t } = useTranslation([ 'translation' ])

  const handleChange = (notificationId: string, value: boolean) => () => onChange(notificationId, value)

  return (
    <Grid container spacing={2}>
      {
        items.map(sn => (
          <Fragment key={sn.id}>
            <Grid
              item
              xs={4}
              className="vb-flex-row-fe-c vb-font big w700 black"
            >
              {t(sn.label)}
            </Grid>
            <Grid
              item
              xs={8}
              className="vb-flex-row-fs-c"
            >
              <Switch
                checked={sn.value}
                onChange={handleChange(sn.id, !sn.value)}
              />
            </Grid>
          </Fragment>
        ))
      }
    </Grid>
  )
}

export default NotificationList
