import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'

import { PageLayout, ViewPageTemplate } from '@app/components'
import { ROUTES, AffiliateLinkDataFields, MAIN_APP_DOMAIN_URL, AFFILIATE_LINK_INTERNAL_NAME_KEY } from '@app/constants'
import { DeleteAffiliateLinkDocument, GetAffiliateLinkByIdDocument } from '@app/graphql'
import { DialogResult } from '@app/ui'
import { TableField } from '@app/types'
import { Button, Grid, styled, TextField } from '@mui/material'

interface AffiliateLinkViewPageProps {}

const AffiliateLinkViewPage: FC<AffiliateLinkViewPageProps> = () => {
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ openCopied, setOpenCopied ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const { affiliateLinkId } = useParams()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const navigate = useNavigate()
  const [
    getLink,
    { data: linkData, loading: linkLoading, error: linkError }
  ] = useLazyQuery(GetAffiliateLinkByIdDocument, { fetchPolicy: 'network-only' })
  const [ deleteLink ] = useMutation(DeleteAffiliateLinkDocument)

  useEffect(() => {
    getLink({ variables: { id: affiliateLinkId } })
  }, [])

  const onDelete = async () => {
    try {
      setLoading(true)
      const result = await deleteLink({ variables: { id: affiliateLinkId } })
      if (result.data.affilateLinkDelete.result) {
        setSuccessMessage(t('deleteSuccess'))
      } else {
        throw new Error()
      }
    } catch (e) {
      setErrorMessage(t('deleteError'))
    } finally {
      setLoading(false)
    }
  }
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(ROUTES.affiliateLinks.short)
    }
  }
  const onCloseCopiedDialog = () => setOpenCopied(false)

  const data = linkData?.affilateLink ? {
    ...linkData.affilateLink,
    [TableField.channelName]: linkData.affilateLink.channel?.channelName,
    [TableField.registrations]: linkData.affilateLink.usersNumber || 0,
  } : null
  const encodedUrl = linkData?.affilateLink?.internalName ?
    encodeURI(MAIN_APP_DOMAIN_URL + '?' + AFFILIATE_LINK_INTERNAL_NAME_KEY + '=' + linkData.affilateLink.internalName) :
    encodeURI(MAIN_APP_DOMAIN_URL)
  const onCopyLink = () => {
    navigator?.clipboard?.writeText(encodedUrl)
    setOpenCopied(true)
  }

  if (!affiliateLinkId || linkError) return <Navigate to={ROUTES.affiliateLinks.short} replace/>

  return (
    <PageLayout
      title={t('affiliateLinkViewPage', { ns: 'pageTitles' })}
      loading={linkLoading || loading}
    >
      <StyledCopyBlock
        container
        spacing={2}
      >
        <Grid
          className="vb-flex-row-fe-c"
          item
          xs={3}
        >
          <Button variant="contained" onClick={onCopyLink}>
            {t('copyLink')}
          </Button>
        </Grid>
        <Grid
          className="vb-flex-row-fs-c"
          item
          xs={6}
        >
          <TextField
            value={encodedUrl}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={3}></Grid>
      </StyledCopyBlock>
      <ViewPageTemplate
        editRoute={ROUTES.affiliateLinkEdit.full + '/' + affiliateLinkId}
        dataFields={AffiliateLinkDataFields}
        affiliateLinkData={data}
        onDelete={onDelete}
      />

      {/* DIALOG */}
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
      <DialogResult
        open={openCopied}
        type={'success'}
        message={t('copiedSuccessfully')}
        onClose={onCloseCopiedDialog}
      />
    </PageLayout>
  )
}

const StyledCopyBlock = styled(Grid)`
  width: 100%;
  padding-top: 2rem;
`

export default AffiliateLinkViewPage
