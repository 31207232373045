import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'
import { useLazyQuery } from '@apollo/client'

import { PageLayout, CreateNewPassword } from '@app/components'
import { AuthHelper } from '@app/helpers'
import { LoginForm } from './components'
import { CognitoUser } from '@app/types'
import { GetCurrentUserDocument } from '@app/graphql'

interface LoginPageProps {}

const LoginPage: FC<LoginPageProps> = () => {
  const [ loading, setLoading ] = useState(false)
  const [ loginError, setLoginError ] = useState<string>(null)
  const [ openDialog, setOpenDialog ] = useState(false)
  const [ successfully, setSuccessfully ] = useState(false)
  const [ cognitoUser, setCognitoUser ] = useState<CognitoUser>(null)
  const { t } = useTranslation([ 'translation', 'forms', 'pageTitles' ])
  const [ getCurrentUser ] = useLazyQuery(GetCurrentUserDocument)

  const loadCurrentUser = async () => {
    const userResponse = await getCurrentUser()
    return Boolean(userResponse?.data?.userCurrent && !userResponse?.error)
  }

  const onSubmitLogin = async (email: string, password: string) => {
    setLoading(true)
    const { data, error, newPasswordRequired } = await AuthHelper.logIn(email, password)
    if (data && !error) {
      setLoginError(null)
      if (newPasswordRequired) {
        setCognitoUser(data)
      }
      const userLoaded = await loadCurrentUser()
      if (!userLoaded) setLoginError(t('loginFailedError'))
      // todo: go to home page, when it's ready
    } else {
      setLoginError(t('loginFailedError'))
    }
    setLoading(false)
  }

  const onSubmitNewPassword = async (password: string) => {
    setLoading(true)
    const { data, error } = await AuthHelper.completeNewPassword(cognitoUser, password)
    if (data && !error) {
      const userLoaded = await loadCurrentUser()
      setSuccessfully(userLoaded)
    } else {
      setSuccessfully(false)
    }
    setOpenDialog(true)
    setLoading(false)
  }

  const onCloseDialog = () => {
    setOpenDialog(false)
    if (successfully) {
      // todo: go to home page, when it's ready
    }
  }

  return (
    <PageLayout title={t('login', { ns: 'pageTitles' })} loading={loading} hideHeader>
      <StyledFormContainer className="vb-flex-column-fs-c">
        {
          cognitoUser ?
            <CreateNewPassword onSubmit={onSubmitNewPassword}/> :
            <LoginForm onSubmit={onSubmitLogin} loginError={loginError}/>
        }
      </StyledFormContainer>

      {/* DIALOGS */}
      <Dialog open={openDialog} onClose={onCloseDialog}>
        <DialogTitle>
          {
            successfully ?
              t('passwordChangedSuccessfully') :
              <StyledErrorDialog className="vb-flex-column-c-c">
                <WarningAmber color="warning"/>
                {t('passwordChangeFailed')}
              </StyledErrorDialog>
          }
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant="contained" autoFocus onClick={onCloseDialog}>
            {t('buttonOk')}
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  )
}

const StyledFormContainer = styled.div`
  width: 100%;
  margin-top: 200px;
`

const StyledErrorDialog = styled.div`
  text-align: center;
`

export default LoginPage
