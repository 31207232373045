import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Box, Button } from '@mui/material'
import { useSnapshot } from 'valtio'

import { PageLayout, ViewPageTemplate } from '@app/components'
import { ROUTES, ServiceDataFields } from '@app/constants'
import {
  DeleteServiceDocument,
  DeleteServiceDurationDocument,
  GetChannelNameDocument,
  GetServiceByIdDocument,
  GetSubscriptionWithFreeTrialDocument,
  GetUserDisplayNameDocument,
  UserPermission,
} from '@app/graphql'
import { DialogConfirm, DialogResult } from '@app/ui'
import { ServiceViewPageData } from '@app/types'
import { CurrentUserState } from '@app/storage'
import { useGetPermissions } from '@app/hooks'

enum DeletionType {
  service = 'SERVICE',
  duration = 'DURATION',
}

interface ServiceViewPageProps {}

const ServiceViewPage: FC<ServiceViewPageProps> = () => {
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ freeTrialSubId, setFreeTrialSubId ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ shouldNavigate, setShouldNavigate ] = useState(false)
  const [ openConfirmation, setOpenConfirmation ] = useState<DeletionType>(null)
  const [ serviceData, setServiceData ] = useState<ServiceViewPageData>(null)
  const [ durationId, setDurationId ] = useState<string>(null)
  const [ loading, setLoading ] = useState(true)
  const navigate = useNavigate()
  const { serviceId } = useParams()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const { id: currentUserId } = useSnapshot(CurrentUserState)
  const { checkPermission, loading: permsLoading } = useGetPermissions({ userId: currentUserId })
  /* QUERIES AND MUTATIONS */
  const [ getService ] = useLazyQuery(GetServiceByIdDocument, { fetchPolicy: 'network-only' })
  const [ getFreeTrialSub ] = useLazyQuery(GetSubscriptionWithFreeTrialDocument, { fetchPolicy: 'network-only' })
  const [ getTipsterName ] = useLazyQuery(GetUserDisplayNameDocument)
  const [ getChannelName ] = useLazyQuery(GetChannelNameDocument)
  const [ deleteService ] = useMutation(DeleteServiceDocument)
  const [ deleteDuration ] = useMutation(DeleteServiceDurationDocument)

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true)
        const serviceResult = await getService({ variables: { id: serviceId } })
        if (serviceResult.data.service) {
          const { tipsterId, channelId, paymentMethods } = serviceResult.data.service
          const [ tipsterResult, channelResult ] = await Promise.all([
            getTipsterName({ variables: { id: tipsterId } }),
            getChannelName({ variables: { channelId } })
          ])


          const freeTrialSubResult = await getFreeTrialSub({ variables: { serviceId } })
          const freeTrialId = freeTrialSubResult?.data?.subscriptionWithFreeTrial?.id
          if (freeTrialId) {
            const period = freeTrialSubResult.data.subscriptionWithFreeTrial.freeTrialPeriod
            const freeTrialPeriod = period ? (period === 1 ? `1 ${t('day')}` : `${period} ${t('days')}`) : t('no')
            setServiceData(prev => ({
              ...prev,
              freeTrialPeriod,
            }))
            setFreeTrialSubId(freeTrialId)
          }
        }
      } finally {
        setLoading(false)
      }
    }
    load()
  }, [])

  const onDelete = () => {
    openConfirmation === DeletionType.service ?
      onDeleteService() :
      onDeleteDuration()
    setOpenConfirmation(null)
  }
  const onDeleteService = async () => {
    try {
      setLoading(true)
      setShouldNavigate(true)
      const result = await deleteService({ variables: { id: serviceId } })
      if (!result.data.serviceDelete) throw new Error()
      setSuccessMessage(t('deleteSuccess'))
    } catch (e) {
      setErrorMessage(t('deleteError'))
    } finally {
      setLoading(false)
    }
  }
  const onOpenDeletion = (deletion: DeletionType) => (delDurationId?: string) => {
    setOpenConfirmation(deletion)
    setDurationId(delDurationId)
  }
  const onCloseDeletion = () => setOpenConfirmation(null)
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      if (shouldNavigate) {
        navigate(ROUTES.services.short)
      } else {
        getService({ variables: { id: serviceId } })
      }
    }
  }
  const onDeleteDuration = async () => {
    try {
      setLoading(true)
      setShouldNavigate(false)
      const result = await deleteDuration({ variables: { id: durationId } })
      if (!result.data.serviceDurationDelete) throw new Error()
      setSuccessMessage(t('deleteSuccess'))
    } catch (e) {
      setErrorMessage(t('deleteError'))
    } finally {
      setLoading(false)
      setDurationId(null)
    }
  }

  const editSubDisabled = permsLoading || !checkPermission(UserPermission.EditSubs)
  const createSubDisabled = permsLoading || !checkPermission(UserPermission.AddSubs)
  const editSubLink = editSubDisabled ? '' : (ROUTES.freeTrialEdit.full + '/' + freeTrialSubId)
  const createSubLink = createSubDisabled ? '' : (ROUTES.freeTrialCreate.full + '/' + serviceData?.id)
  const additionalButtons = (
    <>
      {
        freeTrialSubId ?
          <Box sx={{ ml: 2 }}>
            <Link
              to={editSubLink}
              className="vb-font not-decor"
            >
              <Button variant="contained" disabled={editSubDisabled}>
                {t('editFreeTrialSub')}
              </Button>
            </Link>
          </Box> :
          <Box sx={{ ml: 2 }}>
            <Link
              to={createSubLink}
              className="vb-font not-decor"
            >
              <Button variant="contained" disabled={createSubDisabled}>
                {t('createFreeTrialSub')}
              </Button>
            </Link>
          </Box>
      }
    </>
  )

  return (
    <PageLayout
      title={t('serviceViewPage', { ns: 'pageTitles' })}
      loading={loading}
    >
      <ViewPageTemplate
        editRoute={ROUTES.serviceEdit.full + '/' + serviceId}
        serviceData={serviceData}
        dataFields={ServiceDataFields}
        additionalButtons={additionalButtons}
        onDelete={onOpenDeletion(DeletionType.service)}
      />

      {/* DIALOG */}
      <DialogConfirm
        open={Boolean(openConfirmation)}
        header={t('attention')}
        message={t('deleteConfirmation')}
        onCancel={onCloseDeletion}
        onConfirm={onDelete}
      />
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
    </PageLayout>
  )
}

export default ServiceViewPage
