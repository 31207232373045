import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { FormElementCreation, PageLayout, PermissionTab } from '@app/components'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useSnapshot } from 'valtio'

import { CreateTipsterValues, FormFieldDataType, FormFieldType, SelectElementType } from '@app/types'
import { ROUTES, TipsterPermissionList } from '@app/constants'
import {
  ChannelStatus,
  ChannelType,
  GetChannelsForTipsterPageDocument,
  GetTipsterChannelsDocument,
  GetUserDocument,
  TipsterStatus,
  UpdateTipsterChannelsDocument,
  UpdateUserDocument,
  UpdateUserPermissionsDocument,
  UploadImageDocument,
  UserPermission,
  UserRoles,
  UserStatus
} from '@app/graphql'
import { DialogResult } from '@app/ui'
import { useCallbackPrompt, useGetPermissions } from '@app/hooks'
import { FormHelper } from '@app/helpers'
import { CurrentUserState } from '@app/storage'

enum TabOptions {
  data = 'DATA',
  permissions = 'PERMISSIONS',
}

const emailField = 'email'
const displayNameField = 'displayName'
const countryField = 'country'
const languageField = 'language'
const altNameField = 'altName'
const dealField = 'deal'
const fileField = 'file'
const altFileField = 'altFile'
const roleField = 'role'
const channelField = 'channels'
const bioField = 'bio'
const backgroundField = 'backgroundFile'

interface TipsterCreatePageProps {
  editMode?: boolean
}

const TipsterCreatePage: FC<TipsterCreatePageProps> = ({ editMode }) => {
  const [ blockLeaving, setBlockLeaving ] = useState(true)
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ tipsterChannels, setTipsterChannels ] = useState<string[]>([])
  const [ filteredChannels, setFilteredChannels ] = useState<SelectElementType[]>([])
  const [ currentTab, setCurrentTab ] = useState(TabOptions.data)
  const [ loading, setLoading ] = useState(true)
  const { tipsterId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const { dialog } = useCallbackPrompt(blockLeaving)
  const { id: currentUserId } = useSnapshot(CurrentUserState)
  const { checkPermission, loading: permsLoading } = useGetPermissions({ userId: currentUserId })
  /* QUERIES AND MUTATIONS */
  const [
    getUser,
    { data: userData, loading: userLoading, error: userError }
  ] = useLazyQuery(GetUserDocument, { fetchPolicy: 'network-only' })
  const [ getChannels ] = useLazyQuery(GetChannelsForTipsterPageDocument, { fetchPolicy: 'network-only' })
  const [ getTipsterChannels ] = useLazyQuery(GetTipsterChannelsDocument, { fetchPolicy: 'network-only' })
  const [ updateTipster ] = useMutation(UpdateUserDocument)
  const [ uploadImage ] = useMutation(UploadImageDocument, { context: { operation: 'imageUpload' } })
  const [ updateChannels ] = useMutation(UpdateTipsterChannelsDocument)
  const [ updatePermissions ] = useMutation(UpdateUserPermissionsDocument)

  useEffect(() => {
    if (editMode) getUser({ variables: { id: tipsterId } })
    const load = async () => {
      setLoading(true)
      try {
        const [ resultChannels, resultTipsterChannels ] = await Promise.all([
          getChannels({
            variables: {
              filter: {
                channelStatus: ChannelStatus.Active
              },
              sort: {},
              limit: 10000,
              skip: 0,
            }
          }),
          getTipsterChannels({ variables: { userId: tipsterId } })
        ])

        const channels = resultChannels.data.channels.channels.reduce((res, ch) => {
          if (ch.channelType === ChannelType.Private) {
            res.push({ value: ch.id, label: ch.channelName })
          }
          return res
        }, [])
        setFilteredChannels(channels)
        const newTipsterChannels = resultTipsterChannels?.data.userChannels?.map(ch => ch.id)
        if (newTipsterChannels) setTipsterChannels(newTipsterChannels)
      } finally {
        setLoading(false)
      }
    }
    load()
  }, [])

  const onSubmit = async (values: CreateTipsterValues) => {
    const upload = async (file: File) => {
      if (file) {
        const result = await uploadImage({ variables: { image: file } })
        return result?.data?.imageUpload
      }
      return Promise.resolve(null)
    }
    try {
      setLoading(true)
      setBlockLeaving(false)
      if (editMode && userData?.user?.role === UserRoles.Tipster) {
        const tipsterId = userData.user.id
        let avatarUrl = userData.user.avatarUrl
        let alternativeAvatarUrl = userData.user.alternativeAvatarUrl
        let backgroundUrl = userData.user.background
        const [ avatarPath, altAvatarPath, backgroundPath ] = await Promise.all([
          upload(values.file),
          upload(values.altFile),
          upload(values.backgroundFile),
        ])

        avatarUrl = avatarPath || avatarUrl
        alternativeAvatarUrl = altAvatarPath || alternativeAvatarUrl
        backgroundUrl = backgroundPath || backgroundUrl
        const newRole = values.role === UserRoles.User ?
          { role: UserRoles.User, tipsterStatus: null, userStatus: UserStatus.Active } :
          {}

        const [ { data, errors } ] = await Promise.all([
          updateTipster({
            variables: {
              id: tipsterId,
              input: {
                emailAddress: values.email,
                displayName: values.displayName,
                countryId: values.country,
                avatarUrl,
                alternativeName: values.altName,
                alternativeAvatarUrl,
                bio: values.bio,
                background: backgroundUrl,
                ...newRole,
              },
            },
          }),
          values.channels ?
            updateChannels({
              variables: {
                channelIds: values.channels,
                tipsterId,
              },
            }) :
            Promise.resolve()
        ])
        if (data?.userUpdate?.id && !errors) {
          setSuccessMessage(t('updatingSuccess'))
        } else {
          throw new Error()
        }
      } else {
        // todo: add creation of new tipster (awaiting backend)
        throw new Error()
      }
    } catch (e) {
      setBlockLeaving(true)
      setErrorMessage(editMode ? t('updatingFailure') : t('creationFailure'))
    } finally {
      setLoading(false)
    }
  }
  const onChangeStatus = async (isActive: boolean) => {
    if (!hasPermStatus) return
    try {
      setLoading(true)
      const result = await updateTipster({ variables: {
        id: userData?.user?.id,
        input: {
          tipsterStatus: isActive ? TipsterStatus.Inactive : TipsterStatus.Active
        },
      } })
      if (result.data.userUpdate.id) await getUser({ variables: { id: userData?.user?.id } })
    } catch (e) {
      setErrorMessage(t('updatingFailure'))
    } finally {
      setLoading(false)
    }
  }
  const onCancel = () => navigate(-1)
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(ROUTES.tipsters.short)
    }
  }
  const onChangeTab = (e: SyntheticEvent, newValue: TabOptions) => {
    if (hasPermChange) {
      setCurrentTab(newValue)
    } else {
      setCurrentTab(TabOptions.data)
    }
  }
  const onSubmitPermissions = async (list: UserPermission[]) => {
    if (!hasPermChange) return
    try {
      setLoading(true)
      const result = await updatePermissions({
        variables: {
          userId: tipsterId,
          permissions: list,
        }
      })
      if (!result.data.userPermissionsUpdate.updated) throw new Error()
      getUser({ variables: { id: tipsterId } })
      setSuccessMessage(t('updatePermissionsSuccess'))
    } catch (e) {
      setErrorMessage(t('updatingFailure'))
    } finally {
      setLoading(false)
    }
  }

  const pageTitle = editMode ? t('editTipster', { ns: 'pageTitles' }) :
    t('createTipster', { ns: 'pageTitles' })
  const title = editMode ? t('editTipster') : t('createNewTipster')
  const fields: FormFieldDataType[] = [
    {
      name: emailField,
      label: t('email'),
      type: FormFieldType.text,
      initValue: userData?.user?.emailAddress || '',
      validation: FormHelper.getEmailSchemaField(),
      required: true,
    },
    {
      name: displayNameField,
      label: t('displayName'),
      type: FormFieldType.text,
      initValue: userData?.user?.displayName || '',
      validation: FormHelper.getRequiredStringSchema().max(100, t('max100LengthError', { ns: 'forms' })),
      required: true,
    },
    {
      name: fileField,
      label: t('uploadAvatar'),
      type: FormFieldType.image,
      initValue: null,
      validation: yup.mixed(),
      required: true,
      image: {
        link: userData?.user?.avatarUrl,
        alt: 'Tipster Avatar',
        width: 80,
        height: 80,
        circle: true,
      },
    },
    {
      name: backgroundField,
      label: t('background'),
      type: FormFieldType.image,
      initValue: null,
      validation: yup.mixed(),
      image: {
        link: userData?.user?.background,
        alt: 'Tipster Background',
        width: 360,
        height: 120,
      },
    },
    {
      name: countryField,
      label: t('country'),
      type: FormFieldType.country,
      initValue: userData?.user?.countryData?.id || userData?.user?.countryId,
      validation: FormHelper.getRequiredStringSchema(),
      required: true,
    },
    {
      name: languageField,
      label: t('language'),
      type: FormFieldType.text,
      initValue: '',
      validation: yup.string().max(100, t('max100LengthError', { ns: 'forms' })),
    },
    {
      name: altNameField,
      label: t('altName'),
      type: FormFieldType.text,
      initValue: userData?.user?.alternativeName || '',
      validation: FormHelper.getRequiredStringSchema().max(100, t('max100LengthError', { ns: 'forms' })),
      required: true,
    },
    {
      name: altFileField,
      label: t('uploadAlternativeAvatar'),
      type: FormFieldType.image,
      initValue: null,
      validation: yup.mixed(),
      required: true,
      image: {
        link: userData?.user?.alternativeAvatarUrl,
        alt: 'Tipster Alternative Avatar',
        width: 80,
        height: 80,
        circle: true,
      },
    },
    {
      name: dealField,
      label: t('deal'),
      type: FormFieldType.number,
      initValue: 0,
      validation: FormHelper.getRequiredNumberSchema()
        .min(0, t('minNumberZeroError', { ns: 'forms' }))
        .max(100, t('maxNumber100Error', { ns: 'forms' })),
      required: true,
    },
    {
      name: channelField,
      label: t('channels'),
      type: FormFieldType.select,
      elements: [ ...filteredChannels ],
      initValue: [ ...tipsterChannels ],
      validation: yup.array().of(yup.string()).min(1, t('requiredError', { ns: 'forms' })),
      required: true,
      multiple: true,
    },
    {
      name: roleField,
      label: t('role'),
      type: FormFieldType.select,
      elements: FormHelper.userRoleList,
      initValue: userData?.user?.role,
      validation: yup.string(),
    },
    {
      name: bioField,
      label: t('bio'),
      type: FormFieldType.text,
      initValue: userData?.user?.bio || '',
      validation: yup.string().max(250, t('max250LengthError', { ns: 'forms' })),
      multiline: true,
    },
  ]
  const hasPermChange = !permsLoading && checkPermission(UserPermission.EditTipster)
  const hasPermStatus = !permsLoading && checkPermission(UserPermission.ChangeUserStatus)

  if (editMode && (!tipsterId || userError)) navigate(-1)

  return (
    <PageLayout title={pageTitle} loading={(editMode && userLoading) || loading}>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h4" gutterBottom>
          {title}
        </Typography>
        <Tabs
          value={currentTab}
          onChange={onChangeTab}
        >
          <Tab
            label={t('data')}
            value={TabOptions.data}
          />
          {
            hasPermChange ?
              <Tab
                label={t('permissions')}
                value={TabOptions.permissions}
              /> :
              null
          }
        </Tabs>

        <Box sx={{ width: '700px' }}>
          {
            currentTab === TabOptions.data ?
              <FormElementCreation
                fields={fields}
                state={{
                  active: userData?.user?.tipsterStatus === TipsterStatus.Active,
                }}
                onSubmit={onSubmit}
                onCancel={onCancel}
                onActivate={hasPermStatus ? onChangeStatus : null}
              /> :
              null
          }
          {
            currentTab === TabOptions.permissions && hasPermChange ?
              <PermissionTab
                permissions={TipsterPermissionList}
                list={userData?.user?.permissions || []}
                onSubmit={onSubmitPermissions}
              /> :
              null
          }
        </Box>
      </Box>

      {/* DIALOG */}
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
      { dialog }
    </PageLayout>
  )
}

export default TipsterCreatePage
