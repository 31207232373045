import {
  ROUTES,
  SEARCH_PARAMS,
  TableAdvertFields,
  TableAffiliateLinkFields,
  TableAnalyticsFields,
  TableChannelFields,
  TableFieldsMap,
  TableFinancialFields,
  TableNewFinancialFields,
  TableServiceFields,
  TableSubscriberFields,
  TableSubscriptionFields,
  TableSuperadminFields,
  TableTipsterFields,
  TableUserFields,
  TableTipsterInvitationFields,
} from '@app/constants'
import { TableField, TableFieldDataType, TableFieldType, TableLinkSearchParam } from '@app/types'
import i18n from '@app/i18n'

const mapFields = (
  field: TableField,
  links: { id: string, edit: string },
  options?: {
    dropdown?: Set<TableField>,
    datepicker?: Set<TableField>,
    asLink?: Map<TableField, { field?: TableField, base: string, params?: TableLinkSearchParam[] }>,
    rename?: Map<TableField, string>
  }
) => {
  const fieldDescription = { ...TableFieldsMap.get(field) }
  if (options) {
    if (options.dropdown?.has(field)) fieldDescription.type = TableFieldType.dropdown
    if (options.datepicker?.has(field)) fieldDescription.type = TableFieldType.datepicker
    if (options.asLink?.has(field)) {
      fieldDescription.type = TableFieldType.link
      const linkData = options.asLink.get(field)
      fieldDescription.link = { ...linkData }
    }
    if (options.rename?.has(field)) fieldDescription.label = options.rename.get(field)
  }

  switch (field) {
    case TableField.id:
      return {
        ...fieldDescription,
        link: {
          field: TableField.id,
          base: links.id,
        },
      }
    case TableField.buttonEdit:
      return {
        ...fieldDescription,
        link: {
          field: TableField.id,
          base: links.edit,
        },
      }
    default:
      return fieldDescription
  }
}

const excludeTableFields = (tableFields: TableField[], fields: TableField[]) => (
  tableFields.filter(field => !fields.includes(field))
)

export const parseSuperadminFields = () => {
  return TableSuperadminFields.map(field => mapFields(
    field,
    { id: ROUTES.superadmins.short, edit: ROUTES.superadminEdit.full }
  ))
}

export const parseUserFields = (isAdmin=true) => {
  const excludedFields = isAdmin? [] : [
    TableField.id,
    TableField.buttonEdit,
    TableField.source,
    TableField.birthday,
    TableField.dateJoined,
  ]
  return excludeTableFields(TableUserFields, excludedFields)
    .map(field => mapFields(
      field,
      { id: ROUTES.users.short, edit: ROUTES.userEdit.full },
      { dropdown: new Set([ TableField.userStatus ]) }
    ))
}

export const parseTipsterFields = (isAdmin: boolean) => {
  const excludedFields = isAdmin? [] : [
    TableField.id,
    TableField.buttonEdit,
    TableField.tipsterStatus,
  ]
  return excludeTableFields(TableTipsterFields, excludedFields).map(field => mapFields(
    field,
    { id: ROUTES.tipsters.short, edit: ROUTES.tipsterEdit.full },
    { dropdown: new Set([ TableField.tipsterStatus, TableField.country, TableField.tipsterChannels ]) }
  ))
}

export const parseTipsterInvitationFields = () => {
  return TableTipsterInvitationFields.map(field => mapFields(
    field,
    { id: ROUTES.adverts.short, edit: '' },
    {
      asLink: new Map([
        [ TableField.tipsterId, { field: TableField.tipsterId, base: ROUTES.tipsters.short } ],
      ]),
    }
  ))
}

export const parseAdvertFields = () => {
  return TableAdvertFields.map(field => mapFields(
    field,
    { id: ROUTES.adverts.short, edit: ROUTES.advertEdit.full },

    {
      dropdown: new Set([ TableField.visible, TableField.active, TableField.placement ]),
      datepicker: new Set([ TableField.startDate, TableField.endDate ]),
      asLink: new Map([
        [ TableField.title, { field: TableField.id, base: ROUTES.advertEdit.full } ],
      ]),
    }
  ))
}

export const parseChannelFields = (isAdmin: boolean) => {
  const excludedFields = isAdmin? [] : [
    TableField.id,
    TableField.buttonEdit,
    TableField.channelType,
    TableField.anonymized,
    TableField.channelStatus
  ]
  return excludeTableFields(TableChannelFields, excludedFields)
    .map(field => mapFields(
      field,
      { id: ROUTES.channels.short, edit: ROUTES.channelEdit.full },
      {
        dropdown: new Set([ TableField.channelStatus ]),
        rename: new Map<TableField, string>([
          [ TableField.dateJoined, i18n.t('dateCreated') ],
        ]),
        asLink: new Map([
          [ TableField.tipsterCount,
            {
              field: null,
              base: ROUTES.tipsters.short,
              params: [
                { name: SEARCH_PARAMS.FROM_CHANNEL, field: TableField.id },
              ],
            }
          ],
          [ TableField.usersCount,
            {
              field: null,
              base: ROUTES.users.short,
              params: [
                { name: SEARCH_PARAMS.FROM_CHANNEL, field: TableField.id },
              ],
            }
          ],
          [ TableField.channelName, { field: TableField.id, base: ROUTES.channels.short } ],
        ]),
      }
    ))
}

export const parseServiceFields = (isAdmin: boolean) => {
  const excludedFields = isAdmin? [] : [
    TableField.id,
    TableField.buttonEdit,
  ]
  return excludeTableFields(TableServiceFields, excludedFields).map(field => mapFields(
    field,
    { id: ROUTES.services.short, edit: ROUTES.serviceEdit.full },
    {
      dropdown: new Set([ TableField.status, TableField.autoRenewal ]),
      datepicker: !isAdmin? null: new Set([ TableField.dateStart ]),
      asLink: !isAdmin? null : new Map([
        [ TableField.internalName, { field: TableField.id, base: ROUTES.services.short } ],
        [ TableField.tipsterName, { field: TableField.tipsterId, base: ROUTES.tipsters.short } ],
        [ TableField.channelName, { field: TableField.channelId, base: ROUTES.channels.short } ],
      ]),
    }
  ))
}

export const parseAffiliateLinkFields = () => {
  return TableAffiliateLinkFields.map(field => mapFields(
    field,
    { id: ROUTES.affiliateLinks.short, edit: ROUTES.affiliateLinkEdit.full },
    { datepicker: new Set([ TableField.expirationDate ]), }
  ))
}

export const parseAnalyticsFields = () => {
  return TableAnalyticsFields.map(field => mapFields(
    field,
    { id: ROUTES.analytics.short, edit: ROUTES.analytics.short, }
  ))
}

export const parseSubscriptionFields = (disableEditing?: boolean) => {
  return TableSubscriptionFields.map(field => mapFields(
    field,
    { id: ROUTES.subscriptions.short, edit: disableEditing ? '' : ROUTES.subscriptionEdit.full }
  ))
}

export const parseSubscriberFields = () => {
  return TableSubscriberFields.map(field => mapFields(
    field,
    { id: '', edit: '' }
  ))
}

export const parseFinancialFields = (isAdmin: boolean): TableFieldDataType[] => {
  const excludedFields = isAdmin? [] : [
    TableField.buttonPaid
  ]
  return excludeTableFields(TableFinancialFields, excludedFields).map(field => mapFields(
    field,
    { id: ROUTES.financial.short, edit: ROUTES.financial.short, },
    {
      asLink: new Map([
        [ TableField.channelName, { field: TableField.channelId, base: ROUTES.channels.short } ],
        [ TableField.tipsterName, { field: TableField.tipsterId, base: ROUTES.tipsters.short } ],
        [ TableField.users, {
          base: ROUTES.usersWithSubs.full,
          params: [
            { name: SEARCH_PARAMS.FINANCIAL_TIPSTER, field: TableField.tipsterId },
            { name: SEARCH_PARAMS.FINANCIAL_CHANNEL, field: TableField.channelId },
          ],
        } ],
      ])
    }
  ))
}
export const parseNewFinancialFields = (isAdmin: boolean): TableFieldDataType[] => {
  const excludedFields =  [
    TableField.buttonPaid
  ]
  return excludeTableFields(TableNewFinancialFields, excludedFields).map(field => mapFields(
    field,
    { id: ROUTES.financial.short, edit: ROUTES.financial.short, },
  ))
}
