import { FC, useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import {
  GetAnalyticReportsDocument,
  AnalyticsReportInput,
  ChannelStatus,
} from '@app/graphql'
import { PageLayout, TableTemplate } from '@app/components'
import { TableHelper, CalendarHelper } from '@app/helpers'
import { TableAnalyticsKeyField } from '@app/constants'
import { TableFilterData, TableFilterFlags } from '@app/types'
import { useGetCountryById } from '@app/hooks'

interface AnalyticReportListPageProps {}

const AnalyticReportListPage: FC<AnalyticReportListPageProps> = () => {
  const [ filterOptions, setFilterOptions ] = useState<TableFilterData>({})
  // const [ sortOptions, setSortOptions ] = useState<SortKeys>({})
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [
    getAnalytics,
    { data: analyticsData, loading: analyticsLoading },
  ] = useLazyQuery(GetAnalyticReportsDocument, { fetchPolicy: 'network-only' })
  const { loading: countriesLoading, getCountryById } = useGetCountryById()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])

  useEffect(() => {
    getAnalytics({
      variables: {
        filter: prepareFilter(filterOptions),
        ...getPagination(),
      },
    })
  }, [ filterOptions, pageNumber, rowsPerPage ])

  const onFilter = (filter: TableFilterData = {}) => setFilterOptions(filter)
  const onPaginate = (page: number, rowsPage: number) => {
    setPageNumber(page)
    setRowsPerPage(rowsPage)
  }

  const getPagination = () => {
    const row = localStorage.getItem('per_page')
    const letLimit = Number(row) ?? rowsPerPage
    return { limit: letLimit, skip: pageNumber * rowsPerPage }
  }

  const prepareFilter = (filter: TableFilterData): AnalyticsReportInput => ({
    channelStatus: filter?.status as ChannelStatus,
    countryId: filter?.countryId,
    dates: filter?.dates?.dateFrom || filter?.dates?.dateTo ?
      {
        startDate: filter?.dates?.dateFrom ?
          CalendarHelper.getDayAsString(filter.dates.dateFrom) :
          null,
        endDate: filter?.dates?.dateTo ?
          CalendarHelper.getDayAsString(filter.dates.dateTo) :
          null,
      } :
      null
  })

  const columns = useMemo(() => TableHelper.parseAnalyticsFields(), [])
  const rows = useMemo(() => {
    if (!analyticsData?.analyticsReport?.analyticsReports) return []
    return analyticsData.analyticsReport.analyticsReports.map(report => ({
      ...report,
      country: getCountryById(report.countryId),
    }))
  }, [ analyticsData ])

  const filterFlags: TableFilterFlags = {
    status: true,
    country: true,
    dateRange: true,
  }

  return (
    <PageLayout
      title={t('analytics', { ns: 'pageTitles' })}
      loading={analyticsLoading || countriesLoading}
    >
      <TableTemplate
        columns={columns}
        rows={rows}
        fieldKey={TableAnalyticsKeyField}
        title={t('analyticsAndReports')}
        createText={null}
        createRoute={null}
        sortOptions={{}}
        sortingFields={{}}
        filterFlags={filterFlags}
        filterOptions={filterOptions}
        statuses={Object.values(ChannelStatus)}
        totalAmount={analyticsData?.analyticsReport?.count}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        preventDoubleClick
        onFilter={onFilter}
        onPaginate={onPaginate}
      />
    </PageLayout>
  )
}

export default AnalyticReportListPage
