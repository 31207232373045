import { FC } from 'react'
import ReactQuill from 'react-quill'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Box, SxProps, Typography, styled } from '@mui/material'
import 'react-quill/dist/quill.snow.css'

interface FormikTextEditorProps {
  fieldName: string
  formik: ReturnType<typeof useFormik<any>>
  label: string
  sxProps?: SxProps
  required?: boolean
}

const FormikTextEditor: FC<FormikTextEditorProps> = ({
  fieldName,
  formik,
  label,
  sxProps = {},
  required,
}) => {
  const { t } = useTranslation([ 'translation', 'forms' ])
  const onChange =  (v :string) => {
    formik.setFieldValue(fieldName, v, true)
    formik.setFieldTouched(fieldName, true, true)
  }
  const hasError = Boolean(formik.touched[fieldName] && formik.errors[fieldName])

  return (
    <StyledWrapper sx={sxProps} mb={2}>
      <Typography component="label" className={hasError ? 'error-text-color' : ''}>
        {t(label)}{required ? ' *' : ''}
      </Typography>
      <ReactQuill
        className={hasError ? 'error-border' : ''}
        theme="snow"
        value={formik.values[fieldName]}
        onChange={onChange}
      />
      {
        hasError ?
          <Typography className="error-message error-text-color">
            {t(formik.errors[fieldName] as string)}
          </Typography> :
          null
      }
    </StyledWrapper>
  )
}

const StyledWrapper = styled(Box)`
  width: 100%;

  .error-message {
    font-size: .8rem;
  }
  .error-text-color {
    color: var(--color-warning);
  }
  .error-border {
    border: 1px solid var(--color-warning);
  }
`

export default FormikTextEditor
