import { FC, useState } from 'react'
import { useFormik } from 'formik'
import { Box, Button, FormControlLabel, Stack, styled, Switch } from '@mui/material'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { FormFieldDataType, FormFieldType } from '@app/types'
import {
  CountrySelect,
  DialogResult,
  FormikCheckbox,
  FormikSelect,
  FormikTextEditor,
  FormikTextField,
  UploadButton,
  UserSelect,
  TargetFields,
} from '@app/ui'
import { useURLFromFile } from '@app/hooks'
import { Datepicker } from './Datepicker'

interface FormElementCreationProps {
  fields: FormFieldDataType[]
  state?: {
    active?: boolean
    muted?: boolean
    banned?: boolean
  }
  submitDisabled?: boolean
  onCancel?: () => void
  onActivate?: (isActive: boolean) => void
  onMute?: (isMuted: boolean) => void
  onBan?: (isBanned: boolean) => void
  validate?: (values: any) => object
  onSubmit: (values) => void
  onSaveText?: string
}

const FormElementCreation: FC<FormElementCreationProps> = ({
  fields,
  state = {},
  submitDisabled,
  onCancel,
  onActivate,
  onMute,
  onBan,
  validate,
  onSubmit,
  onSaveText,
}) => {
  const [ openFormError, setOpenFormError ] = useState(false)
  const { t } = useTranslation([ 'translation' ])

  const handleSubmit = (values) => {
    if (formik.isValid && !submitDisabled) {
      onSubmit(values)
    }
  }
  const onSave = () => {
    if (submitDisabled) return
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        setOpenFormError(true)
        const fieldsTouch = fields.reduce((result, current) => {
          result[current.name] = true
          return result
        }, {})
        formik.setTouched(fieldsTouch)
      }
    })
  }
  const onCloseFormError = () => setOpenFormError(false)

  const formik = useFormik(getFormikObject(fields, handleSubmit, validate))

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {
          fields.map(f => getFormField(formik, f))
        }
        {
          onCancel ?
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              color="secondary"
              onClick={onCancel}
            >
              {t('buttonCancel')}
            </Button> :
            null
        }
        {
          onActivate ?
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              color={state.active ? 'error' : 'success'}
              onClick={() => onActivate(state.active)}
            >
              {state.active ? t('buttonDeactivate') : t('buttonActivate')}
            </Button> :
            null
        }
        {
          onBan ?
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              color={state.banned ? 'success' : 'error'}
              onClick={() => onBan(state.banned)}
            >
              {state.banned ? t('buttonUnban') : t('buttonBan')}
            </Button> :
            null
        }
        {
          onMute ?
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              color={state.muted ? 'success' : 'warning'}
              onClick={() => onMute(state.muted)}
            >
              {state.muted ? t('buttonUnmute') : t('buttonMute')}
            </Button> :
            null
        }
        <Button
          variant="contained"
          type="submit"
          disabled={submitDisabled}
          onClick={onSave}
        >
          {t(onSaveText || 'buttonSave')}
        </Button>
      </form>

      {/* DIALOGS */}
      <DialogResult
        open={openFormError}
        type="error"
        message={t('fillOutFields')}
        onClose={onCloseFormError}
      />
    </>
  )
}

const getFormField = (formik: ReturnType<typeof useFormik<any>>, field: FormFieldDataType) => {
  const sxProps = field.sxProps ? { mb: 2, ...field.sxProps } : { mb: 2 }

  if (field.dependent && Boolean(formik.values[field.dependent.field]) !== field.dependent.value) return null

  switch (field.type) {
    case FormFieldType.text:
    case FormFieldType.number:
      return (
        <FormikTextField
          key={field.name}
          fieldName={field.name}
          formik={formik}
          label={field.label}
          sxProps={sxProps}
          type={field.type}
          required={field.required}
          multiline={field.multiline}
        />
      )
    case FormFieldType.select:
      return (
        <FormikSelect
          key={field.name}
          fieldName={field.name}
          formik={formik}
          label={field.label}
          sxProps={sxProps}
          elements={field.elements || []}
          required={field.required}
          multiple={field.multiple}
        />
      )
    case FormFieldType.image: {
      const { imageSrc } = useURLFromFile(formik.values[field.name])
      const onUpload = (file: File) => formik.setFieldValue(field.name, file)

      return (
        <Box
          key={field.name}
          sx={sxProps}
          className="vb-flex-row-fs-c"
        >
          <Box sx={{ mr: 3 }}>
            <UploadButton
              label={field.label + (field.required ? ' *' : '')}
              onUpload={onUpload}
            />
          </Box>
          {
            imageSrc || field.image.link ?
              <img
                src={imageSrc || field.image?.link}
                className={field.image?.circle ? 'vb-circle' : ''}
                alt={field.image?.alt}
                width={field.image?.width}
                height={field.image?.height}
              /> :
              (
                field.required && formik.touched[field.name] ?
                  <StyledImageError
                    className="vb-flex-row-fs-fs"
                    $width={field.image?.width}
                    $height={field.image?.height}
                  >
                    <p className="vb-font small warning">Required</p>
                  </StyledImageError> :
                  null
              )
          }
        </Box>
      )
    }
    case FormFieldType.file: {
      const [fileName, setFileName] = useState('')
      const onUpload = (file: File) => {
        setFileName(file.name)
        if(field?.file?.readText){
          const reader = new FileReader()
          reader.onload = (reader) => {
            formik.setFieldValue(field.name, reader.target.result as string)
          }
          reader.readAsText(file)
        } else {
          formik.setFieldValue(field.name, file)
        }
      }
      return (
        <Box
          key={field.name}
          sx={sxProps}
          className="vb-flex-row-fs-c"
        >
          <Box sx={{ mr: 3 }}>
            <UploadButton
              label={`${field.label} ${fileName? ': ' + fileName: ''} ${field.required? ' *' : ''}`}
              onUpload={onUpload}
            />
          </Box>
        </Box>
      )
    }
    case FormFieldType.datepicker: {
      const onSelect = (date: Date) => formik.setFieldValue(field.name, date, true)

      return (
        <Box key={field.name} sx={sxProps}>
          <Datepicker
            name={field.name}
            label={field.label + (field.required ? '*' : '')}
            value={formik.values[field.name]}
            onSelect={onSelect}
          />
        </Box>
      )
    }
    case FormFieldType.checkbox:
      return (
        <FormikCheckbox
          key={field.name}
          fieldName={field.name}
          formik={formik}
          label={field.label}
          sxProps={sxProps}
        />
      )
    case FormFieldType.country:
      return (
        <CountrySelect
          key={field.name}
          sxProps={sxProps}
          formik={formik}
          fieldName={field.name}
          required={field.required}
        />
      )
    case FormFieldType.user:
      return (
        <UserSelect
          key={field.name}
          sxProps={sxProps}
          formik={formik}
          fieldName={field.name}
          required={field.required}
        />
      )
    case FormFieldType.advertTarget:{
      delete field?.initValue.__typename
      const channelFieldName = `${field.name}-channel`
      const countryFieldName = `${field.name}-country`
      const initValue = field?.initValue.map((target, index) => ({
        channelFieldName: `${channelFieldName}-${index}`,
        countryFieldName: `${countryFieldName}-${index}`,
        initialChannelValue: target?.channelId,
        initialCountryValues: target?.countryIds
      }))
      const [ targetComponents, setTargetComponents ] = useState(initValue)

      const onAddTargets = () => {
        const size = targetComponents.length
        setTargetComponents([ ...targetComponents, {
          channelFieldName: `${channelFieldName}-${size}`,
          countryFieldName: `${countryFieldName}-${size}`
        } ])
      }

      const onRemoveTargets = (targetCounter) => {
        const updatedValues = [ ...targetComponents ]
        updatedValues[targetCounter] = undefined
        setTargetComponents(updatedValues)
        formik.values[FormFieldType.advertTarget][targetCounter] =  undefined
      }

      return (<>
        {targetComponents.map((target) => {

          if(!target) return null
          const targetCounter = parseInt(target.channelFieldName.split('-')[2])
          return <Stack
            direction="row"
            spacing={3}
            key={`${target.channelFieldName}-${target.countryFieldName}`}
          >
            <TargetFields
              channelFieldName={target.channelFieldName}
              sxProps={sxProps}
              formik={formik}
              required={field.required}
              countryFieldName={target.countryFieldName}
              targetCounter={targetCounter}
              initialChannelValue={target?.initialChannelValue ?? ''}
              initialCountryValues={target?.initialCountryValues ?? []}
            />
            <Button
              variant="text"
              type="submit"
              onClick={() => onRemoveTargets(targetCounter)}
            > Remove Target </Button>
          </Stack>
        })}
        <Button
          variant="outlined"
          type="submit"
          onClick={onAddTargets}
          fullWidth
        > Add Target </Button>
      </>
      )
    }
    case FormFieldType.switch: {
      const onChange = () => formik.setFieldValue(field.name, !formik.values[field.name])

      return (
        <FormControlLabel
          key={field.name}
          control={
            <Switch
              checked={formik.values[field.name]}
              onChange={onChange}
            />
          }
          label={field.label}
        />
      )
    }
    case FormFieldType.textEditor: {
      return (
        <FormikTextEditor
          key={field.name}
          fieldName={field.name}
          formik={formik}
          label={field.label}
          sxProps={field.sxProps}
          required={field.required}
        />
      )
    }
  }
}

const getFormikObject = (fields: FormFieldDataType[], onSubmit, validate?: (values: any) => object) => {
  const initialValues = {}
  const validationSchema = {}

  fields.forEach(f => {
    initialValues[f.name] = f.initValue
    validationSchema[f.name] = f.validation
  })

  return {
    initialValues,
    validationSchema: validate ? undefined : yup.object().shape(validationSchema),
    validate,
    onSubmit: values => onSubmit(values),
    enableReinitialize: true
  }
}

const StyledImageError = styled(Box)<{ $width: number, $height: number }>`
  width: ${p => p.$width}px;
  height: ${p => p.$height}px;
  border: 1px solid var(--color-warning);
  border-radius: 1rem;
  padding: .5rem;
`

export default FormElementCreation
