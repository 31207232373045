import { ChangeEvent, FC } from 'react'
import { Button } from '@mui/material'

interface UploadButtonProps {
  label: string
  onUpload: (file: File) => void
}

const UploadButton: FC<UploadButtonProps> = ({
  label,
  onUpload,
}) => {
  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) =>
    onUpload(e?.target?.validity?.valid && e?.target?.files?.[0] || null)

  return (
    <Button variant="contained" component="label">
      {label}
      <input hidden type="file" onChange={handleFileUpload}/>
    </Button>
  )
}

export default UploadButton
