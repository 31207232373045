import { FC, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Button, InputAdornment, IconButton, Link as MatLink } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { ROUTES } from '@app/constants'
import { FormHelper } from '@app/helpers'

const emailField = 'email'
const passwordField = 'password'

interface LoginFormProps {
  loginError?: string
  onSubmit: (email: string, password: string) => void
}

const LoginForm: FC<LoginFormProps> = ({ loginError, onSubmit }) => {
  const [ showPassword, setShowPassword ] = useState(false)
  const { t } = useTranslation([ 'translation', 'forms' ])
  const formik = useFormik({
    initialValues: {
      [emailField]: '',
      [passwordField]: '',
    },
    validationSchema: yup.object().shape({
      [emailField]: FormHelper.getEmailSchemaField(),
      [passwordField]: FormHelper.getPasswordSchemaField(),
    }),
    onSubmit: values => onSubmit(values[emailField], values[passwordField]),
  })

  const onShowPassword = () => setShowPassword(prevState => !prevState)
  const onMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => e.preventDefault()

  return (
    <StyledForm className="form" onSubmit={formik.handleSubmit}>
      <TextField
        className="text-field"
        fullWidth
        id={emailField}
        name={emailField}
        label={t('emailLabel', { ns: 'forms' })}
        value={formik.values[emailField]}
        error={formik.touched[emailField] && Boolean(formik.errors[emailField])}
        helperText={formik.touched[emailField] && t(formik.errors[emailField])}
        type="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <TextField
        className="text-field"
        fullWidth
        id={passwordField}
        name={passwordField}
        label={t('passwordLabel', { ns: 'forms' })}
        value={formik.values[passwordField]}
        error={formik.touched[passwordField] && Boolean(formik.errors[passwordField])}
        helperText={formik.touched[passwordField] && t(formik.errors[passwordField])}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={onShowPassword}
                onMouseDown={onMouseDownPassword}
              >
                {showPassword ? <VisibilityOff/> : <Visibility/>}
              </IconButton>
            </InputAdornment>
          )
        }}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <div className="vb-flex-row-fe-c forgot-password">
        <Link to={ROUTES.recovery}>
          <MatLink component="button">{t('forgotPassword')} ?</MatLink>
        </Link>
      </div>
      <p className="vb-flex-row-c-c login-failed vb-font small">
        {loginError}
      </p>
      <div className="vb-flex-row-c-c">
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={!!formik.errors[emailField] || !!formik.errors[passwordField]}
        >
          {t('buttonLogin')}
        </Button>
      </div>
    </StyledForm>
  )
}

const StyledForm = styled.form`
  width: 40%;

  .text-field, .forgot-password, .login-failed {
    margin-bottom: 10px;
  }
  .login-failed {
    color: var(--color-mui-danger);
  }
`

export default LoginForm
