import { FC, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import * as yup from 'yup'

import {
  AffilateLinkCreate,
  CreateAffiliateLinkDocument,
  GetAffiliateLinkByIdDocument,
  GetChannelsWithMinDataDocument,
  GetCurrenciesDocument,
  GetUsersWithMinDataDocument, SortMethod,
  UpdateAffiliateLinkDocument,
  UserRoles,
} from '@app/graphql'
import { FormElementCreation, PageLayout } from '@app/components'
import { CreateAffiliateLinkValues, FormFieldDataType, FormFieldType, SelectElementType } from '@app/types'
import { DialogResult } from '@app/ui'
import { CalendarHelper, FormHelper } from '@app/helpers'
import { useCallbackPrompt } from '@app/hooks'

const internalNameField = 'internalName'
const tipsterField = 'tipsterId'
const channelField = 'channelId'
const dealField = 'deal'
const expirationDateField = 'expirationDate'
const payPerHeadField = 'payPerHead'
const pphCurrencyField = 'pphCurrency'
const useLimitField = 'useLimit'

interface AffiliateLinkCreatePageProps {
  editMode?: boolean
}

const AffiliateLinkCreatePage: FC<AffiliateLinkCreatePageProps> = ({ editMode }) => {
  const [ blockLeaving, setBlockLeaving ] = useState(true)
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ loading, setLoading ] = useState(true)
  const { affiliateLinkId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const { dialog } = useCallbackPrompt(blockLeaving)
  const [
    getLink,
    { data: linkData, loading: linkLoading, error: linkError }
  ] = useLazyQuery(GetAffiliateLinkByIdDocument, { fetchPolicy: 'network-only' })
  const [
    getTipsters,
    { data: tipstersData, loading: tipstersLoading }
  ] = useLazyQuery(GetUsersWithMinDataDocument, { fetchPolicy: 'network-only' })
  const [
    getChannels,
    { data: channelsData, loading: channelsLoading },
  ] = useLazyQuery(GetChannelsWithMinDataDocument, { fetchPolicy: 'network-only' })
  const [ getCurrencies, { data: currenciesData, loading: currenciesLoading } ] = useLazyQuery(GetCurrenciesDocument)
  const [ createLink ] = useMutation(CreateAffiliateLinkDocument)
  const [ updateLink ] = useMutation(UpdateAffiliateLinkDocument)

  useEffect(() => {
    if (editMode) getLink({ variables: { id: affiliateLinkId } })
    getCurrencies({ variables: { limit: 1000 } })
    getTipsters({
      variables: {
        filter: { role: UserRoles.Tipster },
        skip: 0,
        limit: 10000,
        sort: { displayName: SortMethod.Asc }
      },
    })
    getChannels({
      variables: {
        filter: {},
        sort: { channelName: SortMethod.Asc },
      },
    })
    setLoading(false)
  }, [])

  const onSubmit = async (values: CreateAffiliateLinkValues) => {
    try {
      setLoading(true)
      setBlockLeaving(false)
      const v = {
        ...values,
        usersLimit: values.useLimit,
        expirationDate: values.expirationDate ?
          CalendarHelper.getDayAsString(values.expirationDate) :
          null,
      }
      delete v.useLimit

      const input: AffilateLinkCreate = { ...v }

      let ready: boolean
      if (editMode) {
        const result = await updateLink({ variables: { id: affiliateLinkId, input } })
        ready = result.data.affilateLinkUpdate.result
      } else {
        const result = await createLink({ variables: { input } })
        ready = Boolean(result.data.affilateLinkCreate.id)
      }

      if (ready) {
        setSuccessMessage(editMode ? t('updatingSuccess') : t('creationSuccess'))
      } else {
        throw new Error()
      }
    } catch (e) {
      setBlockLeaving(true)
      setErrorMessage(editMode ? t('updatingFailure') : t('creationFailure'))
    } finally {
      setLoading(false)
    }
  }
  const onCancel = () => navigate(-1)
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(-1)
    }
  }

  const title = editMode ? t('editAffiliateLink') : t('createAffiliateLink')
  const currencyElements: SelectElementType[] = useMemo(() => {
    if (!currenciesData?.currencies?.edges?.length) return []

    return currenciesData.currencies.edges.map(({ node }) => ({
      value: node.code,
      label: node.name,
    }))
  }, [ currenciesData ])
  const fields: FormFieldDataType[] = [
    {
      name: internalNameField,
      label: t('internalName'),
      type: FormFieldType.text,
      initValue: linkData?.affilateLink?.internalName || '',
      validation: FormHelper.getRequiredStringSchema()
        .min(3, t('min3LengthError', { ns: 'forms' }))
        .max(40, t('max40LengthError', { ns: 'forms' })),
      required: true,
    },
    {
      name: expirationDateField,
      label: t('expirationDate'),
      type: FormFieldType.datepicker,
      initValue: new Date(linkData?.affilateLink?.expirationDate || new Date()),
      validation: yup.date().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: tipsterField,
      label: t('tipster'),
      type: FormFieldType.select,
      elements: tipstersData?.users?.users || [],
      initValue: linkData?.affilateLink?.tipsterId || '',
      validation: FormHelper.getRequiredStringSchema(),
      required: true,
    },
    {
      name: channelField,
      label: t('channel'),
      type: FormFieldType.select,
      elements: channelsData?.channels?.channels || [],
      initValue: linkData?.affilateLink?.channelId || '',
      validation: FormHelper.getRequiredStringSchema(),
      required: true,
    },
    {
      name: dealField,
      label: t('deal'),
      type: FormFieldType.number,
      initValue: linkData?.affilateLink?.deal || 0,
      validation: FormHelper.getRequiredNumberSchema()
        .min(0, t('minNumberZeroError', { ns: 'forms' }))
        .max(100, t('maxNumber100Error', { ns: 'forms' })),
      required: true,
    },
    {
      name: payPerHeadField,
      label: t('payPerHead'),
      type: FormFieldType.number,
      initValue: linkData?.affilateLink?.payPerHead || 0,
      validation: yup.number(),
    },
    {
      name: pphCurrencyField,
      label: t('currencies'),
      type: FormFieldType.select,
      elements: currencyElements,
      initValue: linkData?.affilateLink?.pphCurrency || '',
      validation: yup.string(),
    },
    {
      name: useLimitField,
      label: t('useLimit'),
      type: FormFieldType.number,
      initValue: linkData?.affilateLink?.usersLimit || 0,
      validation: yup.number().min(0, t('minNumberZeroError', { ns: 'forms' })),
    },
  ]

  if (editMode && (!affiliateLinkId || linkError)) navigate(-1)

  return (
    <PageLayout
      title={title}
      loading={(editMode && linkLoading) || loading || tipstersLoading || channelsLoading || currenciesLoading}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" component="h4" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ width: '700px' }}>
          <FormElementCreation
            fields={fields}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </Box>
      </Box>

      {/* DIALOG */}
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
      { dialog }
    </PageLayout>
  )
}

export default AffiliateLinkCreatePage
