import * as yup from 'yup'
import { UserRoles } from '@app/graphql'

import i18n from '@app/i18n'

export const getEmailSchemaField = (notRequired?: boolean) => notRequired ?
  yup.string().email(i18n.t('emailInvalidError', { ns: 'forms' }))
    .max(100, i18n.t('emailMaxError', { ns: 'forms' })) :
  yup.string().email(i18n.t('emailInvalidError', { ns: 'forms' }))
    .required(i18n.t('emailRequiredError', { ns: 'forms' }))
    .max(100, i18n.t('emailMaxError', { ns: 'forms' }))

export const getPasswordSchemaField = () => yup.string().required(i18n.t('passwordRequiredError', { ns: 'forms' }))
  .min(8, i18n.t('passwordMinError', { ns: 'forms' }))
  .max(40, i18n.t('passwordMaxError', { ns: 'forms' }))

export const getNewPasswordSchemaField = () => getPasswordSchemaField()
  .matches(/([a-zA-Z]+\d+)|(\d+[a-zA-Z]+)/, i18n.t('containsOneLetterAndOneNumberError', { ns: 'forms' }))

export const getRequiredStringSchema = () => yup.string().required(i18n.t('requiredError', { ns: 'forms' }))
export const getRequiredNumberSchema = () => yup.number().required(i18n.t('requiredError', { ns: 'forms' }))

export const userRoleList = [
  { value: UserRoles.User },
  { value: UserRoles.Tipster },
]
