import { useEffect, useMemo, useState } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import {
  ChannelRole,
  CountryModel,
  GetChannelNameDocument,
  GetChannelUsersDocument,
  GetChannelUsersWithChannelIdsDocument,
  GetChannelsDocument,
  GetTipsterChannelsDocument,
  GetUserSubscriptionsDocument,
  GetFreeTrialByConditionDocument,
  GetUsersByIdsDocument,
  GetUsersDocument,
  TipsterStatus,
  UserFilter,
  UserModel,
  UserRoles,
  UserSort,
  UserStatus,
} from '@app/graphql'
import { TableFilterData } from '@app/types'
import { SEARCH_PARAMS } from '@app/constants'
import { CurrentUserState } from '@app/storage'
import { useSnapshot } from 'valtio'

type userListType = Partial<UserModel & { countryData: Partial<CountryModel> }>[]
export const useLoadUsers = (options: { role: UserRoles }) => {
  const { isTipster } = useSnapshot(CurrentUserState)
  const [ loading, setLoading ] = useState(false)
  const [ channelNameLoading, setChannelNameLoading ] = useState(false)
  const [ usersData, setUsersData ] = useState<userListType>([])
  const [ allChannels, setAllChannels ] = useState<any>([])
  const [ filterOptions, setFilterOptions ] = useState<TableFilterData>({})
  const [ sortOptions, setSortOptions ] = useState<UserSort>(null)
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ totalCount, setTotalCount ] = useState(0)
  const [ fromChannelWithId, setFromChannelWithId ] = useState<string>(null)
  const [ channelName, setChannelName ] = useState('')

  const [ searchParams ] = useSearchParams()
  const location = useLocation()
  const { id: userId, channelsWhereUserIsTipster } = useSnapshot(CurrentUserState)
  /* QUERIES AND MUTATIONS */
  const [ getUsers ] = useLazyQuery(GetUsersDocument, { fetchPolicy: 'network-only' })
  const [ getChannelUsers ] = useLazyQuery(GetChannelUsersDocument, { fetchPolicy: 'network-only' })
  const [ getChannelUsersByChannelIds ] = useLazyQuery(GetChannelUsersWithChannelIdsDocument,
    { fetchPolicy: 'network-only' })
  const [ getUsersByIds ] = useLazyQuery(GetUsersByIdsDocument, { fetchPolicy: 'network-only' })
  const [ getChannelName ] = useLazyQuery(GetChannelNameDocument, { fetchPolicy: 'network-only' })
  const [ getChannels, { data: channelsData } ]
    = useLazyQuery(GetChannelsDocument, { fetchPolicy: 'no-cache' })

  const [ getTipsterChannels ] = useLazyQuery(GetTipsterChannelsDocument, { fetchPolicy: 'network-only' })
  const [
    getUserSubscriptions,{ data: userSubsData, loading: userSubsLoading }
  ] = useLazyQuery(GetUserSubscriptionsDocument, { fetchPolicy: 'network-only' })

  const [ freeTrialByCondition,{ data: payload } ] = useLazyQuery(GetFreeTrialByConditionDocument,
    { fetchPolicy: 'network-only' })

  useEffect(() => {
    if (!loading) {
      loadUsers()
    }
  }, [ location.search, fromChannelWithId ])

  useEffect(() => {
    if (fromChannelWithId) loadChannelName()
  }, [ fromChannelWithId ])

  useEffect(() => {
    const limit = localStorage.getItem('per_page')
    if (limit) {
      setRowsPerPage(Number(limit))
    }
  }, [])

  useEffect(() => {
    loadAllChannels()
  }, [])

  const loadAllChannels = async () => {
    if (isTipster) {
      const allChannels = await getTipsterChannels({ variables: { userId } })
      setAllChannels(allChannels?.data?.userChannels)

    } else {

      const allChannels = await getChannels({
        variables: {
          sort: {},
          filter: {},
          limit: 1000
        },
      })
      setAllChannels(allChannels?.data?.channels.channels)
    }
  }

  const loadUsersList = async (input: {
    filter: UserFilter,
    sort: UserSort,
    limit: number,
    skip: number,
  }) => {

    try {
      setLoading(true)
      if (isTipster) {
        const limit = localStorage.getItem('per_page')
        const user = parseInt(userId, Number(limit))
        input.filter = { ...input.filter, subscriptionToTipster: user, followedTipster: user }
      }

      const [ result, channelUsers, subscriptionUsers, freeTrialUser ] =
       await Promise.all([ getUsers({ variables: input }),
         loadFilteredChannelUsers(input?.filter?.channelIds) ,
         loadFilteredSubscriptionStatusUsers(input?.filter?.subscriptionStatus),
         loadFreeTrialByCondition(input?.filter?.freeTrial)
       ])

      channelUsers.length > 0 && setUsersData(channelUsers)

      if (channelUsers.length > 0) {
        setUsersData(channelUsers)
        setTotalCount(channelUsers.length)

      } else if(input.filter.subscriptionStatus){

        setUsersData(subscriptionUsers)
        setTotalCount(subscriptionUsers.length)
      } else if(input.filter.freeTrial){

        setUsersData(freeTrialUser)
        setTotalCount(freeTrialUser.length)
      }
      else {
        setUsersData(result?.data?.users?.users)
        setTotalCount(result?.data?.users?.count)

      }
      return result?.data

    } catch (e) {
      console.error('Load users list Error: ', e)
    } finally {
      setLoading(false)
    }
  }

  const loadChannelUsers = async (
    input: { limit: number, skip: number }, channelId?: string
  ): Promise<userListType> => {
    if (!fromChannelWithId && !channelId) return
    try {

      const resultChannelUsers = await getChannelUsers({
        variables: {
          ...input,
          channelId: fromChannelWithId || channelId,
          channelRole: getChannelRole(),
        },
      })

      const resultUsersData = await getUsersByIds({
        variables: {
          userIds: resultChannelUsers.data.channelUsers.channelUsers.map(c => c.userId),
        },
      })
      return resultUsersData?.data?.usersByIds
    } catch (e) {
      console.error('Load channel users list Error: ', e)
    }
  }

  const loadFilteredChannelUsers = async (channelId): Promise<userListType> => {
    try {

      const resultChannelsUsers = await getChannelUsersByChannelIds({
        variables: {
          channelIds: channelId || [],
          channelRole: getChannelRole(),
        },
      })

      const resultUsersData = await getUsersByIds({
        variables: {
          userIds: resultChannelsUsers.data.channelUsersWithChannelIds.channelUsers.map(c => c.userId),
        },
      })

      return resultUsersData?.data?.usersByIds
    } catch (e) {
      console.error('Load channel users list Error: ', e)
      return []
    }
  }
  const loadFilteredSubscriptionStatusUsers = async (status): Promise<userListType> => {
    try {

      const resultSuscriptionUsers = await getUserSubscriptions({
        variables: {
          filter: { status: status }
        },
      })

      const resultUsersData = await getUsersByIds({
        variables: {
          userIds: resultSuscriptionUsers.data.serviceSubscriptions.edges.map(c => c.node.userId),
        },
      })

      return resultUsersData?.data?.usersByIds
    } catch (e) {
      console.error('Load channel users list Error: ', e)
      return []
    }
  }
  const loadFreeTrialByCondition = async (status): Promise<userListType> => {
    try {

      const resultSuscriptionUsers = await freeTrialByCondition({
        variables: {
          status
        },
      })

      const resultUsersData = await getUsersByIds({
        variables: {
          userIds: resultSuscriptionUsers?.data?.freeTrialByCondition?.edges?.map(c => c.node.userId),
        },
      })
      return resultUsersData?.data?.usersByIds
    } catch (e) {
      console.error('Load users by free trial Error: ', e)
      return []
    }
  }



  const loadChannelName = async () => {
    try {
      setChannelNameLoading(true)
      const result = await getChannelName({ variables: { channelId: fromChannelWithId } })
      setChannelName(result.data.channel.channelName || '')
    } catch (e) {
      console.error('Load channel name Error: ', e)
    } finally {
      setChannelNameLoading(false)
    }
  }
  const loadUsers = async (pagination?: { limit: number, skip: number }) => {
    setLoading(true)
    let channelIds = []
    if (checkChannelIdParam()) {
      channelIds = [ checkChannelIdParam(), ...channelsWhereUserIsTipster.map(({ id }) => id) ].filter(id => id)
    }

    let userList: userListType = []

    if (channelIds.length) {
      const data = await Promise.all(
        channelIds.map(channelId => loadChannelUsers(pagination || getPagination(), channelId))
      )
      data.forEach(users => userList.push(...users))
    } else {

      const data = await loadUsersList({
        filter: prepareFilter(filterOptions),
        sort: sortOptions,
        ...(pagination || getPagination()),
      })
      userList = (data?.users?.users)
      setTotalCount(data?.users?.count)
    }

    setUsersData(userList)
    setLoading(false)
    return userList
  }
  const getPagination = () => {

    const row = localStorage.getItem('per_page')
    const letLimit = Number(row) ?? rowsPerPage
    return { limit: letLimit, skip: pageNumber * rowsPerPage }
  }
  const prepareFilter = (filter: TableFilterData): UserFilter => {
    const createdAt = filter.dates
      ? {
        startDate: filter.dates.dateFrom?.toISOString() || null,
        endDate: filter.dates.dateTo?.toISOString() || null,
      }
      : null

    const commonProperties: any = {
      createdAt,
      role: options.role,
    }

    if (filter.searchQuery && filter.searchQuery.length > 0) {
      commonProperties.searchQuery = filter.searchQuery
    }
    if (filter.channel && filter.channel.length > 0) {
      commonProperties.channelIds = filter.channel
    }
    if (filter.period && filter.period.length > 0) {
      commonProperties.period = filter.period
    }
    if (filter.subscriptionStatus && filter.subscriptionStatus.length > 0) {
      commonProperties.subscriptionStatus = filter.subscriptionStatus
    }
    if (filter.freeTrial && filter.freeTrial.length > 0) {
      commonProperties.freeTrial = filter.freeTrial
    }

    return options.role === UserRoles.User
      ? {
        ...commonProperties,
        country: filter.country || null,
        userStatus: filter.status as UserStatus || null,
      }
      : {
        ...commonProperties,
        tipsterStatus: filter.status as TipsterStatus || null,
      }
  }

  const getChannelRole = () => {
    switch (options.role) {
      case UserRoles.Admin:
        return ChannelRole.Admin
      case UserRoles.Tipster:
        return ChannelRole.Tipster
      default:
        return ChannelRole.User
    }
  }

  const onSort = fromChannelWithId ? null : (sort: UserSort = {}) => {
    loadUsersList({
      filter: prepareFilter(filterOptions),
      sort,
      ...getPagination(),
    })
    setSortOptions(sort)
  }

  const onFilter = fromChannelWithId ? null : async (filter: TableFilterData = {}) => {
    loadUsersList({
      filter: prepareFilter(filter),
      sort: sortOptions,
      ...getPagination(),
    })
    setFilterOptions(filter)
  }

  const onPaginate = (page: number, rowsPage: number) => {
    loadUsers({
      limit: rowsPage,
      skip: page * rowsPage,
    })
    setPageNumber(page)
    setRowsPerPage(rowsPage)
  }
  const checkChannelIdParam = () => {
    const channelId = searchParams.get(SEARCH_PARAMS.FROM_CHANNEL)
    setFromChannelWithId(channelId)
    return channelId
  }

  return {
    loading: loading || channelNameLoading,
    usersData,
    totalCount,
    pageNumber,
    rowsPerPage,
    filterOptions,
    sortOptions,
    fromChannelWithId,
    loadUsers,
    onSort,
    onFilter,
    onPaginate,
    allChannels
  }
}
