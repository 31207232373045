import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'

import { PageLayout, ViewPageTemplate } from '@app/components'
import { ROUTES, ChannelDataFields } from '@app/constants'
import { DeleteChannelDocument, GetChannelByIdDocument, GetCountryByIdDocument } from '@app/graphql'
import { DialogResult } from '@app/ui'

interface ChannelViewPageProps {}

const ChannelViewPage: FC<ChannelViewPageProps> = () => {
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ country, setCountry ] = useState('')
  const [ loading, setLoading ] = useState(true)
  const { channelId } = useParams()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const navigate = useNavigate()
  const [
    getChannel,
    { data: channelData, loading: channelLoading, error: channelError }
  ] = useLazyQuery(GetChannelByIdDocument)
  const [ getCountry ] = useLazyQuery(GetCountryByIdDocument)
  const [ deleteChannel ] = useMutation(DeleteChannelDocument)

  useEffect(() => {
    const load = async () => {
      try {
        const channelResult = await getChannel({ variables: { channelId }, fetchPolicy: 'network-only' })
        const { countryId } = channelResult.data.channel
        const countryResult = await getCountry({ variables: { id: countryId } })
        const { name } = countryResult.data.country
        setCountry(name)
      } finally {
        setLoading(false)
      }
    }
    load()
  }, [])

  const onDelete = async () => {
    try {
      setLoading(true)
      const result = await deleteChannel({ variables: { channelId } })
      if (result.data.channelDelete.deleted) {
        setSuccessMessage(t('deleteSuccess'))
      } else {
        throw new Error()
      }
    } catch (e) {
      setErrorMessage(t('deleteError'))
    } finally {
      setLoading(false)
    }
  }
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(ROUTES.channels.short)
    }
  }

  const data = channelData?.channel ? { ...channelData.channel, country } : null

  if (!channelId || channelError) return <Navigate to={ROUTES.channels.short} replace/>

  return (
    <PageLayout
      title={t('channelViewPage', { ns: 'pageTitles' })}
      loading={channelLoading || loading}
    >
      <ViewPageTemplate
        editRoute={ROUTES.channelEdit.full + '/' + channelId}
        channelData={data}
        dataFields={ChannelDataFields}
        onDelete={onDelete}
      />

      {/* DIALOG */}
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
    </PageLayout>
  )
}

export default ChannelViewPage
