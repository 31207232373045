import React from 'react'
import PropTypes from 'prop-types'

class TelegramLoginButton extends React.Component <any, any> {
  instance: any
  static propTypes: { botName: PropTypes.Validator<string>; dataOnauth: PropTypes.Requireable<(...args: any[]) => any>; buttonSize: PropTypes.Requireable<string>; cornerRadius: PropTypes.Requireable<number>; requestAccess: PropTypes.Requireable<string>; usePic: PropTypes.Requireable<boolean>; lang: PropTypes.Requireable<string>; widgetVersion: PropTypes.Requireable<number> }
  static defaultProps: { botName: string; dataOnauth: () => any; buttonSize: string; requestAccess: string; usePic: boolean; lang: string; widgetVersion: number; }
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const {
      botName,
      buttonSize,
      cornerRadius,
      requestAccess,
      usePic,
      dataOnauth,
      dataAuthUrl,
      lang,
      widgetVersion,
    } = this.props
    window['TelegramLoginWidget'] = {
      dataOnauth: (user) => dataOnauth(user),
    }

    const script = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-widget.js?' + widgetVersion
    // script.setAttribute('data-telegram-login', botName)
    // script.setAttribute('data-size', buttonSize)
    // if (cornerRadius !== undefined) {
    //   script.setAttribute('data-radius', cornerRadius)
    // }
    script.setAttribute('data-request-access', requestAccess)
    // script.setAttribute('data-userpic', 'false')
    // script.setAttribute('data-lang', lang)
    // if (dataAuthUrl !== undefined) {
    //   script.setAttribute('data-auth-url', dataAuthUrl)
    // } else {
    script.setAttribute(
      'data-onauth',
      'TelegramLoginWidget.dataOnauth(user)'
    )
    // }
    script.async = true
    this.instance.appendChild(script)
  }

  render() {
    return (
      <div
        className={this.props.className}
        ref={(component) => {
          this.instance = component
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

TelegramLoginButton.propTypes = {
  botName: PropTypes.string.isRequired,
  dataOnauth: PropTypes.func,
  buttonSize: PropTypes.oneOf([ 'large', 'medium', 'small' ]),
  cornerRadius: PropTypes.number,
  requestAccess: PropTypes.string,
  usePic: PropTypes.bool,
  lang: PropTypes.string,
  widgetVersion: PropTypes.number,
}

TelegramLoginButton.defaultProps = {
  botName: '',
  dataOnauth: () => undefined,
  buttonSize: 'large',
  requestAccess: 'write',
  usePic: true,
  lang: 'en',
  widgetVersion: 9,
}

export default TelegramLoginButton