import { GetUserPermissionsDocument, UserPermission } from '@app/graphql'
import { useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

/**
 * The hook allows to check user permissions
 * @param userId
 */
export const useGetPermissions = ({ userId }: { userId: string }) => {
  const [ loading, setLoading ] = useState(false)
  const [ userPermissions, setUserPermissions ] = useState<Record<UserPermission, boolean>>(null)
  const [ getUserPermissions ] = useLazyQuery(GetUserPermissionsDocument, { fetchPolicy: 'network-only' })

  useEffect(() => {
    const initPerms = Object.keys(UserPermission).reduce((result, current) => ({
      ...result,
      [current]: false,
    }), {})
    setUserPermissions(initPerms as Record<UserPermission, boolean>)

    const load = async () => {
      try {
        setLoading(true)
        const response = await getUserPermissions({
          variables: {
            userId
          },
        })
        const { permissions } = response.data.user
        const newPerms = { ...userPermissions }
        permissions.forEach(p => newPerms[p] = true)
        setUserPermissions(newPerms)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    if (userId) load()
  }, [ userId ])

  const checkPermission = (perm: UserPermission) => {
    return userPermissions?.[perm]
  }

  return { checkPermission, loading }
}
