import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'

import { PageLayout, ViewPageTemplate } from '@app/components'
import {
  DeleteUserDocument,
  GetTipsterChannelsDocument,
  GetUserDocument
} from '@app/graphql'
import { ROUTES, TipsterDataFields } from '@app/constants'
import { TableField } from '@app/types'

interface TipsterViewPageProps {}

const TipsterViewPage: FC<TipsterViewPageProps> = () => {
  const { tipsterId } = useParams()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const [
    getTipster,
    { data: tipsterData, loading: tipsterLoading, error: tipsterError }
  ] = useLazyQuery(GetUserDocument, { fetchPolicy: 'network-only' })
  const [
    getTipsterChannels,
    { data: channelsData, loading: channelsLoading }
  ] = useLazyQuery(GetTipsterChannelsDocument, { fetchPolicy: 'network-only' })
  const [ deleteTipster ] = useMutation(DeleteUserDocument)

  useEffect(() => {
    getTipster({ variables: { id: tipsterId } })
    getTipsterChannels({ variables: { userId: tipsterId } })
  }, [])

  const onDelete = async () => {
    const result = await deleteTipster({ variables: { id: tipsterId } })
    return Boolean(result.data.userDelete)
  }

  const tipster = tipsterData?.user ? {
    ...tipsterData.user,
    country: tipsterData.user.countryData?.name,
    [TableField.tipsterChannels]: channelsData?.userChannels?.map(ch => ch.channelName)
  } : null

  if (!tipsterId || tipsterError) return <Navigate to={ROUTES.tipsters.short} replace/>

  return (
    <PageLayout
      title={t('tipsterViewPage', { ns: 'pageTitles' })}
      loading={tipsterLoading || channelsLoading}
    >
      <ViewPageTemplate
        editRoute={ROUTES.tipsterEdit.full + '/' + tipsterId}
        userData={tipster}
        dataFields={TipsterDataFields}
        routeAfterDeletion={ROUTES.tipsters.short}
        onDeletePromise={onDelete}
      />
    </PageLayout>
  )
}

export default TipsterViewPage
