import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'

import { PageLayout, TableTemplate } from '@app/components'
import { GetUsersDocument, UserRoles, UserSort } from '@app/graphql'
import { ROUTES, TableSuperadminKeyField } from '@app/constants'
import { TableHelper } from '@app/helpers'

interface SuperadminListPageProps {}

const SuperadminListPage: FC<SuperadminListPageProps> = () => {
  const [ sortOptions, setSortOptions ] = useState<UserSort>(null)
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [
    getUsers,
    { data: usersData, loading: usersLoading }
  ] = useLazyQuery(GetUsersDocument, { fetchPolicy: 'network-only' })
  const { t } = useTranslation([ 'translation', 'pageTitles' ])

  useEffect(() => {
    getUsers({
      variables: {
        sort: sortOptions,
        filter: { role: UserRoles.Admin },
        ...getPagination(),
      }
    })
  }, [])

  const onSort = (sort: UserSort = {}) => {
    getUsers({
      variables: {
        filter: { role: UserRoles.Admin },
        sort,
        ...getPagination(),
      }
    })
    setSortOptions(sort)
  }
  const onPaginate = (page: number, rowsPage: number) => {
    getUsers({
      variables: {
        filter: { role: UserRoles.Admin },
        sort: sortOptions,
        limit: rowsPage,
        skip: page * rowsPage,
      },
    })
    setPageNumber(page)
    setRowsPerPage(rowsPage)
  }

  const getPagination = () => {
    const row = localStorage.getItem('per_page')
    const letLimit = Number(row) ?? rowsPerPage
    return { limit: letLimit, skip: pageNumber * rowsPerPage }
  }
  const columns = TableHelper.parseSuperadminFields()
  const rows = usersData?.users?.users ? [ ...usersData.users.users ] : []

  return (
    <PageLayout title={t('superadminList', { ns: 'pageTitles' })} loading={usersLoading}>
      <TableTemplate
        columns={columns}
        rows={rows}
        fieldKey={TableSuperadminKeyField}
        title={t('superadminList')}
        createText={null}
        createRoute={ROUTES.superadminCreate.full}
        sortOptions={sortOptions}
        totalAmount={usersData?.users?.count}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        onSort={onSort}
        onPaginate={onPaginate}
      />
    </PageLayout>
  )
}

export default SuperadminListPage
