import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  memo,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { Datepicker, SearchField } from '@app/components'
import {
  TableFilterFlags,
  TableFilterData,
  DualSelectOption,
  TableCustomFilterType,
  PeriodSelectOption,
  SubscriptionStatusSelectOption,
  FreeTrialSelectOption,
  SubscriptionTypeOption,
  PaymentTypeOption,
} from '@app/types'
import { CountrySelect } from '@app/ui'
import { ALL_VALUES, misc } from '@app/constants'
import { useSnapshot } from 'valtio'
import { CurrentUserState } from '@app/storage'
import CustomSelect from '@app/ui/ChannelSelectWithCheckBox'
import { CurrencyNode, ServiceSubscriptionStatus } from '@app/graphql'
import CurrencySelect from '@app/ui/CurrencySelect'

interface TableFiltersProps {
  flags: TableFilterFlags;
  filterOptions: TableFilterData;
  statuses?: string[];
  customFilters?: TableCustomFilterType[];
  onFilter: (filter: TableFilterData) => void;
  childRef: any;
  title?: string;
  resetText: string;
  allChannels?: string[];
  currencies?: CurrencyNode[];
  subscriptionStatus?: string;
  paymentOptions?: any []
}

const TableFilters: FC<TableFiltersProps> = ({
  flags,
  filterOptions,
  statuses,
  customFilters,
  onFilter,
  childRef,
  title,
  resetText,
  allChannels,
  currencies,
  subscriptionStatus,
  paymentOptions
}) => {
  const { t } = useTranslation([ 'translation', 'forms' ])
  const { isTipster } = useSnapshot(CurrentUserState)

  useImperativeHandle(childRef, () => ({
    clearFilter() {
      onFilter({})
    },
  }))

  const [ search, setSearch ] = useState<string>('')
  const onChangeStatus = (e: SelectChangeEvent) => {
    const status = e?.target?.value === ALL_VALUES ? null : e.target.value
    onFilter({ ...filterOptions, status })
  }
  const onSelectDateFrom = (d: Date) =>
    onFilter({
      ...filterOptions,
      dates: {
        dateFrom: d,
        dateTo: filterOptions.dates?.dateTo,
      },
    })
  const onSelectDateTo = (d: Date) =>
    onFilter({
      ...filterOptions,
      dates: {
        dateFrom: filterOptions.dates?.dateFrom,
        dateTo: d,
      },
    })

  const onSelectDateRangeFrom = (d: Date) =>
    onFilter({
      ...filterOptions,
      createdAt: {
        startDate: d,
        endDate: filterOptions.createdAt?.endDate,
      },
    })

  const onSelectDateRangeTo = (d: Date) =>
    onFilter({
      ...filterOptions,
      createdAt: {
        endDate: d,
        startDate: filterOptions.createdAt?.startDate,
      },
    })

  const onSelectDateStart = (d: Date) =>
    onFilter({
      ...filterOptions,
      dateStart: d,
    })

  const onChangeCountry = (e: SelectChangeEvent) => {
    const country = e?.target?.value === ALL_VALUES ? null : e?.target?.value
    onFilter({ ...filterOptions, country })
  }

  const onChangeChannel = (e: SelectChangeEvent) => {
    const channel = e?.target?.value === ALL_VALUES ? null : e?.target?.value
    onFilter({ ...filterOptions, channel })
  }
  const onChangeCurrency = (e: SelectChangeEvent) => {
    console.log('?????: ',e.target.value)
    const currency = e?.target?.value === ALL_VALUES ? null : e?.target?.value
    console.log('===curr===', currency)
    onFilter({ ...filterOptions, currency })
  }
  const onChangeSettled = (e: SelectChangeEvent) => {
    const settled = e?.target?.value === ALL_VALUES ? null : e?.target?.value
    onFilter({ ...filterOptions, settled })
  }

  const onChangeAnonymity = (e: SelectChangeEvent) => {
    const anonymity =
      e?.target?.value === ALL_VALUES
        ? null
        : (e?.target?.value as DualSelectOption)
    onFilter({ ...filterOptions, anonymity })
  }

  const onChangePeriod = (e: SelectChangeEvent) => {
    const period =
      e?.target?.value === ALL_VALUES
        ? null
        : (e?.target?.value as DualSelectOption)
    onFilter({ ...filterOptions, period })
  }

  const onChangeSubscriptionStatus = (e: SelectChangeEvent) => {
    const subscriptionStatus =
      e?.target?.value === ALL_VALUES
        ? null
        : (e?.target?.value as ServiceSubscriptionStatus)
    onFilter({ ...filterOptions, subscriptionStatus })
  }

  const onChangePaymentType = (e: SelectChangeEvent) => {
    const paymentType =
      e?.target?.value === ALL_VALUES
        ? null
        : (e?.target?.value as PaymentTypeOption)
    onFilter({ ...filterOptions, paymentType })
  }

  const onChangePaymentMethod = (e: SelectChangeEvent) => {
    const paymentMethod =
      e?.target?.value === ALL_VALUES
        ? null
        : (e?.target?.value)
    console.log('>>>KKK: ', e.target.value)
    onFilter({ ...filterOptions, paymentMethod })
  }

  const onChangeSubscriptionType = (e: SelectChangeEvent) => {
    const subscriptionType =
      e?.target?.value === ALL_VALUES
        ? null
        : (e?.target?.value as SubscriptionTypeOption)
    onFilter({ ...filterOptions, subscriptionType })
  }
  const onChangeFreeTrial = (e: SelectChangeEvent) => {
    const freeTrial =
      e?.target?.value === ALL_VALUES
        ? null
        : (e?.target?.value as ServiceSubscriptionStatus)
    onFilter({ ...filterOptions, freeTrial })
  }

  const onChangeCustom = (field: string) => (e: SelectChangeEvent) => {
    const value =
      !e?.target?.value || e?.target?.value === ALL_VALUES
        ? null
        : e.target.value
    onFilter({ ...filterOptions, [field]: value })
  }

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e?.target?.value)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === misc.ENTER) {
      if (title === misc.USER_LIST || title == misc.TIPSTER_LIST) {
        const searchQuery = search === '' ? null : search
        onFilter({ ...filterOptions, searchQuery })
      }
      if (title === misc.CHANNEL_LIST) {
        const channelName = search === '' ? null : search
        onFilter({ ...filterOptions, channelName })
      }
      if (title === misc.FINANCIAL) {
        const searchQuery = search === '' ? null : search
        onFilter({ ...filterOptions, searchQuery })
      }
    }
  }
  const onResetFilters = () => onFilter({})

  return (
    <Grid container spacing={2} sx={{ p: 4 }}>
      {/* {isTipster ? ( */}
      <>
        {flags.withCustom && customFilters.map((cf) => (
          <Grid key={cf.field} item xs={3}>
            <FormControl fullWidth>
              <InputLabel id={cf.field}>{t(cf.label)}</InputLabel>
              <Select
                labelId={cf.field}
                label={t(cf.label)}
                value={(filterOptions[cf.field] as string) || ALL_VALUES}
                onChange={onChangeCustom(cf.field)}
              >
                {cf.withAll && (
                  <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
                )}
                {cf.elements?.map((el) => (
                  <MenuItem key={el.value} value={el.value}>
                    {el.label ?? el.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ))
        }
        {/* {flags.withCustom && customFilters ? (
          <Grid className="vb-flex-row-c-c" item xs={2.2}>
            <Button
              sx={{ color: 'black' }}
              variant="contained"
              fullWidth
              onClick={onResetFilters}
            >
              {t('buttonReset')}
              <Box className="vb-flex-row-c-c" sx={{ ml: 1, color: 'black' }}>
                <CloseIcon />
              </Box>
            </Button>
          </Grid>
        ) : null} */}
      </>
      <Grid container spacing={2}>
        {flags.channel && (

          <Grid item xs={2.2}>
            <CustomSelect
              multiple
              value={filterOptions?.channel || []}
              withAll
              onChange={onChangeChannel}
              list={allChannels}
              labelId={'channel-filter'}
              labelTitle={t('channelLabel')}
            />
          </Grid>

        )}

        {flags.period && (

          <Grid item xs={2.2}>
            <FormControl fullWidth>
              <InputLabel id="period-filter">{t('periodLabel')}</InputLabel>
              <Select
                labelId="period-filter"
                label={t('periodLabel')}
                value={filterOptions?.period || ALL_VALUES}
                onChange={onChangePeriod}
              >
                <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
                <MenuItem value={PeriodSelectOption.today}>
                  {t('todayLabel')}
                </MenuItem>
                <MenuItem value={PeriodSelectOption.last30DaysLabel}>
                  {t('last30DaysLabel')}
                </MenuItem>
                <MenuItem value={PeriodSelectOption.last90DaysLabel}>
                  {t('last90DaysLabel')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {flags.dateRange && (
          // <Grid item container xs={3} spacing={2}>
          <>
            <Grid item xs={2.2}>
              <Datepicker
                name="date-from"
                label={t('dateFromLabel', { ns: 'forms' })}
                value={filterOptions?.dates?.dateFrom}
                endDate={filterOptions?.dates?.dateTo}
                onSelect={onSelectDateFrom}
              />
            </Grid>
            <Grid item xs={2.2}>
              <Datepicker
                name="date-to"
                label={t('dateToLabel', { ns: 'forms' })}
                value={filterOptions?.dates?.dateTo}
                startDate={filterOptions?.dates?.dateFrom}
                onSelect={onSelectDateTo}
              />
            </Grid>
          </>
          //  {/* </Grid> */}
        )}
        {flags.dateRangeFilter && (
          <>
            <Grid item xs={2.2}>
              <Datepicker
                name="date-from"
                label={t('dateFrom', { ns: 'forms' })}
                value={filterOptions?.createdAt?.startDate}
                startDate={filterOptions?.dates?.dateFrom}
                onSelect={onSelectDateRangeFrom}
              />
            </Grid>
            <Grid item xs={2.2}>
              <Datepicker
                name="date-to"
                label={t('dateTo', { ns: 'forms' })}
                value={filterOptions?.createdAt?.endDate}
                endDate={filterOptions?.dates?.dateTo}
                onSelect={onSelectDateRangeTo}
              />
            </Grid>
          </>
        )}
        {flags.search && (
          <Grid item xs={2.2}>
            <SearchField
              label={t('search')}
              handleKeyDown={handleKeyDown}
              onChangeSearch={onChangeSearch}
            />
          </Grid>
        )}

        {flags.status && statuses && (
          <Grid item xs={2.2}>
            <FormControl fullWidth>
              <InputLabel id="status-filter">
                {t('userStatusLabel', { ns: 'forms' })}
              </InputLabel>
              <Select
                labelId="status-filter"
                label={t('statusLabel', { ns: 'forms' })}
                value={filterOptions?.status || ALL_VALUES}
                onChange={onChangeStatus}
              >
                <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
                {statuses?.map((s) => (
                  <MenuItem value={s} key={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {flags.country && (
          <Grid item xs={2.2}>
            <CountrySelect
              multiple
              value={filterOptions?.country || []}
              withAll
              onChange={onChangeCountry}
            />
          </Grid>
        )}

        {flags.subscriptionType && (

          <Grid item xs={2.2}>
            <FormControl fullWidth>
              <InputLabel id="subscription-type-filter">
                {t('subscriptionType')}
              </InputLabel>
              <Select
                labelId="subscription-status-filter"
                label={t('subscriptionLabel')}
                value={filterOptions?.subscriptionType || ALL_VALUES}
                onChange={onChangeSubscriptionType}
              >
                <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
                <MenuItem value={SubscriptionTypeOption.tipster}>
                  {t('subscriptionType.label')}
                </MenuItem>
                <MenuItem value={SubscriptionTypeOption.channel}>
                  {t('subscriptionType.channel')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {flags.subscriptionStatus && (

          <Grid item xs={2.2}>
            <FormControl fullWidth>
              <InputLabel id="subscription-status-filter">
                {t('subscriptionStatus')}
              </InputLabel>
              <Select
                labelId="subscription-status-filter"
                label={t('subscriptionLabel')}
                value={filterOptions?.subscriptionStatus || ALL_VALUES}
                onChange={onChangeSubscriptionStatus}
              >
                <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
                <MenuItem value={SubscriptionStatusSelectOption.active}>
                  {t('activeLabel')}
                </MenuItem>
                <MenuItem value={SubscriptionStatusSelectOption.expired}>
                  {t('expiredLabel')}
                </MenuItem>
                <MenuItem value={SubscriptionStatusSelectOption.pending}>
                  {t('pendingLabel')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}


        {flags.paymentType && (

          <Grid item xs={2.2}>
            <FormControl fullWidth>
              <InputLabel id="subscription-type-filter">
                {t('paymentType')}
              </InputLabel>
              <Select
                labelId="payment-type-filter"
                label={t('paymentTypeLabel')}
                value={filterOptions?.paymentType || ALL_VALUES}
                onChange={onChangePaymentType}
              >
                <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
                <MenuItem value={PaymentTypeOption.manual}>
                  {t('manual.label')}
                </MenuItem>
                <MenuItem value={PaymentTypeOption.automatic}>
                  {t('automatic.label')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {flags.paymentMethod && (

          <Grid item xs={2.2}>
            <FormControl fullWidth>
              <InputLabel id="payment-option-filter">
                {t('paymentOption')}
              </InputLabel>
              <Select
                labelId="payment-option-filter"
                label={t('subscriptionLabel')}
                value={filterOptions?.paymentMethod || ALL_VALUES}
                onChange={onChangePaymentMethod}
              >
                <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
                {
                  paymentOptions?.map(item =>{
                    return (
                      <MenuItem value={item.node.id}
                        key={item.node.id}>
                        {item.node.paymentName}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </Grid>
        )}
        {flags.dateStart && (
          <Grid item container xs={6} spacing={2}>
            <Grid item xs={6}>
              <Datepicker
                name="date-start"
                label={t('dateStartLabel', { ns: 'forms' })}
                value={filterOptions.dateStart}
                onSelect={onSelectDateStart}
              />
            </Grid>
          </Grid>
        )}

        {flags.freeTrial && (
          <Grid item xs={2.2}>
            <FormControl fullWidth>
              <InputLabel id="free-trial-filter">
                {t('freeTrialLabel')}
              </InputLabel>
              <Select
                labelId="free-trial-filter"
                label={t('freeTrialLabel')}
                value={filterOptions?.freeTrial || ALL_VALUES}
                onChange={onChangeFreeTrial}
              >
                <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
                <MenuItem value={FreeTrialSelectOption.notUsed}>
                  {t('notUsedLabel')}
                </MenuItem>
                <MenuItem value={FreeTrialSelectOption.active}>
                  {t('activeLabel')}
                </MenuItem>
                <MenuItem value={FreeTrialSelectOption.expired}>
                  {t('expiredLabel')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {flags.anonymity && (
          <Grid item xs={2.2}>
            <FormControl fullWidth>
              <InputLabel id="anonymity-filter">{t('anonymity')}</InputLabel>
              <InputLabel id="anonymity-filter">{t('anonymity')}</InputLabel>
              <Select
                labelId="anonymity-filter"
                label={t('anonymity')}
                value={filterOptions.anonymity || ALL_VALUES}
                onChange={onChangeAnonymity}
              >
                <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
                <MenuItem value={DualSelectOption.yes}>{t('YES')}</MenuItem>
                <MenuItem value={DualSelectOption.no}>{t('NO')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {flags.currency && (

          <Grid item xs={2.2}>
            <CurrencySelect
              value={filterOptions?.currency || []}
              withAll
              onChange={onChangeCurrency}
              currencies={currencies}
              labelId={'currency-filter'}
              labelTitle={t('currency')}
            />
          </Grid>
        )}

        {flags.settled && (
          <Grid item xs={2.2}>
            <FormControl fullWidth>
              <InputLabel id="settled-filter">{t('settled')}</InputLabel>
              <InputLabel id="settled-filter">{t('settled')}</InputLabel>
              <Select
                labelId="settled-filter"
                label={t('settled')}
                value={filterOptions.settled || ALL_VALUES}
                onChange={onChangeSettled}
              >
                <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
                <MenuItem value={DualSelectOption.yes}>{t('YES')}</MenuItem>
                <MenuItem value={DualSelectOption.no}>{t('NO')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        {/* {flags.withCustom && customFilters
          ? customFilters.map((cf) => (
            <Grid key={cf.field} item xs={2.2}>
              <FormControl fullWidth>
                <InputLabel id={cf.field}>{t(cf.label)}</InputLabel>
                <Select
                  labelId={cf.field}
                  label={t(cf.label)}
                  value={(filterOptions[cf.field] as string) || ALL_VALUES}
                  onChange={onChangeCustom(cf.field)}
                >
                  {cf.withAll && (
                    <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
                  )}
                  {cf.elements?.map((el) => (
                    <MenuItem key={el.value} value={el.value}>
                      {el.label ?? el.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          ))
          : null} */}
        <br/>
        <br/>
        <br/>
        <Grid className="vb-flex-row-c-c" item xs={2}>
          <Button
            sx={{ color: 'black' }}
            variant="contained"
            fullWidth
            onClick={onResetFilters}
          >
            {t('buttonReset')}
            <Box className="vb-flex-row-c-c" sx={{ ml: 1, color: 'black' }}>
              <CloseIcon />
            </Box>
          </Button>
        </Grid>
      </Grid>
      {/* )} */}

      {resetText?.length === 0 && (
        <Grid className="vb-flex-row-c-c" item xs={2.2}>
          <Button
            sx={{ color: 'black' }}
            variant="contained"
            fullWidth
            onClick={onResetFilters}
          >
            {t('buttonReset')}
            <Box className="vb-flex-row-c-c" sx={{ ml: 1, color: 'black' }}>
              <CloseIcon />
            </Box>
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default memo(TableFilters)

