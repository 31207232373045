import i18next from '@app/i18n'

import { ROUTES } from './routes'
import { LayoutMenuItemsType } from '@app/types'

export const LayoutMenuItems = [
  {
    route: ROUTES.superadmins.short,
    type: LayoutMenuItemsType.superAdminList,
    text: i18next.t('buttonSuperadmins'),
    onlyAdmin: true,
  },
  {
    route: ROUTES.users.short,
    type: LayoutMenuItemsType.userList,
    text: i18next.t('buttonUsers'),
    onlyAdmin: false,
  },
  {
    route: ROUTES.tipsters.short,
    type: LayoutMenuItemsType.tipsterList,
    text: i18next.t('buttonTipsters'),
    onlyAdmin: true,
  },
  {
    route: ROUTES.adverts.short,
    type: LayoutMenuItemsType.advert,
    text: i18next.t('buttonAdvert'),
    onlyAdmin: true,
  },
  {
    route: ROUTES.channels.short,
    type: LayoutMenuItemsType.channel,
    text: i18next.t('buttonChannel'),
    onlyAdmin: true,
  },
  {
    route: ROUTES.services.short,
    type: LayoutMenuItemsType.service,
    text: i18next.t('buttonService'),
    onlyAdmin: true,
  },
  {
    route: ROUTES.notifications.short,
    type: LayoutMenuItemsType.notification,
    text: i18next.t('buttonNotifications'),
    onlyAdmin: true,
  },
  {
    route: ROUTES.affiliateLinks.short,
    type: LayoutMenuItemsType.affiliateLinks,
    text: i18next.t('buttonAffiliateLinks'),
    onlyAdmin: true,
  },
  {
    route: ROUTES.analytics.short,
    type: LayoutMenuItemsType.analytics,
    text: i18next.t('analytics'),
    onlyAdmin: true,
  },
  {
    route: ROUTES.subscriptions.short,
    type: LayoutMenuItemsType.subscriptions,
    text: i18next.t('subscriptions'),
    onlyAdmin: true,
  },
  {
    route: ROUTES.financial.short,
    type: LayoutMenuItemsType.financial,
    text: i18next.t('financial'),
    onlyAdmin: false,
  },
  {
    route: ROUTES.tipsterInvite.short,
    type: LayoutMenuItemsType.tipsterInvite,
    text: i18next.t('tipsterInvite'),
    onlyAdmin: true,
  },
]
