import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { ROUTES } from '@app/constants'
import TipsterListPage from './TipsterListPage'
import TipsterViewPage from './TipsterViewPage'
import TipsterCreatePage from './TipsterCreatePage'
import { CurrentUserState } from '@app/storage'
import { useSnapshot } from 'valtio'

interface TipsterRoutesProps {}

const TipsterRoutes: FC<TipsterRoutesProps> = () => {
  const { isAdmin } = useSnapshot(CurrentUserState)
  return (
    <Routes>
      <Route index element={<TipsterListPage/>}/>
      {
        isAdmin? (<>
          <Route path={ROUTES.tipsterEdit.short} element={<TipsterCreatePage editMode/>}/>
          <Route path={ROUTES.tipsterCreate.short} element={<TipsterCreatePage/>}/>
        </>): null
      }
      <Route path={ROUTES.tipsterViewShort} element={<TipsterViewPage/>}/>
    </Routes>
  )
}

export default TipsterRoutes
