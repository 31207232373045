import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { ROUTES } from '@app/constants'
import ServiceListPage from './ServiceListPage'
import ServiceViewPage from './ServiceViewPage'
import ServiceCreatePage from './ServiceCreatePage'

interface ServiceRoutesProps {}

const ServiceRoutes: FC<ServiceRoutesProps> = () => (
  <Routes>
    <Route index element={<ServiceListPage/>}/>
    <Route path={ROUTES.serviceEdit.short} element={<ServiceCreatePage editMode/>}/>
    <Route path={ROUTES.serviceCreate.short} element={<ServiceCreatePage/>}/>
    <Route path={ROUTES.serviceViewShort} element={<ServiceViewPage/>}/>
  </Routes>
)

export default ServiceRoutes
