import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { PageLayout } from '@app/components'
import { NotificationSettingScope } from '@app/graphql'
import { NotificationsTab } from '@app/components'

interface NotificationPageProps {}

const NotificationPage: FC<NotificationPageProps> = () => {
  const { t } = useTranslation([ 'translation', 'pageTitles' ])

  return (
    <PageLayout
      title={t('notifications', { ns: 'pageTitles' })}
    >
      <NotificationsTab scope={NotificationSettingScope.Global}/>
    </PageLayout>
  )
}

export default NotificationPage
