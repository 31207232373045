import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import AnalyticReportListPage from './AnalyticReportListPage'

interface AnalyticReportRoutesProps {}

const AnalyticReportRoutes: FC<AnalyticReportRoutesProps> = () => (
  <Routes>
    <Route index element={<AnalyticReportListPage/>}/>
  </Routes>
)

export default AnalyticReportRoutes
