import { FormElementCreation, PageLayout, TableTemplate } from '@app/components'
import { TableTipsterInvitationKeyField } from '@app/constants'
import { TableHelper } from '@app/helpers'
import { FormFieldDataType, FormFieldType } from '@app/types'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Box, Typography } from '@mui/material'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GetTipsterInviteesDocument, CreateTipsterInviteesDocument, CreateTipsterInviteeModel } from '@app/graphql'
import env from '@app/environment'


interface TipsterInvitationListPageProps {}

const TipsterInvitationListPage: FC<TipsterInvitationListPageProps> = () => {
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [
    getInvitees,
    { data: inviteesData, loading: inviteesLoading },
  ] = useLazyQuery(GetTipsterInviteesDocument, { fetchPolicy: 'no-cache' })
  const [ createTipsterInvitees ] = useMutation(CreateTipsterInviteesDocument)
  const getPagination = () => {
    const row = localStorage.getItem('per_page')
    const letLimit = Number(row) ?? rowsPerPage
    return { limit: letLimit, skip: pageNumber * rowsPerPage }
  }

  useEffect(() => {
    getInvitees({
      variables: {
        ...getPagination()
      }}
    )
  }, [])
  const onPaginate = (page: number, rowsPage: number) => {
    getInvitees({
      variables: {
        limit: rowsPage,
        skip: page * rowsPage,
      },
    })
    setPageNumber(page)
    setRowsPerPage(rowsPage)
  }
  const columns = useMemo(() => TableHelper.parseTipsterInvitationFields(), [])
  const rows = useMemo(() => {
    const invitees = inviteesData?.listTipsterInvitees?.invitees ?? []
    return invitees.map((invitee) => ({
      ...invitee,
      tipsterInviteLink: `${env.VB_CLIENT_APP_DOMAIN}/?tivb=${invitee.tipsterInviteLink}`,
      tipsterRegistered: !!invitee.tipsterRegistered,
      tipsterId: invitee.tipsterRegistered
    }))
  }, [inviteesData])
  const JSONFile = 'JSONFile'
  const title = 'Process Tipter Invite File'
  const fields: FormFieldDataType[] = [
    {
      name: JSONFile,
      label: t('Select Tipster Invite File'),
      type: FormFieldType.file,
      initValue: '',
      validation: yup.mixed(),
      required: true,
      file: {
        readText: true,
      }
    },
  ]
  const processTipsterInvitee = (socialQueries): CreateTipsterInviteeModel[] => {
    let  cleanSocialDesc = []
    let socials = []
    for (const {content} of socialQueries) {
      cleanSocialDesc = [...cleanSocialDesc, ...content.results.organic.map(
        ({desc, url_shown}) => {
          const urlShownSplits = url_shown.split('›')
          let name = urlShownSplits.length > 1? urlShownSplits[1]: null
          name = name? name.trim(): name
          return [name, `https://www.instagram.com/${name}`, desc,]
        }
      )]
    }

    const seen = []
    for (const [instagramName, instagramURL, desc,] of cleanSocialDesc) {
      if(instagramName == '...' || !instagramName || seen.includes(instagramName)) continue
      const telegramURL = (desc.match(/(https?:\/\/)?(t(?:elegram)?\.me|telegram\.org)\/\+?([a-z0-9-A-Z_]{5,32})\/?\.?/g)) ?? null
      let instagramFollowers = desc.match(/(\d+((\.|,)\d+)?[kKmM]?)(?=\s+((f|F)ollower|seguidore))/g)
      instagramFollowers = (instagramFollowers? instagramFollowers[0].replace(',','.'): instagramFollowers) ?? null
      socials = [
        ...socials,
        {
          instagramName,
          instagramFollowers,
          telegramURL: telegramURL? telegramURL[0] : null,
          instagramURL,
        }]
      seen.push(instagramName)
    }
    return socials
  }
  const onSubmit = async (values) => {
    if(!values[JSONFile]) return
    const {results: socialQueries} = JSON.parse(values[JSONFile])
    const invitees = processTipsterInvitee(socialQueries)
    // const {data: inviteeData} = await createTipsterInvitees({ variables: { input: invitees } })
    // console.log(inviteeData?.createTipsterInvitees?.invitees ?? [])
    await createTipsterInvitees({ variables: { input: invitees } })
    window.location.reload()
  }
  return (
    <PageLayout
      title={t('tipsterInviteList', { ns: 'pageTitles' })}
      loading={inviteesLoading}
    >
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h4" gutterBottom>
          {title}
        </Typography>
        <Box>
          <FormElementCreation
            fields={fields}
            onSubmit={onSubmit}
            onSaveText={t('Process File')}
          />
        </Box>
      </Box>
      <TableTemplate
        columns={columns}
        rows={rows}
        fieldKey={TableTipsterInvitationKeyField}
        title={t('tipsterInviteList')}
        preventDoubleClick={true}
        totalAmount={inviteesData?.listTipsterInvitees?.count}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        onPaginate={onPaginate}
      />
    </PageLayout>
  )
}

export default TipsterInvitationListPage