import { TableField, TableFieldType } from '@app/types'
import i18n from '@app/i18n'

// USER
export const UserDataFields = [
  { name: TableField.email, label: i18n.t('emailLabel'), type: TableFieldType.str },
  { name: TableField.displayName, label: i18n.t('displayNameLabel'), type: TableFieldType.str },
  { name: TableField.firstName, label: i18n.t('firstNameLabel'), type: TableFieldType.str },
  { name: TableField.lastName, label: i18n.t('lastNameLabel'), type: TableFieldType.str },
  { name: TableField.country, label: i18n.t('countryLabel'), type: TableFieldType.str },
  { name: TableField.gender, label: i18n.t('genderLabel'), type: TableFieldType.str },
  { name: TableField.phoneNumber, label: i18n.t('phoneNumberLabel'), type: TableFieldType.str },
  { name: TableField.userStatus, label: i18n.t('statusLabel'), type: TableFieldType.str },
  { name: TableField.bio, label: i18n.t('bioLabel'), type: TableFieldType.str },
]
// TIPSTER
export const TipsterDataFields = [
  { name: TableField.background, label: i18n.t('background'), type: TableFieldType.profileBackground },
  { name: TableField.email, label: i18n.t('emailLabel'), type: TableFieldType.str },
  { name: TableField.displayName, label: i18n.t('displayNameLabel'), type: TableFieldType.str },
  { name: TableField.firstName, label: i18n.t('firstNameLabel'), type: TableFieldType.str },
  { name: TableField.lastName, label: i18n.t('lastNameLabel'), type: TableFieldType.str },
  { name: TableField.tipsterChannels, label: i18n.t('channelsLabel'), type: TableFieldType.chips },
  { name: TableField.country, label: i18n.t('countryLabel'), type: TableFieldType.str },
  { name: TableField.gender, label: i18n.t('genderLabel'), type: TableFieldType.str },
  { name: TableField.phoneNumber, label: i18n.t('phoneNumberLabel'), type: TableFieldType.str },
  { name: TableField.tipsterStatus, label: i18n.t('statusLabel'), type: TableFieldType.str },
  { name: TableField.bio, label: i18n.t('bioLabel'), type: TableFieldType.str },
  { name: TableField.alternativeName, label: i18n.t('alternativeNameLabel'), type: TableFieldType.str },
]
// SUPERADMIN
export const SuperadminDataFields = [
  { name: TableField.email, label: i18n.t('emailLabel'), type: TableFieldType.str },
  { name: TableField.displayName, label: i18n.t('displayNameLabel'), type: TableFieldType.str },
  { name: TableField.firstName, label: i18n.t('firstNameLabel'), type: TableFieldType.str },
  { name: TableField.lastName, label: i18n.t('lastNameLabel'), type: TableFieldType.str },
  { name: TableField.country, label: i18n.t('countryLabel'), type: TableFieldType.str },
  { name: TableField.gender, label: i18n.t('genderLabel'), type: TableFieldType.str },
  { name: TableField.phoneNumber, label: i18n.t('phoneNumberLabel'), type: TableFieldType.str },
  { name: TableField.bio, label: i18n.t('bioLabel'), type: TableFieldType.str },
]
// ADVERT
export const AdvertDataFields = [
  { name: TableField.title, label: i18n.t('titleLabel'), type: TableFieldType.str },
  { name: TableField.placement, label: i18n.t('placement'), type: TableFieldType.chips },
  { name: TableField.startDate, label: i18n.t('startDateLabel'), type: TableFieldType.date },
  { name: TableField.endDate, label: i18n.t('endDateLabel'), type: TableFieldType.date },
  { name: TableField.referral, label: i18n.t('referralLinkLabel'), type: TableFieldType.externalLink },
  { name: TableField.visible, label: i18n.t('visibleToTipsterLabel'), type: TableFieldType.bool },
  { name: TableField.active, label: i18n.t('active'), type: TableFieldType.bool },
]
// CHANNEL
export const ChannelDataFields = [
  { name: TableField.id, label: i18n.t('id'), type: TableFieldType.str },
  { name: TableField.channelName, label: i18n.t('name'), type: TableFieldType.str },
  { name: TableField.background, label: i18n.t('background'), type: TableFieldType.profileBackground },
  { name: TableField.channelStatus, label: i18n.t('status'), type: TableFieldType.str },
  { name: TableField.dateJoined, label: i18n.t('createdAt'), type: TableFieldType.date },
  { name: TableField.country, label: i18n.t('country'), type: TableFieldType.str },
  { name: TableField.channelType, label: i18n.t('type'), type: TableFieldType.str },
  { name: TableField.anonymized, label: i18n.t('anonymity'), type: TableFieldType.bool },
  { name: TableField.channelDeal, label: i18n.t('deal'), type: TableFieldType.num },
  { name: TableField.trialSubscription, label: i18n.t('trialSubscription'), type: TableFieldType.num },
  { name: TableField.feeForPayment, label: i18n.t('fee'), type: TableFieldType.num },
  { name: TableField.paymentOptions, label: i18n.t('paymentOption'), type: TableFieldType.str },
  { name: TableField.usersLimit, label: i18n.t('usersLimit'), type: TableFieldType.num },
]
// SERVICE
export const ServiceDataFields = [
  { name: TableField.id, label: i18n.t('id'), type: TableFieldType.str },
  { name: TableField.internalName, label: i18n.t('internalName'), type: TableFieldType.str },
  { name: TableField.status, label: i18n.t('status'), type: TableFieldType.str },
  { name: TableField.dateStart, label: i18n.t('dateStart'), type: TableFieldType.date },
  { name: TableField.title, label: i18n.t('title'), type: TableFieldType.str },
  { name: TableField.subtitle, label: i18n.t('subtitle'), type: TableFieldType.str },
  { name: TableField.CTATitle, label: i18n.t('CTATitle'), type: TableFieldType.str },
  { name: TableField.CTASubtitle, label: i18n.t('CTASubtitle'), type: TableFieldType.str },
  { name: TableField.paymentMethod, label: i18n.t('paymentMethod'), type: TableFieldType.chips },
  { name: TableField.autoRenewal, label: i18n.t('autoRenewal'), type: TableFieldType.bool },
  { name: TableField.expirationAlert, label: i18n.t('expirationAlert'), type: TableFieldType.bool },
  { name: TableField.tipsterName, label: i18n.t('tipsterName'), type: TableFieldType.str },
  { name: TableField.channelName, label: i18n.t('channelName'), type: TableFieldType.str },
  { name: TableField.contactEmail, label: i18n.t('contact'), type: TableFieldType.str },
  { name: TableField.info, label: i18n.t('info'), type: TableFieldType.html },
  { name: TableField.freeTrialPeriod, label: i18n.t('freeTrialPeriod'), type: TableFieldType.str },
]

export const ServiceDurationFields = [
  { name: TableField.duration, label: i18n.t('duration'), type: TableFieldType.num },
  { name: TableField.price, label: i18n.t('price'), type: TableFieldType.num },
  { name: TableField.currency, label: i18n.t('currency'), type: TableFieldType.str },
]
// AFFILIATE LINK
export const AffiliateLinkDataFields = [
  { name: TableField.id, label: i18n.t('id'), type: TableFieldType.str },
  { name: TableField.internalName, label: i18n.t('internalName'), type: TableFieldType.str },
  { name: TableField.status, label: i18n.t('status'), type: TableFieldType.str },
  { name: TableField.dateCreated, label: i18n.t('dateCreated'), type: TableFieldType.date },
  { name: TableField.expirationDate, label: i18n.t('expirationDate'), type: TableFieldType.date },
  { name: TableField.tipsterName, label: i18n.t('tipsterName'), type: TableFieldType.str },
  { name: TableField.channelName, label: i18n.t('channelName'), type: TableFieldType.str },
  { name: TableField.registrations, label: i18n.t('registrations'), type: TableFieldType.num },
]

// SUBSCRIPTION
export const SubscriptionDataFields = [
  { name: TableField.id, label: i18n.t('id'), type: TableFieldType.str },
  { name: TableField.serviceName, label: i18n.t('serviceName'), type: TableFieldType.str },
  { name: TableField.price, label: i18n.t('price'), type: TableFieldType.num },
  { name: TableField.currency, label: i18n.t('currency'), type: TableFieldType.str },
  { name: TableField.duration, label: i18n.t('duration'), type: TableFieldType.str },
]
