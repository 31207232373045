import { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import TipsterInvitationListPage from './TipsterInvitationListPage'

interface TipsterInvitationRoutesProps {}

const TipsterInvitationRoutes: FC<TipsterInvitationRoutesProps> = () => {
  return (
    <Routes>
      <Route index element={<TipsterInvitationListPage/>}/>
    </Routes>
  )
}

export default TipsterInvitationRoutes
