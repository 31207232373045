import i18n from '@app/i18n'

export const NOTIFICATION_SYSTEM_NAMES_MAP = new Map([
  [ 'userWarning', i18n.t('userWarning') ],
  [ 'referralJoined', i18n.t('referralJoined') ],
  [ 'appUpdated', i18n.t('appUpdated') ],
  [ 'userBanned', i18n.t('userBanned') ],
  [ 'userMuted', i18n.t('userMuted') ],
  [ 'userComment', i18n.t('userComment') ],
])

export const NOTIFICATION_TIPS_NAMES_MAP = new Map([
  [ 'tipsterAddedVipTip', i18n.t('tipsterAddedVipTip') ],
  [ 'tipsterAddedFreeTip', i18n.t('tipsterAddedFreeTip') ],
  [ 'tipResult', i18n.t('tipResult') ],
  [ 'newMultiPostTip', i18n.t('newMultiPostTip') ],
  [ 'postTipResult', i18n.t('postTipResult') ],
  [ 'newSinglePostTip', i18n.t('newSinglePostTip') ],
  [ 'multiTipResultPartial', i18n.t('multiTipResultPartial') ],
])

export const NOTIFICATION_ACTIVITY_NAMES_MAP = new Map([
  [ 'pollResult', i18n.t('pollResult') ],
  [ 'pinnedContent', i18n.t('pinnedContent') ],
  [ 'newPost', i18n.t('newPost') ],
  [ 'eventVoteResult', i18n.t('eventVoteResult') ],
  [ 'pollPublished', i18n.t('pollPublished') ],
])

export const NOTIFICATION_SUBSCRIPTIONS_NAMES_MAP = new Map([
  [ 'subscriptionExpired', i18n.t('subscriptionExpired') ],
  [ 'freeTrialExpired', i18n.t('freeTrialExpired') ],
  [ 'freeTrialExpiringIn24Hours', i18n.t('freeTrialExpiringIn24Hours') ],
  [ 'freeTrialExpiringIn3days', i18n.t('freeTrialExpiringIn3days') ],
])

export const NOTIFICATION_MESSAGES_NAMES_MAP = new Map([
  [ 'messages', i18n.t('messages') ],
  [ 'adminToTipster', i18n.t('adminToTipster') ],
  [ 'tipsterToUser', i18n.t('tipsterToUser') ],
])
