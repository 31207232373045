import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions
} from '@mui/material'
import { WarningAmber } from '@mui/icons-material'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { PageLayout, CreateNewPassword } from '@app/components'
import { SubmitEmail } from './components'
import { ROUTES } from '@app/constants'
import { AuthHelper } from '@app/helpers'

interface PasswordRecoveryPageProps {}

const PasswordRecoveryPage: FC<PasswordRecoveryPageProps> = () => {
  const [ loading, setLoading ] = useState(false)
  const [ openSentFailed, setOpenSentFailed ] = useState(false)
  const [ openSentSuccess, setOpenSentSuccess ] = useState(false)
  const [ openChangeSuccess, setOpenChangeSuccess ] = useState(false)
  const [ openChangeFailed, setOpenChangeFailed ] = useState(false)
  const [ usernameConfirmed, setUsernameConfirmed ] = useState<string>(null)
  const [ sentCode, setSentCode ] = useState(false)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const navigate = useNavigate()

  const onLogin = () => navigate(ROUTES.login)
  const onSubmitEmail = async (username: string) => {
    setLoading(true)
    const { success, error } = await AuthHelper.sendCodeForgotPassword(username)
    if (success && !error) {
      setUsernameConfirmed(username)
      setOpenSentSuccess(true)
    } else {
      setOpenSentFailed(true)
    }

    setLoading(false)
  }
  const onSubmitPassword = async (password: string, verificationCode: string) => {
    setLoading(true)
    const { success, error } = await AuthHelper.sendNewPasswordForgotPassword(
      usernameConfirmed,
      verificationCode,
      password
    )
    if (success && !error) {
      setOpenChangeSuccess(true)
    } else {
      setOpenChangeFailed(true)
    }
    setLoading(false)
  }
  const onCloseFailedSendingDialog = () => setOpenSentFailed(false)
  const onSuccessSendingDialogOk = () => {
    setSentCode(true)
    setOpenSentSuccess(false)
  }
  const onCloseSuccessChangeDialog = () => {
    setOpenChangeSuccess(false)
    onLogin()
  }
  const onCloseFailedChangeDialog = () => setOpenChangeFailed(false)

  return (
    <PageLayout title={t('recovery', { ns: 'pageTitles' })} loading={loading} hideHeader>
      <StyledFormContainer className="vb-flex-column-fs-c">
        {
          sentCode && usernameConfirmed ?
            <CreateNewPassword withVerificationCode onSubmit={onSubmitPassword} onLogin={onLogin}/> :
            <SubmitEmail onSubmit={onSubmitEmail}/>
        }
      </StyledFormContainer>
      {/* DIALOGS */}
      <Dialog open={openSentSuccess} onClose={onSuccessSendingDialogOk}>
        <DialogTitle>{t('emailForRecoveryWasSentText')}</DialogTitle>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={onSuccessSendingDialogOk} autoFocus variant="contained">
            {t('buttonOk')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSentFailed} onClose={onCloseFailedSendingDialog}>
        <DialogTitle>
          <StyledErrorDialog className="vb-flex-column-c-c">
            <WarningAmber color="warning"/>
            {t('emailForRecoverySendingError')}
          </StyledErrorDialog>
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={onCloseFailedSendingDialog} autoFocus variant="contained">
            {t('buttonOk')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openChangeSuccess} onClose={onCloseSuccessChangeDialog}>
        <DialogTitle>
          {t('passwordChangedSuccessfully')}
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant="contained" autoFocus onClick={onCloseSuccessChangeDialog}>
            {t('buttonOk')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openChangeFailed} onClose={onCloseFailedChangeDialog}>
        <DialogTitle>
          <StyledErrorDialog className="vb-flex-column-c-c">
            <WarningAmber color="warning"/>
            {t('passwordChangeFailed')}
          </StyledErrorDialog>
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant="contained" autoFocus onClick={onCloseFailedChangeDialog}>
            {t('buttonOk')}
          </Button>
        </DialogActions>
      </Dialog>
    </PageLayout>
  )
}

const StyledFormContainer = styled.div`
  width: 100%;
  margin-top: 200px;
`

const StyledErrorDialog = styled.div`
  text-align: center;
`

export default PasswordRecoveryPage
