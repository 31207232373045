export enum LayoutMenuItemsType {
  superAdminList = 'superAdminList',
  tipsterList = 'tipsterList',
  userList = 'userList',
  advert = 'advert',
  channel = 'channel',
  service = 'service',
  notification = 'notification',
  affiliateLinks = 'affiliateLinks',
  analytics = 'analytics',
  subscriptions = 'subscriptions',
  financial = 'financial',
  tipsterInvite = 'tipsterInvite'
}
