import { FC, ReactNode, useEffect, Suspense, useState } from 'react'
import styled from 'styled-components'

import Loader from './Loader'
import PageLayoutHeader from './PageLayoutHeader'
import { AuthHelper } from '@app/helpers'
import { resetCurrentUserState } from '@app/storage'

interface PageLayoutProps {
  title: string
  loading?: boolean
  hideHeader?: boolean
  children?: ReactNode
}

const PageLayout: FC<PageLayoutProps> = ({
  title = '',
  loading: outerLoading,
  hideHeader,
  children,
}) => {
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    document.title = title
  }, [ title ])

  const onLogout = async () => {
    try {
      setLoading(true)
      const response = await AuthHelper.logOut()
      if (response.success) resetCurrentUserState()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Suspense fallback={<Loader fullScreen/>}>
      <StyledPageLayout $hideHeader={hideHeader}>
        {
          !hideHeader &&
          <PageLayoutHeader onLogout={onLogout}/>
        }
        {
          outerLoading || loading ?
            <Loader fullScreen/> :
            children
        }
      </StyledPageLayout>
    </Suspense>
  )
}

const StyledPageLayout = styled.div<{ $hideHeader: boolean }>`
  width: 100%;
  padding-top: ${p => p.$hideHeader ? '0' : '60px'};
`

export default PageLayout
