import { FC } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { WarningAmber, CheckCircle } from '@mui/icons-material'

interface DialogResultProps {
  open: boolean
  type: 'success' | 'error'
  message: string
  onClose: () => void
}

const DialogResult: FC<DialogResultProps> = ({
  open,
  type,
  message,
  onClose,
}) => {
  const { t } = useTranslation([ 'translation' ])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div className="vb-flex-row-c-c">
          <Box sx={{ mr: 2 }} className="vb-flex-row-c-c">
            {
              type === 'success' ?
                <CheckCircle color="success"/> :
                <WarningAmber color="warning"/>
            }
          </Box>
          {
            type === 'success' ?
              t('successfully!')?.toUpperCase() :
              t('error!')?.toUpperCase()
          }
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="vb-flex-row-c-c vb-pre">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant="contained" autoFocus onClick={onClose}>
          {t('buttonOk')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogResult
