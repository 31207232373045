import { FormFieldType } from '@app/types'
import ChannelSelect from './ChannelSelect'
import CountrySelect from './CountrySelect'
import { SelectChangeEvent } from '@mui/material'
import { useState } from 'react'

const TargetFields = ({
  channelFieldName,
  sxProps,
  formik,
  required,
  countryFieldName,
  targetCounter,
  initialChannelValue,
  initialCountryValues
}) => {
  const [ countryvalues, setCountryvalues ] = useState<string[]>(initialCountryValues)
  const [ channelvalue, setChannelvalue ] = useState<string>(initialChannelValue)
  const channelOnchange = (e: SelectChangeEvent) => {
    const { value: channelId } = e.target
    if(formik.values[FormFieldType.advertTarget][targetCounter]){
      const existingValue = formik.values[FormFieldType.advertTarget]
      existingValue[targetCounter]['channelId'] = channelId
    } else {
      const existingValue = formik.values[FormFieldType.advertTarget]
      existingValue[targetCounter] = { channelId, countryIds: []}
    }
    setChannelvalue(channelId)
  }

  const countryOnchange = (e: SelectChangeEvent) => {
    const { value } = e.target
    const cleanValues = (
      typeof value === 'string'
        ? value.split(',')
        : (value as string[]).map( item => item)
    )
    const currentValues = formik.values[FormFieldType.advertTarget][targetCounter] ?? []
    if(formik.values[FormFieldType.advertTarget][targetCounter]){
      const existingValue = formik.values[FormFieldType.advertTarget]
      existingValue[targetCounter]['countryIds'] = Array.from(
        new Set([ ...(currentValues[1] ?? []), ...cleanValues ])
      )
    } else {
      const existingValue = formik.values[FormFieldType.advertTarget]
      existingValue[targetCounter] = { channelId: null, countryIds: cleanValues }
    }
    setCountryvalues(typeof value === 'string' ? value.split(',') : value)
  }
  return (
    <>
      <ChannelSelect
        key={channelFieldName}
        sxProps={sxProps}
        fieldName={channelFieldName}
        required={required}
        value={channelvalue}
        onChange={channelOnchange}
      />
      <CountrySelect
        key={countryFieldName}
        sxProps={sxProps}
        fieldName={countryFieldName}
        required={required}
        multiple={true}
        value={countryvalues ?? []}
        onChange={countryOnchange}
      />
    </>
  )
}

export default TargetFields
