import { FC } from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ActionsBlockProps {
  withSubmit?: boolean
  submitDisabled?: boolean
  isActive?: boolean
  onCancel?: () => void
  onActivate?: () => void
}

const ActionsBlock: FC<ActionsBlockProps> = ({
  withSubmit,
  submitDisabled,
  isActive,
  onCancel,
  onActivate,
}) => {
  const { t } = useTranslation([ 'translation' ])

  return (
    <div>
      {
        onCancel ?
          <Button sx={{ mr: 2 }} variant="contained" color="secondary" onClick={onCancel}>
            {t('buttonCancel')}
          </Button> :
          null
      }
      {
        onActivate ?
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            color={isActive ? 'error' : 'success'}
            onClick={onActivate}
          >
            {isActive ? t('buttonDeactivate') : t('buttonActivate')}
          </Button> :
          null
      }
      {
        withSubmit &&
          <Button variant="contained" type="submit" disabled={submitDisabled}>
            {t('buttonSave')}
          </Button>
      }
    </div>
  )
}

export default ActionsBlock
