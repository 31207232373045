import { FC, useEffect } from 'react'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, FormHelperText, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { useFormik } from 'formik'

import { GetChannelsWithMinDataDocument, SortMethod } from '@app/graphql'
import { ALL_VALUES } from '@app/constants'

interface ChannelSelectProps {
  value?: string
  withAll?: boolean
  sxProps?: SxProps
  formik?: ReturnType<typeof useFormik<any>>
  fieldName?: string
  required?: boolean
  onChange?: (e: SelectChangeEvent) => void
}

const ChannelSelect: FC<ChannelSelectProps> = ({
  withAll,
  value,
  sxProps,
  formik,
  fieldName,
  required,
  onChange,
}) => {
  const { t } = useTranslation([ 'forms' ])
  const [
    getChannels,
    { data, loading },
  ] = useLazyQuery(GetChannelsWithMinDataDocument, { fetchPolicy: 'network-only' })

  useEffect(() => {
    getChannels({
      variables: {
        filter: {},
        sort: { channelName: SortMethod.Asc },
      },
    })
  }, [])

  const withError = formik ? formik.touched[fieldName] && Boolean(formik.errors[fieldName]) : false
  const helperText = formik ? formik.touched[fieldName] && t(formik.errors[fieldName] as string) : ''
  const channels = loading? [] : data?.channels?.channels ?? []


  return !loading && (
    <FormControl fullWidth error={withError} sx={sxProps} required={required}>
      <InputLabel id="channel-filter">{t('channelLabel', { ns: 'forms' })}</InputLabel>
      <Select
        id={fieldName}
        name={fieldName}
        labelId="channel-filter"
        label={t('channelLabel', { ns: 'forms' })}
        value={formik && fieldName ? formik.values[fieldName] ?? '' : value}
        disabled={loading}
        error={withError}
        required={required}
        onChange={formik ? formik.handleChange : (onChange ? onChange : null)}
        onBlur={formik ? formik.handleBlur : null}
      >
        {
          withAll &&
          <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
        }
        {
          channels.map(c => (
            <MenuItem key={c.value} value={c.value}>{c.label}</MenuItem>
          ))
        }
      </Select>
      {
        helperText ?
          <FormHelperText>{helperText}</FormHelperText> :
          null
      }
    </FormControl>
  )
}

export default ChannelSelect
