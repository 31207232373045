import { FC, HTMLInputTypeAttribute } from 'react'
import { SxProps, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

interface FormikTextFieldProps {
  fieldName: string
  formik: ReturnType<typeof useFormik<any>>
  label: string
  sxProps?: SxProps
  required?: boolean
  multiline?: boolean
  type?: HTMLInputTypeAttribute
}

const FormikTextField: FC<FormikTextFieldProps> = ({
  fieldName,
  formik,
  label,
  sxProps = {},
  required,
  multiline,
  type = 'text',
}) => {
  const { t } = useTranslation([ 'translation', 'forms' ])

  return (
    <TextField
      sx={sxProps}
      fullWidth
      id={fieldName}
      name={fieldName}
      label={label}
      value={formik.values[fieldName]}
      error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
      helperText={formik.touched[fieldName] && t(formik.errors[fieldName] as string)}
      type={type}
      required={required}
      multiline={multiline}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
    />
  )
}

export default FormikTextField
