import { FC, memo, useMemo, useState } from 'react'
import { Paper, Box, FormControl, InputLabel, Select, MenuItem, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import CalendarGrid from './CalendarGrid'
import { CalendarHelper } from '@app/helpers'
import { ScrollContainer } from '@app/ui'

interface CalendarProps {
  value: Date
  startDate?: Date
  endDate?: Date
  width: number
  onSelect: (date: Date) => void
}

const Calendar: FC<CalendarProps> = ({ value, startDate, endDate, width, onSelect }) => {
  const [ currentMonth, setCurrentMonth ] = useState(value.getMonth() + 1)
  const [ currentYear, setCurrentYear ] = useState(value.getFullYear())
  const [ openMonthSelection, setOpenMonthSelection ] = useState(false)
  const [ openYearSelection, setOpenYearSelection ] = useState(false)
  const { t } = useTranslation([ 'translation', 'forms' ])

  const onToggleYearSelection = () => {
    setOpenYearSelection(prev => !prev)
    setOpenMonthSelection(false)
  }
  const onToggleMonthSelection = () => {
    setOpenMonthSelection(prev => !prev)
    setOpenYearSelection(false)
  }
  const onSelectYear = (year: number) => () => {
    setCurrentYear(year)
    setOpenYearSelection(false)
  }
  const onSelectMonth = (month: number) => () => {
    setCurrentMonth(month)
    setOpenMonthSelection(false)
  }

  const yearsList = useMemo(() => CalendarHelper.getYearsList(), [])
  const monthName = useMemo(() => CalendarHelper.getMonthNameByNumber(currentMonth), [ currentMonth ])

  return (
    <Paper>
      <div className="vb-flex-row-c-c">
        <Box sx={{ p: 1, width: '50%' }}>
          <FormControl fullWidth>
            <InputLabel id="calendar-year">{t('yearLabel', { ns: 'forms' })}</InputLabel>
            <Select
              labelId="calendar-year"
              label={t('yearLabel', { ns: 'forms' })}
              value={currentYear}
              readOnly
              size="small"
              onClick={onToggleYearSelection}
            >
              <MenuItem value={currentYear}>{currentYear}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ p: 1, width: '50%' }}>
          <FormControl fullWidth>
            <InputLabel id="calendar-month">{t('monthLabel', { ns: 'forms' })}</InputLabel>
            <Select
              labelId="calendar-month"
              label={t('monthLabel', { ns: 'forms' })}
              value={currentMonth}
              readOnly
              size="small"
              onClick={onToggleMonthSelection}
            >
              <MenuItem value={currentMonth}>{monthName}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>

      <StyledScroll $width={width}>
        {
          !openYearSelection && !openMonthSelection &&
          <CalendarGrid
            currentDate={value}
            selectedMonth={currentMonth}
            selectedYear={currentYear}
            startDate={startDate}
            endDate={endDate}
            onSelect={onSelect}
          />
        }
        {
          openYearSelection &&
          <Grid container spacing={2} sx={{ p: 1 }}>
            {
              yearsList.map(y => (
                <Grid key={y} item xs={4} onClick={onSelectYear(y)}>
                  <StyledItem className="vb-flex-row-c-c" $selected={currentYear === y}>
                    {y}
                  </StyledItem>
                </Grid>
              ))
            }
          </Grid>
        }
        {
          openMonthSelection &&
          <Grid container spacing={2} sx={{ p: 1 }}>
            {
              CalendarHelper.MOTHS_LIST.map((m, i) => (
                <Grid key={m} item xs={6} onClick={onSelectMonth(i + 1)}>
                  <StyledItem className="vb-flex-row-c-c" $selected={(i + 1) === currentMonth}>
                    {m}
                  </StyledItem>
                </Grid>
              ))
            }
          </Grid>
        }
      </StyledScroll>
    </Paper>
  )
}

const StyledScroll = styled(ScrollContainer)<{ $width: number }>`
  max-height: 245px;
  width: ${p => p.$width}px;
`

const StyledItem = styled.div<{ $selected: boolean }>`
  padding: 5px;
  border-radius: 1rem;
  background-color: ${p => p.$selected ? 'var(--color-chosen-date)' : 'white'};
  cursor: pointer;
  
  &:hover {
    background-color: ${p => p.$selected ? '' : 'var(--color-gray-light)'};
  }
`

export default memo(Calendar)
