import React from 'react'
import ReactDOM from 'react-dom/client'
import { Buffer } from 'buffer'
import Amplify from 'aws-amplify'
import { ThemeProvider, createTheme } from '@mui/material'
import { ApolloClient, ApolloProvider, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'

import Application from './Application'
import awsConfig from './aws-exports'
import './i18n'
import { AuthHelper } from './helpers'
import env from './environment'

Amplify.configure(awsConfig)

const theme = createTheme({
  palette: {
    primary: {
      light: '#BDEAE2',
      main: '#68D0BD',
      dark: '#379D8B',
    },
    secondary: {
      main: '#A4A4A4',
      dark: '#666666',
      light: '#CCCCCC',
      contrastText: '#FFFFFF',
    },
  },
})

const httpLink = createUploadLink({
  uri: env.API_URL,
})

const httpLinkImageUpload = createUploadLink({
  uri: env.API_URL_IMAGE_UPLOAD,
})
const imageUploadLinkSplit = split(
  operation => operation.getContext().operation === 'imageUpload',
  httpLinkImageUpload,
  httpLink
)

const authLink = setContext(async (_, { headers }) => {
  try {
    const authType = localStorage.getItem('auth_type')
    const token = await getToken()
    const tokenType = authType == 'telegram' ? 'TelegramWeb' : 'Bearer'

    return {
      headers: {
        ...headers,
        authorization: token ? `${tokenType} ${token}` : '',
      }
    }
  } catch (e) {
    return headers
  }
})

const getToken = async () => {
  try {
    const user = await AuthHelper.getCurrentUser()
    const authType = localStorage.getItem('auth_type')

    if (authType === 'telegram') {
      const telegramUserDataHash = Buffer.from(JSON.stringify(user.data)).toString('base64')
      return !user.error ? telegramUserDataHash : ''
    } else {
      return !user.error ? user?.data?.signInUserSession?.idToken?.jwtToken : ''
    }

  } catch (e) {
    return ''
  }
}

const client = new ApolloClient({
  link: authLink.concat(imageUploadLinkSplit),
  cache: new InMemoryCache(),
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Application />
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
)
