import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnapshot } from 'valtio'

import { PageLayout, TableTemplate } from '@app/components'
import { ROUTES, TableSubscriptionKeyField, TableSubscriptionSortingFields } from '@app/constants'
import { TableHelper } from '@app/helpers'
import { useLazyQuery } from '@apollo/client'
import { GetSubscriptionsDocument, UserPermission } from '@app/graphql'
import { TableField } from '@app/types'
import { useGetPermissions } from '@app/hooks'
import { CurrentUserState } from '@app/storage'

interface SubscriptionListPageProps {}

const SubscriptionListPage: FC<SubscriptionListPageProps> = () => {
  const [ sortOptions, setSortOptions ] = useState<any>(null)
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ pageNumber, setPageNumber ] = useState(0)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const { id: currentUserId } = useSnapshot(CurrentUserState)
  const { checkPermission, loading: permsLoading } = useGetPermissions({ userId: currentUserId })
  const [
    getSubscriptions,
    { data: subscriptionData, loading: subscriptionLoading }
  ] = useLazyQuery(GetSubscriptionsDocument, { fetchPolicy: 'network-only' })

  useEffect(() => {
    getSubscriptions({
      variables: {
        ...getSort(),
        ...getPagination(),
      },
    })
  }, [])

  const getPagination = () => {
    const row = localStorage.getItem('per_page')
    const letLimit = Number(row) ?? rowsPerPage
    return { limit: letLimit, skip: pageNumber * rowsPerPage }
  }

  const getSort = (): { reverse?: boolean, sortKey?: string } => {
    // todo: set up sorting
    return {}
  }
  const onSort = (sort: any = {}) => {
    getSubscriptions({
      variables: {
        ...getSort(),
        ...getPagination(),
      }
    })
    setSortOptions(sort)
  }
  const onPaginate = (page: number, rowsPage: number) => {
    getSubscriptions({
      variables: {
        ...getSort(),
        limit: rowsPage,
        skip: page * rowsPage,
      },
    })
    setPageNumber(page)
    setRowsPerPage(rowsPage)
  }

  const columns = useMemo(
    () => TableHelper.parseSubscriptionFields(!checkPermission(UserPermission.EditSubs)),
    [ currentUserId, permsLoading ]
  )
  const rows = useMemo(() => {
    return subscriptionData?.subscriptions?.edges ?
      subscriptionData.subscriptions.edges.map(({ node }) => ({
        [TableField.id]: node?.id,
        [TableField.serviceName]: node?.service?.serviceName,
        [TableField.price]: node?.price?.price,
        [TableField.currency]: node?.price?.currency?.name,
        [TableField.duration]: (node?.serviceDuration?.duration || '0') + ' days',
      })) :
      []
  }, [ subscriptionData ])

  return (
    <PageLayout
      title={t('subscriptionList', { ns: 'pageTitles' })}
      loading={subscriptionLoading}
    >
      <TableTemplate
        columns={columns}
        rows={rows}
        fieldKey={TableSubscriptionKeyField}
        title={t('subscriptionList')}
        createText={t('buttonCreateSubscription')}
        totalAmount={subscriptionData?.subscriptions?.pageInfo?.totalCount}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        sortOptions={sortOptions}
        createRoute={checkPermission(UserPermission.AddSubs) ? ROUTES.subscriptionCreate.full : null}
        sortingFields={TableSubscriptionSortingFields}
        onSort={onSort}
        onPaginate={onPaginate}
      />
    </PageLayout>
  )
}

export default SubscriptionListPage
