import { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface DialogConfirmProps {
  open: boolean
  header?: string
  message: string
  onCancel: () => void
  onConfirm: () => void
}

const DialogConfirm: FC<DialogConfirmProps> = ({
  open,
  header,
  message,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation([ 'translation' ])

  return (
    <Dialog open={open} onClose={onCancel}>
      {
        header ?
          <DialogTitle>
            <div className="vb-flex-row-c-c">
              {header}
            </div>
          </DialogTitle> :
          null
      }
      <DialogContent>
        <DialogContentText className="vb-flex-row-c-c vb-pre">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant="contained" color="secondary" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button variant="contained" autoFocus onClick={onConfirm}>
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirm
