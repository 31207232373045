import { FC, useEffect, useState } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormHelperText,
  SxProps,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { useFormik } from 'formik'

import { GetCountriesDocument } from '@app/graphql'
import { ALL_VALUES } from '@app/constants'

interface CountrySelectProps {
  value?: string | string[]
  withAll?: boolean
  sxProps?: SxProps
  formik?: ReturnType<typeof useFormik<any>>
  fieldName?: string
  required?: boolean
  onChange?: (e: SelectChangeEvent) => void
  multiple?: boolean
}

const CountrySelect: FC<CountrySelectProps> = ({
  withAll,
  value,
  sxProps,
  formik,
  fieldName,
  required,
  onChange,
  multiple,
}) => {
  const { t } = useTranslation([ 'forms' ])
  const [ getCountries, { data: countriesData, loading: countriesLoading } ] = useLazyQuery(GetCountriesDocument)

  useEffect(() => {
    getCountries()
  }, [])

  const withError = formik ? formik.touched[fieldName] && Boolean(formik.errors[fieldName]) : false
  const helperText = formik ? formik.touched[fieldName] && t(formik.errors[fieldName] as string) : ''
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  return (
    <FormControl fullWidth error={withError} sx={sxProps} required={required}>
      <InputLabel id="country-filter">{t('countryLabel', { ns: 'forms' })}</InputLabel>
      <Select
        id={fieldName}
        name={fieldName}
        labelId="country-filter"
        label={t('countryLabel', { ns: 'forms' })}
        value={formik && fieldName ? formik.values[fieldName] : multiple ?  [ ...value as string[] ] : value}
        disabled={countriesLoading}
        error={withError}
        required={required}
        multiple={multiple}
        onChange={formik ? formik.handleChange : (onChange ? onChange : null)}
        onBlur={formik ? formik.handleBlur : null}
        renderValue={(selected: unknown) => {
          if(Array.isArray(selected)) {
            return countriesData?.countries?.filter(({ id }) => selected.includes(id))
              .map(({ name }) => name).join(', ')
          }
        }}
        MenuProps={MenuProps}
      >
        {
          withAll &&
          <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>
        }
        {
          countriesData?.countries?.map(({ id, name }) => (
            !multiple?
              <MenuItem key={id} value={id}>{name}</MenuItem>
              :(
                <MenuItem key={id} value={id}>
                  <Checkbox checked={value.includes(id)} />
                  <ListItemText primary={name} />
                </MenuItem>
              )
          ))
        }
      </Select>
      {
        helperText ?
          <FormHelperText>{helperText}</FormHelperText> :
          null
      }
    </FormControl>
  )
}

export default CountrySelect
