import { proxy } from 'valtio'

import { CurrentUserStateType } from '@app/types'

const initialState: CurrentUserStateType = {
  id: null,
  displayName: null,
  role: null,
  tipsterStatus: null,
  permChangeStatus: false,
  isAdmin: false,
  isTipster: false,
  channelsWhereUserIsTipster: []
}

export const CurrentUserState = proxy<CurrentUserStateType>(initialState)

export const updateCurrentUserState = (state: Partial<CurrentUserStateType>) => {
  if (state) {
    Object.keys(state).forEach(key => {
      CurrentUserState[key] = state[key]
    })
  }
}

export const resetCurrentUserState = () => updateCurrentUserState(initialState)
