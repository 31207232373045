import { useEffect, useState } from 'react'

export const useURLFromFile = (file: File) => {
  const [ imageSrc, setImageSrc ] = useState<string>(null)
  const [ fileName, setFileName ] = useState<string>(null)

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file)
      setImageSrc(objectUrl)
      setFileName(file?.name)


      return () => URL.revokeObjectURL(objectUrl)
    } else {
      setImageSrc(null)
    }
  }, [ file ])

  return { imageSrc, fileName }
}