import { FC, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import {
  GetAffiliateLinksDocument,
  AffilateLinkFilters,
  AffilateLinkStatus,
  SortMethod,
  AffilateLinkSort,
  AffilateLinkUpdtae,
  UpdateAffiliateLinkDocument,
} from '@app/graphql'
import { PageLayout, TableTemplate } from '@app/components'
import { CalendarHelper, TableHelper } from '@app/helpers'
import { ROUTES, TableAffiliateLinkKeyField, TableAffiliateLinksSortingFields } from '@app/constants'
import { TableChangeItemValue, TableField, TableFilterData, TableFilterFlags } from '@app/types'

type SortKeys = {
  [Property in keyof typeof TableAffiliateLinksSortingFields]?: SortMethod
}

interface AffiliateLinkListPageProps {}

const AffiliateLinkListPage: FC<AffiliateLinkListPageProps> = () => {
  const [ loading, setLoading ] = useState(false)
  const [ filterOptions, setFilterOptions ] = useState<TableFilterData>({})
  const [ sortOptions, setSortOptions ] = useState<SortKeys>({})
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [
    getLinks,
    { data: linksData, loading: linksLoading },
  ] = useLazyQuery(GetAffiliateLinksDocument, { fetchPolicy: 'network-only' })
  const [ updateLink ] = useMutation(UpdateAffiliateLinkDocument)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])

  useEffect(() => {
    getLinks({
      variables: {
        filter: prepareFilter(filterOptions),
        sort: prepareSort(sortOptions),
        ...getPagination(),
      },
    })
  }, [])

  const onSort = (sort: { [key: string]: SortMethod } = {}) => {
    getLinks({
      variables: {
        filter: prepareFilter(filterOptions),
        sort: prepareSort(sort),
        ...getPagination(),
      },
    })
    setSortOptions(sort)
  }
  const onFilter = (filter: TableFilterData = {}) => {
    getLinks({
      variables: {
        filter: prepareFilter(filter),
        sort: prepareSort(sortOptions),
        ...getPagination(),
      },
    })
    setFilterOptions(filter)
  }
  const onPaginate = (page: number, rowsPage: number) => {
    setPageNumber(page)
    setRowsPerPage(rowsPage)
  }
  const onChange: TableChangeItemValue = async (field, id, newValue) => {
    try {
      if (!field || !id) return
      setLoading(true)
      let input: AffilateLinkUpdtae = null
      switch (field) {
        case TableField.expirationDate: {
          if (CalendarHelper.isDate(newValue)) {
            input = { expirationDate: (newValue as Date).toISOString() }
          }
          break
        }
      }
      if (input) {
        const result = await updateLink({
          variables: {
            id,
            input,
          },
        })
        if (result.data.affilateLinkUpdate) {
          getLinks({
            variables: {
              filter: prepareFilter(filterOptions),
              sort: prepareSort(sortOptions),
              ...getPagination(),
            },
          })
        }
      }
    } finally {
      setLoading(false)
    }
  }
  const getPagination = () => {
    const row = localStorage.getItem('per_page')
    const letLimit = Number(row) ?? rowsPerPage
    return { limit: letLimit, skip: pageNumber * rowsPerPage }
  }

  const prepareFilter = (filter: TableFilterData): AffilateLinkFilters => ({
    status: filter?.status as AffilateLinkStatus || null,
  })
  const prepareSort = (sort: SortKeys): AffilateLinkSort => ({
    id: sort.id,
    internalName: sort.internalName,
    expirationDate: sort.expirationDate,
    channelName: sort.channelName,
    createdAt: sort.dateCreated,
  })

  const columns = useMemo(() => TableHelper.parseAffiliateLinkFields(), [])
  const rows = useMemo(() => {
    if (!linksData?.affiliateLinks?.affilateLinks) return []
    return linksData.affiliateLinks.affilateLinks.map(al => ({
      ...al,
      [TableField.channelName]: al.channel?.channelName,
      [TableField.registrations]: al.usersNumber || 0,
    }))
  }, [ linksData ])
  const filterFlags: TableFilterFlags = {
    status: true,
  }

  return (
    <PageLayout
      title={t('affiliateLinkList', { ns: 'pageTitles' })}
      loading={linksLoading || loading}
    >
      <TableTemplate
        columns={columns}
        rows={rows}
        fieldKey={TableAffiliateLinkKeyField}
        title={t('affiliateLinkList')}
        createText={t('buttonCreateAffiliateLink')}
        createRoute={ROUTES.affiliateLinkCreate.full}
        filterFlags={filterFlags}
        sortOptions={sortOptions}
        sortingFields={TableAffiliateLinksSortingFields}
        filterOptions={filterOptions}
        statuses={Object.values(AffilateLinkStatus)}
        totalAmount={linksData?.affiliateLinks?.count}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        onSort={onSort}
        onFilter={onFilter}
        onPaginate={onPaginate}
        onChange={onChange}
      />
    </PageLayout>
  )
}

export default AffiliateLinkListPage
