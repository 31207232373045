let devEnvironment: Partial<IEnvironment> = {}
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  try { devEnvironment = require('./environment.dev.ts').default } catch(e) { /**/ }
}

const environment = {
  API_URL: process.env.REACT_APP_API_URL || '',
  API_URL_IMAGE_UPLOAD: process.env.REACT_APP_API_URL_IMAGE_UPLOAD || '',
  AWS_PROJECT_REGION: process.env.REACT_APP_AWS_PROJECT_REGION || '',
  AWS_COGNITO_IDENTITY_POOL_ID: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID || '',
  AWS_COGNITO_REGION: process.env.REACT_APP_AWS_COGNITO_REGION || '',
  AWS_USER_POOLS_ID: process.env.REACT_APP_AWS_USER_POOLS_ID || '',
  AWS_USER_POOLS_WEB_CLIENT_ID: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID || '',
  AWS_OAUTH_DOMAIN: process.env.REACT_APP_AWS_OAUTH_DOMAIN || '',
  AWS_OAUTH_REDIRECT_SIGN_IN: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_IN || 'https://www.votebetting-admin.app/',
  AWS_OAUTH_REDIRECT_SIGN_OUT: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGN_OUT ||
  'https://www.votebetting-admin.app/',
  MAIN_APP_DOMAIN: process.env.REACT_APP_MAIN_APP_DOMAIN,
  TELEGRAM_BOT_ID: process.env.REACT_APP_TELEGRAM_BOT_ID || '',
  TELEGRAM_BOT_USERNAME: process.env.REACT_APP_TELEGRAM_BOT_USERNAME || '',
  VB_CLIENT_APP_DOMAIN: process.env.REACT_APP_VB_CLIENT_APP_DOMAIN
}

export type IEnvironment = typeof environment
export default { ...environment, ...devEnvironment }