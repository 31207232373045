import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Alert, Box, Snackbar, styled } from '@mui/material'
import {
  NOTIFICATION_ACTIVITY_NAMES_MAP,
  NOTIFICATION_MESSAGES_NAMES_MAP,
  NOTIFICATION_SUBSCRIPTIONS_NAMES_MAP,
  NOTIFICATION_SYSTEM_NAMES_MAP,
  NOTIFICATION_TIPS_NAMES_MAP
} from '@app/constants'
import {
  EntityNotificationSetting,
  GetNotificationSettingsDocument,
  NotificationSettingScope,
  UpdateNotificationSettingsDocument
} from '@app/graphql'
import { NotificationItemType } from '@app/types'
import NotificationList from './NotificationList'
import Loader from '@app/components/Loader'

interface NotificationTabProps {
  scope: NotificationSettingScope
  channelId?: string
}

const NotificationTab: FC<NotificationTabProps> = ({
  scope,
  channelId,
}) => {
  const [ message, setMessage ] = useState('')
  const [ errorOccured, setErrorOccured ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const [
    getNotificationSettings,
    { data: settingsData, loading: settingsLoading },
  ] = useLazyQuery(GetNotificationSettingsDocument, { fetchPolicy: 'network-only' })
  const [ updateNotification ] = useMutation(UpdateNotificationSettingsDocument)

  useEffect(() => {
    getNotificationSettings({ variables: { scope, entityId: channelId } })
  }, [])

  const onChangeNotification = async (notId: string, value: boolean) => {
    try {
      setLoading(true)
      const result = await updateNotification({
        variables: {
          id: notId,
          scope,
          value,
        },
      })
      if (!result.data.entityNotificationSettingsUpdate.id) throw new Error()
      setMessage(t('updatingSuccess'))
      getNotificationSettings({ variables: { scope, entityId: channelId } })
    } catch (e) {
      setErrorOccured(true)
      setMessage(t('updatingFailure'))
    } finally {
      setLoading(false)
    }
  }
  const onCloseDialogResult = () => {
    setMessage('')
    setErrorOccured(false)
  }

  const {
    systemNotifications,
    tipsNotifications,
    activityNotifications,
    messagesNotifications,
    subscriptionNotifications,
  } = useMemo(() => {
    const tipsNotifications: NotificationItemType[] = []
    const activityNotifications: NotificationItemType[] = []
    const subscriptionNotifications: NotificationItemType[] = []
    const messagesNotifications: NotificationItemType[] = []
    const systemNotifications: NotificationItemType[] = []

    const fillNotifications = (
      map: Map<string, string>,
      array: NotificationItemType[],
      not: Partial<EntityNotificationSetting>
    ) => {
      if (map.has(not?.notificationSetting?.name)) {
        array.push({
          id: not.id,
          label: not.notificationSetting.name,
          value: not.value,
          isEditable: not.isEditable,
        })
      }
    }

    if (settingsData?.entityNotificationSettingsByEntityId) {
      settingsData.entityNotificationSettingsByEntityId.forEach(not => {
        fillNotifications(NOTIFICATION_SYSTEM_NAMES_MAP, systemNotifications, not)
        fillNotifications(NOTIFICATION_TIPS_NAMES_MAP, tipsNotifications, not)
        fillNotifications(NOTIFICATION_ACTIVITY_NAMES_MAP, activityNotifications, not)
        fillNotifications(NOTIFICATION_SUBSCRIPTIONS_NAMES_MAP, subscriptionNotifications, not)
        fillNotifications(NOTIFICATION_MESSAGES_NAMES_MAP, messagesNotifications, not)
      })
    }

    return {
      systemNotifications,
      tipsNotifications,
      activityNotifications,
      subscriptionNotifications,
      messagesNotifications,
    }
  }, [ settingsData ])

  return (
    <>
      { (settingsLoading || loading) && <Loader fullScreen overlay/> }
      <StyledListBlock className="vb-font big w700 black" $isFirst>
        {t('systemNotifications').toUpperCase()}:
      </StyledListBlock>
      <NotificationList items={systemNotifications} onChange={onChangeNotification}/>
      <StyledListBlock className="vb-font big w700 black">
        {t('tipsNotifications').toUpperCase()}:
      </StyledListBlock>
      <NotificationList items={tipsNotifications} onChange={onChangeNotification}/>
      <StyledListBlock className="vb-font big w700 black">
        {t('activityNotifications').toUpperCase()}:
      </StyledListBlock>
      <NotificationList items={activityNotifications} onChange={onChangeNotification}/>
      <StyledListBlock className="vb-font big w700 black">
        {t('subscriptionNotifications').toUpperCase()}:
      </StyledListBlock>
      <NotificationList items={messagesNotifications} onChange={onChangeNotification}/>
      <StyledListBlock className="vb-font big w700 black">
        {t('messagesNotifications').toUpperCase()}:
      </StyledListBlock>
      <NotificationList items={subscriptionNotifications} onChange={onChangeNotification}/>
      <Box sx={{ mb: 4 }}/>

      <Snackbar
        open={!!message}
        autoHideDuration={6000}
        onClose={onCloseDialogResult}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={onCloseDialogResult}
          severity={errorOccured ? 'error' : 'success' }
          sx={{ width: '100%' }}
        >
          { message }
        </Alert>
      </Snackbar>
    </>
  )
}

const StyledListBlock = styled(Box)<{ $isFirst?: boolean }>`
  width: 100%;
  margin-top: 0.5rem;
  padding: 2rem 0 0 1rem;
  border-top:${p => p.$isFirst ? 'none' : '1px solid var(--color-gray-intermidiate)'};
`

export default NotificationTab
