import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

import { CognitoForgotPasswordResponse, CognitoUser } from '@app/types'
import { TipsterStatus, UserRoles } from '@app/graphql'
import env from '@app/environment'

export const logIn = async (username: string, password: string) => {
  try {
    const userInfo: CognitoUser = await Auth.signIn(username, password)
    return { data: userInfo, newPasswordRequired: userInfo.challengeName === 'NEW_PASSWORD_REQUIRED' }
  } catch (e) {
    return { error: e }
  }
}

export const getCurrentUser = async () => {
  try {
    const authType = localStorage.getItem('auth_type')
    if (authType === 'telegram') {
      return { data: JSON.parse(localStorage.getItem('telegram_user')) }
    } else {
      const userInfo = await Auth.currentAuthenticatedUser()
      return { data: userInfo }
    }
  } catch (e) {
    return { error: e }
  }
}

export const logOut = async () => {
  try {
    const authType = localStorage.getItem('auth_type')
    if (authType === 'telegram') {
      localStorage.removeItem('auth_type')
      localStorage.removeItem('telegram_user')
    } else {
      await Auth.signOut()
      return { success: true }
    }
  } catch (e) {
    return { error: e, success: false }
  }
}

export const sendCodeForgotPassword = async (username: string) => {
  try {
    const response: CognitoForgotPasswordResponse = await Auth.forgotPassword(username)

    return { success: Boolean(response?.CodeDeliveryDetails?.AttributeName) }
  } catch (e) {
    return { error: e }
  }
}

export const sendNewPasswordForgotPassword = async (username: string, verificationCode: string, password: string) => {
  try {
    const response: string = await Auth.forgotPasswordSubmit(username, verificationCode, password)

    return { success: response === 'SUCCESS' }
  } catch (e) {
    return { error: e }
  }
}

export const completeNewPassword = async (user: CognitoUser, password: string) => {
  try {
    const userData: CognitoUser = await Auth.completeNewPassword(user, password, { email: user.username })

    return { data: userData }
  } catch (e) {
    console.log('error: ', e)
    return { error: e }
  }
}

export const authByFacebook = async (queryParams = '') => {
  try {
    const credentials = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Facebook,
      customState: encodeURIComponent(queryParams),
    })
    localStorage.setItem('auth_type', 'cognito')
    return { data: credentials }
  } catch (e) {
    return { error: e }
  }
}

export const authByGoogle = async (queryParams = '') => {
  try {
    const credentials = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: encodeURIComponent(queryParams),
    })
    localStorage.setItem('auth_type', 'cognito')
    return { data: credentials }
  } catch (e) {
    return { error: e }
  }
}

export const authByTelegram = async () => {
  window['Telegram'].Login.auth({ bot_id: env.TELEGRAM_BOT_ID, request_access: true },
    async (response) => {
      if (!response) {
        return {
          error: 'The user is not authenticated'
        }
      }

      localStorage.setItem('auth_type', 'telegram')
      localStorage.setItem('telegram_user', JSON.stringify(response))
      window.location.reload()
    })
}

export const checkAllowedRole = (role: UserRoles, tipsterStatus?: TipsterStatus) => (
  role === UserRoles.Tipster && tipsterStatus === TipsterStatus.Active || role === UserRoles.Admin
)
