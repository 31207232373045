export const THIS_YEAR = Number(new Date().getFullYear())

export const THIS_MONTH = Number(new Date().getMonth()) + 1
export const DAY_MILLISECONDS = 24 * 60 * 60 * 1000
export const WEEK_DAYS_2_LETTERS = [ 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su' ]
export const WEEK_DAYS_3_LETTERS = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ]

export const MOTHS_LIST = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
const getShortMonthNames = () => MOTHS_LIST.map(name => name.substring(0,3))

export const CALENDAR_WEEKS = 6

export const zeroPad = (value, length) => {
  return `${value}`.padStart(length, '0')
}

export const beginningOfDay = (date: Date) => {
  if (!isDate(date)) return null

  return new Date(date.toDateString())
}

export const getYearsList = () => {
  const yearsList = []
  for (let i = THIS_YEAR; i >= THIS_YEAR - 100; i--) {
    yearsList.push(i)
  }
  return yearsList
}

export const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => {
  const months30 = [ 4, 6, 9, 11 ]
  const leapYear = year % 4 === 0
  return month === 2
    ? leapYear
      ? 29
      : 28
    : months30.includes(month)
      ? 30
      : 31
}

export const getMonthFirstDay = (month = THIS_MONTH, year = THIS_YEAR) => {
  return Number(new Date(`${year}-${zeroPad(month, 2)}-01`).getDay())
}

export const isDate = date => {
  const isDate = Object.prototype.toString.call(date) === '[object Date]'
  const isValidDate = date && !Number.isNaN(date.valueOf())

  return isDate && isValidDate
}

export const isSameMonth = (date, basedate = new Date()) => {
  if (!(isDate(date) && isDate(basedate))) return false
  const basedateMonth = Number(basedate.getMonth()) + 1
  const basedateYear = basedate.getFullYear()
  const dateMonth = Number(date.getMonth()) + 1
  const dateYear = date.getFullYear()
  return (Number(basedateMonth) === Number(dateMonth)) && (Number(basedateYear) === Number(dateYear))
}

export const isSameDay = (date, basedate = new Date()) => {
  if (!(isDate(date) && isDate(basedate))) return false
  const basedateDate = basedate.getDate()
  const basedateMonth = Number(basedate.getMonth()) + 1
  const basedateYear = basedate.getFullYear()
  const dateDate = date.getDate()
  const dateMonth = Number(date.getMonth()) + 1
  const dateYear = date.getFullYear()
  return (
    Number(basedateDate) === Number(dateDate)) &&
    (Number(basedateMonth) === Number(dateMonth)) &&
    (Number(basedateYear) === Number(dateYear)
    )
}

export const isSameTime = (date, basedate = new Date()) => {
  if (!isSameDay(date, basedate)) return false
  return getDateTimeAsString(date) === getDateTimeAsString(basedate)
}

export const isPast = (date: Date, endDate = new Date()) => {
  if (!isDate(date) || !isDate(endDate)) return false
  return !isSameDay(date, endDate) && date < endDate
}

export const isFuture = (date: Date, startDate = new Date()) => {
  if (!isDate(date) || !isDate(startDate)) return false
  return !isSameDay(date, startDate) && date > startDate
}

/*
 * return format <DD.MM.YY>
 */
export const getDateISO = (date = new Date) => {
  if (!isDate(date)) return null
  return [
    zeroPad(Number(date.getDate()), 2),
    zeroPad(Number(date.getMonth()) + 1, 2),
    date.getFullYear().toString().substring(2),
  ].join('.')
}

/*
 * return format <DD MonthName YYYY>
 */
export const getDateStringWithMonthName = (date) => {
  if (!isDate(date)) return ''
  return [
    zeroPad(Number(date.getDate()), 2),
    MOTHS_LIST[Number(date.getMonth())],
    date.getFullYear(),
  ].join(' ')
}

/*
 * return format <HH:MM>
 */
export const getDateTimeAsString = (date: Date): string => {
  if (!isDate(date)) return ''
  return `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`
}

/*
 * format for backend mutations
 */
export const getDayAsString = (date: Date): string => {
  if (isDate(date)) {
    return `${zeroPad(date.getMonth() + 1, 2)}/${zeroPad(date.getDate(), 2)}/${date.getFullYear()}`
  }
  return ''
}

export const getDateAsObjectWithStrings = (date: Date) => {
  if (!isDate(date)) return null
  return {
    day: zeroPad(date.getDate(), 2),
    month: getShortMonthNames()[date.getMonth()],
    weekday: WEEK_DAYS_3_LETTERS[date.getDay()],
  }
}

export const getPreviousMonth = (month: number, year: number) => {
  const prevMonth = (month > 1) ? month - 1 : 12
  const prevMonthYear = (month > 1) ? year : year - 1
  return { month: prevMonth, year: prevMonthYear }
}

export const getNextMonth = (month: number, year: number) => {
  const nextMonth = (month < 12) ? month + 1 : 1
  const nextMonthYear = (month < 12) ? year : year + 1
  return { month: nextMonth, year: nextMonthYear }
}

export const getMonthNameByNumber = (monthNumber: number) => MOTHS_LIST[monthNumber - 1]

export const getGridDates = (month = THIS_MONTH, year = THIS_YEAR): {
  year: number;
  month: string;
  day: string;
  date: Date;
}[] => {
  const prepareDate = (day: number, month: number, year: number) => {
    const preparingDate = beginningOfDay(new Date())
    preparingDate.setFullYear(year, month, day)
    return preparingDate
  }

  const monthDays = getMonthDays(month, year)
  const monthFirstDay = getMonthFirstDay(month, year) || 7

  const daysFromPrevMonth = monthFirstDay - 1
  const daysFromNextMonth = (CALENDAR_WEEKS * 7) - (daysFromPrevMonth + monthDays)

  const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(month, year)
  const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year)
  const prevMonthDays = getMonthDays(prevMonth, prevMonthYear)

  const prevMonthDates = [ ...new Array(daysFromPrevMonth) ].map((n, index) => {
    const day = index + 1 + (prevMonthDays - daysFromPrevMonth)
    return {
      year: prevMonthYear,
      month: zeroPad(prevMonth, 2),
      day: zeroPad(day, 2),
      date: prepareDate(day, prevMonth - 1, prevMonthYear),
    }
  })

  const thisMonthDates = [ ...new Array(monthDays) ].map((n, index) => {
    const day = index + 1
    return {
      year,
      month: zeroPad(month, 2),
      day: zeroPad(day, 2),
      date: prepareDate(day, month - 1, year),
    }
  })

  const nextMonthDates = [ ...new Array(daysFromNextMonth) ].map((n, index) => {
    const day = index + 1
    return {
      year: nextMonthYear,
      month: zeroPad(nextMonth, 2),
      day: zeroPad(day, 2),
      date: prepareDate(day, nextMonth - 1, nextMonthYear),
    }
  })

  return [ ...prevMonthDates, ...thisMonthDates, ...nextMonthDates ]
}
