import { ChangeEvent, FC, memo, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import {
  TableChangeItemValue,
  TableCustomFilterType,
  TableField,
  TableFieldDataType,
  TableFilterData,
  TableFilterFlags,
  TableSelectElements,
} from '@app/types'
import { TableCellHelper } from '@app/helpers'
import TableFilters from './TableFilters'
import TableHeader from './TableHeader'
import { ChannelSortInput, SortMethod, UserSort } from '@app/graphql'
import { useSnapshot } from 'valtio'
import { CurrentUserState } from '@app/storage'

interface TableTemplateProps {
  columns: TableFieldDataType[];
  rows: unknown[];
  selectElements?: TableSelectElements;
  fieldKey: TableField;
  filterFlags?: TableFilterFlags;
  title?: string;
  createText?: string;
  resetText?: string;
  createRoute?: string;
  sortOptions?: UserSort | ChannelSortInput;
  filterOptions?: TableFilterData;
  customFilters?: TableCustomFilterType[];
  statuses?: string[];
  totalAmount?: number;
  pageNumber?: number;
  rowsPerPage?: number;
  sortingFields?: { [key: string]: boolean };
  preventDoubleClick?: boolean;
  onPaginate?: (pageNumber: number, rowsPerPage: number) => void;
  onSort?: (sort: { [key: string]: SortMethod }) => void;
  onFilter?: (filter: TableFilterData) => void;
  onChange?: TableChangeItemValue;
  onReset?: () => void;
  channelsData?: any[];
  currencies?: any[];
  financeRows?: any;
  subscriptionStatus?: string;
  paymentOptions?: any[];
}

interface ClearFilterProps {
  clearFilter(): void;
}

const TableTemplate: FC<TableTemplateProps> = ({
  columns,
  rows = [],
  selectElements,
  fieldKey,
  filterFlags,
  title,
  createText,
  resetText,
  createRoute,
  sortOptions,
  filterOptions,
  customFilters,
  statuses,
  totalAmount,
  pageNumber,
  rowsPerPage,
  sortingFields,
  preventDoubleClick,
  onPaginate,
  onSort,
  onFilter,
  onChange,
  onReset,
  channelsData,
  currencies,
  financeRows,
  subscriptionStatus,
  paymentOptions,
}) => {
  const childRef = useRef<ClearFilterProps>(null)

  const navigate = useNavigate()
  const { t } = useTranslation([ 'translation', 'pageTitles', 'forms' ])
  const { isAdmin } = useSnapshot(CurrentUserState)

  const [ savedPageNumber, setSavedPaGeNumber ] = useState<number>(0)

  useEffect(() => {
    const pNumber = localStorage.getItem('per_page')
    if (pNumber) {
      setSavedPaGeNumber(Number(pNumber))
    }
  }, [])

  const onChangePage = (event: unknown, newPage: number) => {
    onPaginate(newPage, rowsPerPage)
  }
  const onChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      localStorage.setItem('per_page', event.target.value)
    }
    onPaginate(0, parseInt(event.target.value, 10))
  }
  const handleSort = (field: TableField) => () => {
    if (!onSort) return
    let sort
    if (sortOptions?.[field] === SortMethod.Asc) {
      sort = { [field]: SortMethod.Desc }
    } else {
      sort = { [field]: SortMethod.Asc }
    }
    onSort(sort)
  }
  const isSortedField = (field: TableField) =>
    field !== TableField.buttonEdit &&
    field !== TableField.logo &&
    field !== TableField.tipsterChannels &&
    (sortingFields ? sortingFields[field] : true)

  const showPreview = (row: any) => () => {
    if (isAdmin && !preventDoubleClick) navigate(row.id)
  }

  return (
    <Paper>
      {title || createText || createRoute ? (
        <TableHeader
          title={title}
          createText={createText}
          resetText={resetText}
          handleClose={onReset}
          createRoute={createRoute}
        />
      ) : null}

      {filterFlags && onFilter ? (
        <TableFilters
          flags={filterFlags}
          filterOptions={filterOptions}
          statuses={statuses}
          customFilters={customFilters}
          onFilter={onFilter}
          childRef={childRef}
          title={title}
          resetText={resetText}
          allChannels={channelsData}
          currencies={currencies}
          subscriptionStatus={subscriptionStatus}
          paymentOptions={paymentOptions}
        />
      ) : null}
      <TableContainer sx={{ overflow: 'visible' }}>
        <Table sx={{ minWidth: 750 }}>
          <TableHead>
            <TableRow>
              {columns.map((col) => {
                return (
                  <TableCell
                    key={col.field}
                    align="center"
                    sortDirection={
                      sortOptions?.[col.field]?.toLowerCase() || false
                    }
                  >
                    {isSortedField(col.field) ? (
                      <TableSortLabel
                        active={Boolean(sortOptions?.[col.field])}
                        direction={
                          sortOptions?.[col.field]?.toLowerCase() || 'asc'
                        }
                        onClick={handleSort(col.field)}
                      >
                        {t(col.label)}
                      </TableSortLabel>
                    ) : (
                      t(col.label)
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          {rows?.map((row) => (
            <TableRow
              key={row?.[fieldKey]}
              hover
              onDoubleClick={showPreview(row)}
            >
              {columns.map((col) => (
                <TableCell key={col.field} align="center">
                  {TableCellHelper.fillCell(col, row, {
                    selectElements,
                    onChange,
                  })}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {CurrentUserState.isTipster && title.toLowerCase() === 'financial' && (
            <TableBody>
              <br />
              <br />
              <br />
              <TableRow>
                <TableCell align="center">
                  <Typography variant="h6">Totals</Typography>
                </TableCell>
                <TableCell colSpan={2} />
                <TableCell colSpan={0} align="center">
                  <Typography variant="h6">{financeRows?.length}</Typography>
                </TableCell>
                <TableCell colSpan={3} />
                <TableCell align="center">
                  <Typography variant="h6">
                    {' '}
                    {financeRows &&
                      Number(financeRows[0]?.totalEarned).toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6">
                    {financeRows &&
                      Number(financeRows[0]?.totalComm).toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {onPaginate ? (
        <TablePagination
          rowsPerPageOptions={[ 5, 10, 25, 100 ]}
          component="div"
          count={totalAmount || rows?.length || 0}
          rowsPerPage={savedPageNumber}
          page={pageNumber}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      ) : null}
    </Paper>
  )
}

export default memo(TableTemplate)
