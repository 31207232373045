import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'

import { PageLayout, ViewPageTemplate } from '@app/components'
import { ROUTES, UserDataFields } from '@app/constants'
import { DeleteUserDocument, GetUserDocument } from '@app/graphql'

interface UserViewPageProps {}

const UserViewPage: FC<UserViewPageProps> = () => {
  const { userId } = useParams()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const [ getUser, { data: userData, loading: userLoading, error: userError } ] = useLazyQuery(GetUserDocument)
  const [ deleteUser ] = useMutation(DeleteUserDocument)

  useEffect(() => {
    getUser({ variables: { id: userId } })
  }, [])

  const onDelete = async () => {
    const result = await deleteUser({ variables: { id: userId } })
    return Boolean(result.data.userDelete)
  }

  const user = userData?.user ? { ...userData.user, country: userData.user.countryData?.name } : null

  if (!userId || userError) return <Navigate to={ROUTES.users.short} replace/>

  return (
    <PageLayout title={t('userViewPage', { ns: 'pageTitles' })} loading={userLoading}>
      <ViewPageTemplate
        editRoute={ROUTES.userEdit.full + '/' + userId}
        userData={user}
        dataFields={UserDataFields}
        routeAfterDeletion={ROUTES.users.short}
        onDeletePromise={onDelete}
      />
    </PageLayout>
  )
}

export default UserViewPage
