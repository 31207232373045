import { FC } from 'react'
import styled from 'styled-components'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import { LayoutMenuItems } from '@app/constants'
import { CurrentUserState } from '@app/storage'
import { UserRoles } from '@app/graphql'

interface PageLayoutHeaderProps {
  onLogout: () => void
}

const PageLayoutHeader: FC<PageLayoutHeaderProps> = ({ onLogout }) => {
  const { t } = useTranslation([ 'translation' ])
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { role } = useSnapshot(CurrentUserState)
  const paths = pathname?.split('/') || []

  return (
    <StyledHeader className="vb-flex-row-spb-c">
      <StyledMenu>
        {
          LayoutMenuItems.map(item => {
            if (item.onlyAdmin && role !== UserRoles.Admin) return null
            const onClick = () => navigate(item.route)

            return (
              <Button key={item.type} variant={('/' + paths[1]) === item.route ? 'outlined' : 'text'} onClick={onClick}>
                {t(item.text)}
              </Button>
            )
          })
        }
      </StyledMenu>
      <Button
        variant="outlined"
        color="warning"
        onClick={onLogout}
      >
        {t('buttonLogout')}
      </Button>
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  box-shadow: 0 4px 2px -2px gray;
  background-color: white;
  z-index: 1000;
`

const StyledMenu = styled.div``

export default PageLayoutHeader