import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { ROUTES } from '@app/constants'
import AdvertCreatePage from './AdvertCreatePage'
import AdvertListPage from './AdvertListPage'
import AdvertViewPage from './AdvertViewPage'

interface AdvertRoutesProps {}

const AdvertRoutes: FC<AdvertRoutesProps> = () => (
  <Routes>
    <Route index element={<AdvertListPage/>}/>
    <Route path={ROUTES.advertEdit.short} element={<AdvertCreatePage editMode/>}/>
    <Route path={ROUTES.advertCreate.short} element={<AdvertCreatePage/>}/>
    <Route path={ROUTES.advertViewShort} element={<AdvertViewPage/>}/>
  </Routes>
)

export default AdvertRoutes
