import { FC } from 'react'
import { CircularProgress } from '@mui/material'
import styled from 'styled-components'

interface LoaderProps {
  fullScreen?: boolean
  overlay?: boolean;
}

const Loader: FC<LoaderProps> = ({ fullScreen, overlay }) => (
  <StyledLoaderContainer className="vb-flex-row-c-c" $fullScreen={fullScreen} $overlay={overlay}>
    <CircularProgress/>
  </StyledLoaderContainer>
)

const StyledLoaderContainer = styled.div<{ $fullScreen: boolean, $overlay: boolean }>`
  width: ${p => p.$fullScreen ? '100vw' : '100%'};
  height: ${p => p.$fullScreen ? '100vh' : '100%'};
  ${p => p.$overlay && `
    background: rgba(0,0,0,.05);
    position: fixed;
  `}
`

export default Loader
