import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import FinancialListPage from './FinancialListPage'

interface FinancialRoutesProps {}

const FinancialRoutes: FC<FinancialRoutesProps> = () => (
  <Routes>
    <Route index element={<FinancialListPage/>}/>
  </Routes>
)

export default FinancialRoutes
