import { FC } from 'react'
import {
  FormControl,
  SxProps,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useFormik } from 'formik'

interface FormikCheckboxProps {
  fieldName: string
  formik: ReturnType<typeof useFormik<any>>
  label: string
  sxProps?: SxProps
  disabled?: boolean
}

const FormikCheckbox: FC<FormikCheckboxProps> = ({
  fieldName,
  formik,
  label,
  sxProps,
  disabled,
}) => {
  const { t } = useTranslation([ 'translation', 'forms' ])

  if (!fieldName || !formik) return null

  return (
    <FormControl
      fullWidth
      sx={sxProps}
    >
      <FormControlLabel
        label={t(label)}
        control={
          <Checkbox
            name={fieldName}
            checked={formik.values[fieldName]}
            disabled={disabled}
            onChange={formik.handleChange}
          />
        }
      />
    </FormControl>
  )
}

export default FormikCheckbox
