import { FC, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import {
  ChannelStatus,
  GetFinancialsDocument,
  TipsterStatus,
  SubsType,
  Payments,
  SetFinancialPaidDocument,
  FinancialSortInput,
  SortMethod,
  // YesNoType,
  // PaymentTypeOption,
  GetCurrenciesDocument,
  CurrencyNodeEdge,
  UserRoles,
  GetChannelsDocument,
  FetchSubscriptionsDocument,
  GetTipsterAvailablePaymentsDocument,
  GetTipsterAvailablePaymentsWithPaymentTypeDocument,
  GetUsersByIdsDocument,
  GetChannelByIdDocument,
  FinancialFilterInputs,
  GetTipsterCurrenciesDocument,
  GetTipsterChannelsDocument,
} from '@app/graphql'
import { PageLayout, TableTemplate } from '@app/components'
import { TableHelper } from '@app/helpers'
import {
  DisabledCellButton,
  TableFinancialKeyField,
  TableFinancialSortingFields,
} from '@app/constants'
import {
  PaymentTypeOption,
  TableCustomFilterType,
  TableField,
  TableFilterData,
  TableFilterFlags,
} from '@app/types'
import { useGetCountryById, useLoadUsers } from '@app/hooks'
import { CurrentUserState } from '@app/storage'
import { useSnapshot } from 'valtio'

type SortInput = Omit<
  FinancialSortInput,
  'channelCountry' | 'tipsterCountry'
> & {
  countryChannel?: SortMethod;
  countryTipster?: SortMethod;
};

interface FinancialListPageProps {}

const FinancialListPage: FC<FinancialListPageProps> = () => {
  const [ loading, setLoading ] = useState(false)
  const [ filterOptions, setFilterOptions ] = useState<TableFilterData>({})
  const [ sortOptions, setSortOptions ] = useState<SortInput>({})
  const [ rowsPerPage, setRowsPerPage ] = useState(10)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ payOptions, setPayOptions ] = useState([])
  const {
    loading: countriesLoading,
    getCountryById,
    countriesSelect,
  } = useGetCountryById()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const [ getFinancial, { data: financialData, loading: financialLoading } ] =
    useLazyQuery(GetFinancialsDocument, { fetchPolicy: 'network-only' })

  const [
    fetchServiceSubscriptions,
    { data: serviceSubscriptionData, loading: serviceSubcriptionLoading },
  ] = useLazyQuery(FetchSubscriptionsDocument, { fetchPolicy: 'network-only' })

  const [
    getTipsterAvailablePayments ] = useLazyQuery(GetTipsterAvailablePaymentsDocument, { fetchPolicy: 'network-only' })

  const [ getTipsterAvailablePaymentsWithType ] =
  useLazyQuery(GetTipsterAvailablePaymentsWithPaymentTypeDocument, { fetchPolicy: 'network-only' })

  const [ getCurrencies, { data: currenciesData, loading: currenciesLoading } ] =
    useLazyQuery(GetCurrenciesDocument)

  const [ getTipsterCurrencies, { data: tipsterCurrencies } ] = useLazyQuery(GetTipsterCurrenciesDocument)

  const [ getChannels, { data: channelsData, loading: channelLoading } ] =
    useLazyQuery(GetTipsterChannelsDocument, { fetchPolicy: 'no-cache' })

  const [ getUsersByIds ] = useLazyQuery(GetUsersByIdsDocument, {
    fetchPolicy: 'network-only',
  })

  const [ setFinancialPaid ] = useMutation(SetFinancialPaidDocument)
  const { id: userId } = useSnapshot(CurrentUserState)
  const { isAdmin } = useSnapshot(CurrentUserState)

  useEffect(() => {
    if(!filterOptions.paymentType){
      fetchServiceSubscriptions({
        variables: {
          filter: prepareFilter(filterOptions),
          ...getPagination(),
        },
      })
    }
    getChannels({
      variables: {
        userId: '2'
      },
    })
    getTipsterAvailablePayments({
      variables: {
        tipsterId: '2',
        // tipsterId: CurrentUserState.id,
        ...getPagination(),
      },
    })
    getTipsterCurrencies({
      variables: {
        tipsterId: '2',
        // tipsterId: CurrentUserState.id,
        ...getPagination(),
      },
    })
    if(filterOptions.paymentType){
      const makeRequest =async  () =>{
        const { data } = await getTipsterAvailablePaymentsWithType({
          variables: {
            isAutoActivated: filterOptions.paymentType.toLowerCase() == 'automatic' ? true : false,
            tipsterId: '2',
            // tipsterId: CurrentUserState.id,
            ...getPagination(),
          },
        })
        setPayOptions(data.tipsterAvailablePaymentsWithPaymentType.edges)
      }
      makeRequest()
    }
    console.log('=======', filterOptions.paymentType)
    //GetTipsterAvailablePayments
  }, [ filterOptions, pageNumber, rowsPerPage, sortOptions ])

  useEffect(() => {

    const makeRequest = async()=>{
      const { data } = await getTipsterAvailablePayments({
        variables: {
          tipsterId: '2',
          // tipsterId: CurrentUserState.id,
          ...getPagination(),
        },
      })
      setPayOptions(data.tipsterAvailablePayments.edges)

    }
    makeRequest()
  }, [])
  useEffect(() => {
    getFinancial({
      variables: {
        filter: prepareFilter(filterOptions),
        sort: prepareSort(sortOptions),
        ...getPagination(),
      },
    })
  }, [ filterOptions, pageNumber, rowsPerPage, sortOptions ])

  useEffect(() => {
    getCurrencies({ variables: { limit: 1000, skip: 0 } })
    fetchServiceSubscriptions({
      variables: {
        limit: 1000,
      },
    })
  }, [ currenciesData ])

  const onFilter = (filter: TableFilterData = {}) => setFilterOptions(filter)
  const onPaginate = (page: number, rowsPage: number) => {
    setPageNumber(page)
    setRowsPerPage(rowsPage)
  }
  const onSort = (sort: SortInput) => setSortOptions(sort)
  const getPagination = () => ({
    limit: rowsPerPage,
    skip: pageNumber * rowsPerPage,
  })
  const prepareFilter = (filter: {
    [key: string]: unknown;
  }): FinancialFilterInputs => ({
    ...filter,
    tipsterId: isAdmin ? null : userId,
  })
  const prepareSort = (sort: SortInput): FinancialSortInput => {
    if (sort.countryChannel) return { channelCountry: sort.countryChannel }
    if (sort.countryTipster) return { tipsterCountry: sort.countryTipster }
    return sort
  }
  const onPaid =
    (channelId: number, subAuto: string, tipsterId: number) => async () => {
      try {
        setLoading(true)
        const result = await setFinancialPaid({
          variables: {
            channelId,
            lastPayment: subAuto,
            tipsterId,
          },
        })
        if (result.data.setPaid.id) {
          await getFinancial({
            variables: {
              filter: prepareFilter(filterOptions),
              ...getPagination(),
            },
          })
        }
      } finally {
        setLoading(false)
      }
    }

  const getSubscriptionAutomaticAmount = (value: string) => {
    return String(value)
      .split('')
      .reduce((acc, curr) => {
        if (!Number(curr)) {
          return acc
        }
        return acc + Number(curr)
      }, 0)
  }

  const columns = useMemo(() => TableHelper.parseFinancialFields(isAdmin), [])
  const newcolumns = useMemo(
    () => TableHelper.parseNewFinancialFields(isAdmin),
    []
  )
  const rows = useMemo(() => {
    if (!financialData?.getFinancials?.financials) return []
    return financialData.getFinancials.financials.map((finance) => {
      return {
        ...finance,
        countryChannel: getCountryById(finance.countryChannel),
        countryTipster: getCountryById(finance.countryTipster),
        [TableField.buttonPaid]:
          getSubscriptionAutomaticAmount(finance.subscriptionAutomatic) > 0
            ? onPaid(
              Number(finance.channelId),
              finance.subscriptionAutomatic,
              Number(finance.tipsterId)
            )
            : DisabledCellButton,
        [TableField.buttonUndoPaid]: 'gello',
      }
    })
  }, [ financialData ])

  const financeRows = useMemo(() => {
    if (!serviceSubscriptionData?.fetchServiceSubscriptions?.edges) return []

    // Calculate the sum of amounts
    const totalAmount =
      serviceSubscriptionData.fetchServiceSubscriptions.edges.reduce(
        (total, finance) =>
          total + Number(finance.node.subscriptionPrice),
        0
      )

    return serviceSubscriptionData.fetchServiceSubscriptions.edges.map(
      (finance) => {
        console.log('=======', finance)
        const amount = Number(
          finance?.node?.subscriptionPrice
        )
        const commission = amount - amount * 0.3
        const earned = amount - amount * 0.7

        return {
          id: finance.node.id,
          user: getUsersByIds({
            variables: {
              userIds: [ finance?.node?.tipsterId?.toString() ],
            },
          }),
          userDisplayName: finance?.node?.userDisplayName,
          tipster: finance?.node?.subscription.service.tipsterName,
          channel: finance?.node?.subscription.service.channelName,
          subscription: `${finance.node.serviceName}`,
          amount: `${amount}`,
          currency: `${finance.node.subscriptionCurrencyCode}`,
          expiration: `${finance.node.expiration}`,
          commission: Number(`${Number(commission).toFixed(2)}`),
          earned: Number(`${Number(earned).toFixed(2)}`),
          settled: 'NO',
          totalComm: Number(totalAmount * 0.3).toFixed(2),
          totalEarned: Number(totalAmount * 0.7).toFixed(2),
        }
      }
    )
  }, [ serviceSubscriptionData ])

  const adminFlag: TableFilterFlags = {
    withCustom: true,
  }
  const tipsterFlags: TableFilterFlags = {
    // withCustom: true,
    period: true,
    channel: true,
    dateRangeFilter: true,
    search: true,
    subscriptionStatus: true,
    subscriptionType: true,
    currency: true,
    paymentType: true,
    paymentMethod: true,
    settled: true
  }
  const adminFilter: TableCustomFilterType[] = [
    {
      field: TableField.countryTipster,
      label: t('tipsterCountry'),
      withAll: true,
      elements: countriesSelect,
    },
    {
      field: TableField.subsType,
      label: t('subscriptionType'),
      withAll: true,
      elements: Object.values(SubsType).map((s) => ({ value: s })),
    },
    {
      field: TableField.paymentOption,
      label: t('paymentOption'),
      withAll: true,
      elements: Object.values(Payments).map((p) => ({ value: p })),
    },
  ]

  const customFilters: TableCustomFilterType[] = [
    {
      field: TableField.channelStatus,
      label: t('channelStatus'),
      withAll: true,
      elements: Object.values(ChannelStatus).map((c) => ({ value: c })),
    },
    {
      field: TableField.tipsterStatus,
      label: t('tipsterStatus'),
      withAll: true,
      elements: Object.values(TipsterStatus).map((t) => ({ value: t })),
    },
    {
      field: TableField.countryChannel,
      label: t('channelCountry'),
      withAll: true,
      elements: countriesSelect,
    },
    {
      field: TableField.countryTipster,
      label: t('tipsterCountry'),
      withAll: true,
      elements: countriesSelect,
    },
    {
      field: TableField.subsType,
      label: t('subscriptionType'),
      withAll: true,
      elements: Object.values(SubsType).map((s) => ({ value: s })),
    },
    {
      field: TableField.paymentOption,
      label: t('paymentOption'),
      withAll: true,
      elements: Object.values(PaymentTypeOption).map((s) => ({ value: s })),
    },
  ]

  return (
    <PageLayout
      title={t('financial', { ns: 'pageTitles' })}
      loading={
        financialLoading ||
        countriesLoading ||
        loading ||
        serviceSubcriptionLoading
      }
    >
      <TableTemplate
        // columns={columns}
        // rows={rows}
        columns={isAdmin ? columns : newcolumns}
        rows={isAdmin ? rows : financeRows}
        fieldKey={TableFinancialKeyField}
        title={t('financial')}
        resetText={t('buttonReset')}
        createText={null}
        createRoute={null}
        sortOptions={sortOptions}
        sortingFields={TableFinancialSortingFields}
        filterFlags={ isAdmin ? adminFlag : tipsterFlags}
        filterOptions={filterOptions}
        totalAmount={financialData?.getFinancials?.count}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        customFilters={isAdmin ? adminFilter : customFilters}
        preventDoubleClick
        onFilter={onFilter}
        onPaginate={onPaginate}
        onSort={onSort}
        currencies={tipsterCurrencies?.tipsterCurrencies?.edges}
        channelsData={channelsData?.userChannels}
        financeRows={financeRows}
        paymentOptions={payOptions}
      />
    </PageLayout>
  )
}

export default FinancialListPage
