import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import * as yup from 'yup'

import { FormElementCreation, PageLayout } from '@app/components'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  CreateServiceDocument,
  GetChannelsWithMinDataDocument,
  GetServiceByIdDocument,
  GetUsersWithMinDataDocument,
  ServiceCreate,
  ServiceStatus,
  ServiceUpdate,
  SortMethod,
  UpdateServiceDocument,
  UploadImageDocument,
  UserRoles
} from '@app/graphql'
import { CreateServiceValues, FormFieldDataType, FormFieldType } from '@app/types'
import { ROUTES } from '@app/constants'
import { DialogResult } from '@app/ui'
import { useCallbackPrompt } from '@app/hooks'

interface ServiceCreatePageProps {
  editMode?: boolean
}

const internalNameField = 'internalName'
const titleField = 'title'
const subtitleField = 'subTitle'
const CTATitleField = 'ctaTitle'
const CTASubtitleField = 'ctaSubTitle'
const paymentOptionField = 'paymentMethods'
const autoRenewalField = 'autoRenewal'
const expirationAlertField = 'expirationAlert'
const statusField = 'status'
const tipsterField = 'tipsterId'
const channelField = 'channelId'
const contactField = 'email'
const dateStartField = 'dateStart'
const infoField = 'info'
const imageField = 'imageFile'

const ServiceCreatePage: FC<ServiceCreatePageProps> = ({ editMode }) => {
  const [ blockLeaving, setBlockLeaving ] = useState(true)
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ loading, setLoading ] = useState(false)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const navigate = useNavigate()
  const { serviceId } = useParams()
  const [
    getService,
    { data: serviceData, loading: serviceLoading },
  ] = useLazyQuery(GetServiceByIdDocument, { fetchPolicy: 'network-only' })
  const [
    getTipsters,
    { data: tipstersData, loading: tipstersLoading }
  ] = useLazyQuery(GetUsersWithMinDataDocument)
  const [
    getChannels,
    { data: channelsData, loading: channelsLoading },
  ] = useLazyQuery(GetChannelsWithMinDataDocument)
  const [ uploadImage ] = useMutation(UploadImageDocument, { context: { operation: 'imageUpload' } })
  const [ createService ] = useMutation(CreateServiceDocument)
  const [ updateService ] = useMutation(UpdateServiceDocument)
  const { dialog } = useCallbackPrompt(blockLeaving)

  useEffect(() => {
    if (editMode) getService({ variables: { id: serviceId } })
    getTipsters({
      variables: {
        filter: { role: UserRoles.Tipster },
        skip: 0,
        limit: 10000,
        sort: { displayName: SortMethod.Asc }
      },
    })
    getChannels({
      variables: {
        filter: {},
        sort: { channelName: SortMethod.Asc },
      },
    })
  }, [])

  const onCancel = () => navigate(-1)
  const onSubmit = async (values: CreateServiceValues) => {
    try {
      setLoading(true)
      setBlockLeaving(false)
      let success
      let path
      if (values.imageFile) {
        const uploadResult = await uploadImage({ variables: { image: values.imageFile } })
        path = uploadResult.data.imageUpload
      }
      const { internalName } = values
      const v = { ...values }
      delete v.internalName
      delete v.imageFile
      const input: ServiceCreate = {
        ...v,
        serviceName: internalName,
        paymentMethods: [],
        status: v.status as ServiceStatus,
        imageLink: path,
      }
      if (editMode) {
        const result = await updateService({
          variables: {
            id: serviceId,
            input: input as ServiceUpdate,
          },
        })
        success = result.data.serviceUpdate
      } else {
        const result = await createService({ variables: { input } })
        success = Boolean(result.data.serviceCreate.id)
      }

      if (!success) throw new Error()
      setSuccessMessage(editMode ? t('updatingSuccess') : t('creationSuccess'))
    } catch (e) {
      setBlockLeaving(true)
      setErrorMessage(editMode ? t('updatingFailure') : t('creationFailure'))
    } finally {
      setLoading(false)
    }
  }
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(ROUTES.services.short)
    }
  }

  const pageTitle = editMode ? t('editService', { ns: 'pageTitles' }) :
    t('createService', { ns: 'pageTitles' })
  const title = editMode ? t('editService') : t('createNewService')
  const fields: FormFieldDataType[] = [
    {
      name: internalNameField,
      label: t('internalName'),
      type: FormFieldType.text,
      initValue: serviceData?.service?.internalName || '',
      validation: yup.string().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: imageField,
      label: t('uploadImage'),
      type: FormFieldType.image,
      initValue: null,
      validation: yup.mixed(),
      required: true,
      image: {
        link: serviceData?.service?.imageLink,
        alt: 'Service Image',
        width: 360,
        height: 280,
      },
    },
    {
      name: titleField,
      label: t('title'),
      type: FormFieldType.text,
      initValue: serviceData?.service?.title || '',
      validation: yup.string().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: subtitleField,
      label: t('subtitle'),
      type: FormFieldType.text,
      initValue: serviceData?.service?.subTitle || '',
      validation: yup.string().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: CTATitleField,
      label: t('CTATitle'),
      type: FormFieldType.text,
      initValue: serviceData?.service?.ctaTitle || '',
      validation: yup.string().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: CTASubtitleField,
      label: t('CTASubtitle'),
      type: FormFieldType.text,
      initValue: serviceData?.service?.ctaSubTitle || '',
      validation: yup.string().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: dateStartField,
      label: t('dateStart'),
      type: FormFieldType.datepicker,
      initValue: new Date(serviceData?.service?.dateStart || new Date()),
      validation: yup.date().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: paymentOptionField,
      label: t('paymentOption'),
      type: FormFieldType.select,
      elements: [],
      initValue: serviceData?.service?.paymentMethods || [],
      validation: yup.array().of(yup.string()).min(1, t('requiredError', { ns: 'forms' })),
      required: true,
      multiple: true,
    },
    {
      name: autoRenewalField,
      label: t('autoRenewal'),
      type: FormFieldType.checkbox,
      initValue: serviceData?.service?.autoRenewal || false,
      validation: yup.boolean(),
    },
    {
      name: expirationAlertField,
      label: t('expirationAlert'),
      type: FormFieldType.checkbox,
      initValue: serviceData?.service?.expirationAlert || false,
      validation: yup.boolean(),
    },
    {
      name: statusField,
      label: t('status'),
      type: FormFieldType.select,
      elements: Object.values(ServiceStatus).map(s => ({ value: s })),
      initValue: serviceData?.service?.status || ServiceStatus.Active,
      validation: yup.string().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: tipsterField,
      label: t('tipster'),
      type: FormFieldType.select,
      elements: tipstersData?.users?.users || [],
      initValue: serviceData?.service?.tipsterId || '',
      validation: yup.string().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: channelField,
      label: t('channel'),
      type: FormFieldType.select,
      elements: channelsData?.channels?.channels || [],
      initValue: serviceData?.service?.channelId || '',
      validation: yup.string().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
    {
      name: infoField,
      label: t('info'),
      type: FormFieldType.textEditor,
      initValue: serviceData?.service?.info || '',
      validation: yup.string().max(250, t('tooLongTextError', { ns: 'forms' })),
    },
    {
      name: contactField,
      label: t('contact'),
      type: FormFieldType.text,
      initValue: serviceData?.service?.email || '',
      validation: yup.string().required(t('requiredError', { ns: 'forms' })),
      required: true,
    },
  ]

  return (
    <PageLayout
      title={pageTitle}
      loading={loading || serviceLoading || tipstersLoading || channelsLoading}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" component="h4" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ width: '70%' }}>
          <FormElementCreation
            fields={fields}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </Box>
      </Box>

      {/* DIALOG */}
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
      { dialog }
    </PageLayout>
  )
}

export default ServiceCreatePage
