import { FC, useEffect, useState } from 'react'
import { Box, Button, styled, Typography } from '@mui/material'
import { Facebook, Telegram } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { PageLayout } from '@app/components'
import { GoogleIcon } from '@app/ui'
import { AuthHelper } from '@app/helpers'
import { useSnapshot } from 'valtio'
import { CurrentUserState } from '@app/storage'
import { TipsterStatus, UserRoles } from '@app/graphql'
import TelegramLoginButton from './components/TelegramLoginButton'
import env from '@app/environment'

interface SimplifiedLoginPageProps {
}

const SimplifiedLoginPage: FC<SimplifiedLoginPageProps> = () => {
  const telegramBotName = env.TELEGRAM_BOT_USERNAME
  const [ loading, setLoading ] = useState(true)
  const { id, role, tipsterStatus } = useSnapshot(CurrentUserState)
  const { t } = useTranslation([ 'translation', 'forms', 'pageTitles' ])

  useEffect(() => {
    const load = async () => {
      try {
        if (id && role && !AuthHelper.checkAllowedRole(role as UserRoles, tipsterStatus as TipsterStatus)) {
          await AuthHelper.logOut()
        }
      } finally {
        setLoading(false)
      }
    }
    load()
  }, [])

  const onClickFacebookLogin = () => AuthHelper.authByFacebook()
  const onClickGoogleLogin = () => AuthHelper.authByGoogle()
  const handleTelegramResponse = () => AuthHelper.authByTelegram()

  return (
    <PageLayout title={t('login', { ns: 'pageTitles' })} hideHeader loading={loading}>
      <StyledContent className="vb-flex-column-fs-c">
        <StyledLogo className="vb-flex-row-c-c">
          <img className="logo-image" src="/assets/images/logotype-entry.png" alt={t('logo')}/>
        </StyledLogo>
        <Box>
          <Typography variant="h5" component="h5" sx={{ mb: 1 }}>
            {t('onlyTipstersAdmins')}
          </Typography>
        </Box>
        <StyledButton
          className="facebook"
          variant="contained"
          size="large"
          onClick={onClickFacebookLogin}
        >
          <div className="facebook-icon vb-flex-row-c-c">
            <Facebook/>
          </div>
          {t('facebook')}
        </StyledButton>
        <StyledButton
          variant="contained"
          size="large"
          onClick={onClickGoogleLogin}
        >
          <GoogleIcon/>
          {t('google')}
        </StyledButton>
        <TelegramLoginButton
          classname="logotype" cornerRadius={0}
          dataOnauth={handleTelegramResponse}
          botName={telegramBotName}
        >
          <StyledButton
            variant="contained"
            size="large"
            onClick={handleTelegramResponse}
          >
            <Telegram/>
            {t('telegram')}
          </StyledButton>
        </TelegramLoginButton>
      </StyledContent>
    </PageLayout>
  )
}

const StyledContent = styled(Box)`
  width: 100%;
  min-height: 100vh;
  padding-top: 10%;
  background-color: var(--color-screen-background-light);
`

const StyledButton = styled(Button)`
  background-color: #fff;
  width: 15rem;
  margin-bottom: 1rem;
  
  &:hover {
    background-color: #999;
  }
  .facebook-icon {
    color: #4267B2;
  }
`

const StyledLogo = styled(Box)`
  padding-bottom: 1rem;
  
  .logo-image {
    max-width: 300px;
    object-fit: contain;
  }
`

export default SimplifiedLoginPage
