import { FC, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'

import { DeleteUserDocument, GetUserDocument } from '@app/graphql'
import { ROUTES, SuperadminDataFields } from '@app/constants'
import { PageLayout, ViewPageTemplate } from '@app/components'

interface SuperadminViewPageProps {}

const SuperadminViewPage: FC<SuperadminViewPageProps> = () => {
  const { superadminId } = useParams()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const [
    getAdmin,
    { data: adminData, loading: adminLoading, error: adminError }
  ] = useLazyQuery(GetUserDocument)
  const [ deleteAdmin ] = useMutation(DeleteUserDocument)

  useEffect(() => {
    getAdmin({ variables: { id: superadminId } })
  }, [])

  const onDelete = async () => {
    const result = await deleteAdmin({ variables: { id: superadminId } })
    return Boolean(result.data.userDelete)
  }

  const admin = adminData?.user ? { ...adminData.user, country: adminData.user.countryData?.name } : null

  if (!superadminId || adminError) return <Navigate to={ROUTES.superadmins.short} replace/>

  return (
    <PageLayout title={t('superadminViewPage', { ns: 'pageTitles' })} loading={adminLoading}>
      <ViewPageTemplate
        editRoute={ROUTES.superadminEdit.full + '/' + superadminId}
        userData={admin}
        dataFields={SuperadminDataFields}
        routeAfterDeletion={ROUTES.superadmins.short}
        onDeletePromise={onDelete}
      />
    </PageLayout>
  )
}

export default SuperadminViewPage