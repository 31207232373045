import { UserPermission, UserRoles } from '@app/graphql'
import { CurrentUserPermissions } from '@app/types'

export const checkPermissions = (perms: UserPermission[], role: UserRoles): CurrentUserPermissions => {
  const userPerms: CurrentUserPermissions = {
    permChangeStatus: role === UserRoles.Admin,
  }

  perms.forEach(p => {
    switch (p) {
      case UserPermission.ChangeUserStatus: {
        userPerms.permChangeStatus = true
      }
    }
  })

  return userPerms
}
