const root = '/'
const login = 'login'
const recovery = 'recovery'
const superadmins = 'superadmins'
const edit = 'edit'
const create = 'create'
const superadminIdParam = ':superadminId'
const users = 'users'
const withSubs = 'with-subs'
const userIdParam = ':userId'
const tipsters = 'tipsters'
const tipsterIdParam = ':tipsterId'
const advert = 'advert'
const advertIdParam = ':advertId'
const channel = 'channel'
const channelIdParam = ':channelId'
const services = 'services'
const serviceIdParam = ':serviceId'
const notifications = 'notifications'
const affiliateLinks = 'affiliate-links'
const affiliateLinkIdParam = ':affiliateLinkId'
const analytics = 'analytics'
const subscriptions = 'subscriptions'
const subscriptionIdParam = ':subscriptionId'
const freeTrial = 'free-trial'
const financial = 'financial'
const tipsterInvite = 'tipster-invite'

export const ROUTES = {
  root: `${root}`,
  login: `${root}${login}`,
  recovery: `${root}${recovery}`,
  // SUPERADMIN
  superadmins: {
    root: `${root}${superadmins}/*`,
    short: `${root}${superadmins}`,
  },
  superadminViewShort: `${superadminIdParam}`,
  superadminEdit: {
    short: `${edit}/${superadminIdParam}`,
    full: `${root}${superadmins}/${edit}`, // + superadmin id
  },
  superadminCreate: {
    full: `${root}${superadmins}/${create}`,
    short: `${create}`,
  },
  // USER
  users: {
    root: `${root}${users}/*`,
    short: `${root}${users}`,
  },
  usersWithSubs: {
    full: `${root}${users}/${withSubs}`,
    short: `${withSubs}`,
  },
  userCreate: {
    full: `${root}${users}/${create}`,
    short: `${create}`,
  },
  userEdit: {
    short: `${edit}/${userIdParam}`,
    full: `${root}${users}/${edit}`, // + user id
  },
  userViewShort: `${userIdParam}`,
  // TIPSTER
  tipsters: {
    root: `${root}${tipsters}/*`,
    short: `${root}${tipsters}`,
  },
  tipsterCreate: {
    full: `${root}${tipsters}/${create}`,
    short: `${create}`,
  },
  tipsterEdit: {
    short: `${edit}/${tipsterIdParam}`,
    full: `${root}${tipsters}/${edit}`, // + tipster id
  },
  tipsterViewShort: `${tipsterIdParam}`,
  // ADVERT
  adverts: {
    root: `${root}${advert}/*`,
    short: `${root}${advert}`,
  },
  advertCreate: {
    full: `${root}${advert}/${create}`,
    short: `${create}`,
  },
  advertEdit: {
    short: `${edit}/${advertIdParam}`,
    full: `${root}${advert}/${edit}`, // + advert id
  },
  advertViewShort: `${advertIdParam}`,
  // CHANNEL
  channels: {
    root: `${root}${channel}/*`,
    short: `${root}${channel}`,
  },
  channelCreate: {
    full: `${root}${channel}/${create}`,
    short: `${create}`,
  },
  channelEdit: {
    short: `${edit}/${channelIdParam}`,
    full: `${root}${channel}/${edit}`, // + channel id
  },
  channelViewShort: `${channelIdParam}`,
  // SERVICES
  services: {
    root: `${root}${services}/*`,
    short: `${root}${services}`,
  },
  serviceCreate: {
    full: `${root}${services}/${create}`,
    short: `${create}`,
  },
  serviceEdit: {
    short: `${edit}/${serviceIdParam}`,
    full: `${root}${services}/${edit}`, // + service id
  },
  serviceViewShort: `${serviceIdParam}`,
  // NOTIFICATIONS
  notifications: {
    short: `${root}${notifications}`,
  },
  // AFFILIATE LINKS
  affiliateLinks: {
    root: `${root}${affiliateLinks}/*`,
    short: `${root}${affiliateLinks}`,
  },
  affiliateLinkCreate: {
    full: `${root}${affiliateLinks}/${create}`,
    short: `${create}`,
  },
  affiliateLinkEdit: {
    short: `${edit}/${affiliateLinkIdParam}`,
    full: `${root}${affiliateLinks}/${edit}`, // + affiliate link id
  },
  affiliateLinkViewShort: `${affiliateLinkIdParam}`,
  // ANALYTICS
  analytics: {
    root: `${root}${analytics}/*`,
    short: `${root}${analytics}`,
  },
  // SUBSCRIPTIONS
  subscriptions: {
    root: `${root}${subscriptions}/*`,
    short: `${root}${subscriptions}`,
  },
  subscriptionCreate: {
    full: `${root}${subscriptions}/${create}`,
    short: `${create}`,
  },
  subscriptionEdit: {
    short: `${edit}/${subscriptionIdParam}`,
    full: `${root}${subscriptions}/${edit}`, // + subscription id
  },
  subscriptionViewShort: `${subscriptionIdParam}`,
  freeTrialEdit: {
    short: `${freeTrial}/${edit}/${subscriptionIdParam}`,
    full: `${root}${subscriptions}/${freeTrial}/${edit}`, // + subscription id
  },
  freeTrialCreate: {
    short: `${freeTrial}/${create}/${serviceIdParam}`,
    full: `${root}${subscriptions}/${freeTrial}/${create}`, // + service id
  },
  // FINANCIAL
  financial: {
    root: `${root}${financial}/*`,
    short: `${root}${financial}`,
  },
  // Tipster Invite tipsterInvite
  tipsterInvite: {
    root: `${root}${tipsterInvite}/*`,
    short: `${root}${tipsterInvite}`,
  },
}
