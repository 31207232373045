import { proxy } from 'valtio'

import { EditingServiceStateType } from '@app/types'

const initialState: EditingServiceStateType = {
  serviceId: null
}

export const EditingServiceState = proxy<EditingServiceStateType>(initialState)

export const updateEditingServiceState = (state: Partial<EditingServiceStateType>) => {
  if (state) {
    Object.keys(state).forEach(key => {
      EditingServiceState[key] = state[key]
    })
  }
}

export const resetEditingServiceState = () => updateEditingServiceState(initialState)