import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { ROUTES } from '@app/constants'
import SubscriptionListPage from './SubscriptionListPage'
import SubscriptionViewPage from './SubscriptionViewPage'
import SubscriptionCreatePage from './SubscriptionCreatePage'
import FreeTrialCreatePage from './FreeTrialCreatePage'

interface SubscriptionRoutesProps {}

const SubscriptionRoutes: FC<SubscriptionRoutesProps> = () => (
  <Routes>
    <Route index element={<SubscriptionListPage/>}/>
    <Route path={ROUTES.subscriptionEdit.short} element={<SubscriptionCreatePage editMode/>}/>
    <Route path={ROUTES.subscriptionCreate.short} element={<SubscriptionCreatePage/>}/>
    <Route path={ROUTES.subscriptionViewShort} element={<SubscriptionViewPage/>}/>
    <Route path={ROUTES.freeTrialCreate.short} element={<FreeTrialCreatePage/>}/>
    <Route path={ROUTES.freeTrialEdit.short} element={<FreeTrialCreatePage/>}/>
  </Routes>
)

export default SubscriptionRoutes
