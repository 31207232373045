import { FC, memo, useMemo } from 'react'
import styled from 'styled-components'

import { CalendarHelper } from '@app/helpers'

interface CalendarGridProps {
  currentDate: Date | null;
  selectedMonth: number;
  selectedYear: number;
  onlyPast?: boolean;
  onlyFuture?: boolean;
  endDate?: Date;
  startDate?: Date;
  className?: string;
  onSelect: (date: Date) => void;
}

const CalendarGrid: FC<CalendarGridProps> = ({
  currentDate,
  selectedMonth,
  selectedYear,
  onlyPast,
  onlyFuture,
  className,
  startDate,
  endDate,
  onSelect,
}) => {
  const dateValues = useMemo(() => {
    const calendarMonth = selectedMonth || (currentDate && +currentDate.getMonth() + 1) || CalendarHelper.THIS_MONTH
    const calendarYear = selectedYear || currentDate?.getFullYear() || CalendarHelper.THIS_YEAR

    return CalendarHelper.getGridDates(calendarMonth, calendarYear)
  }, [ currentDate, selectedMonth, selectedYear ])

  return (
    <StyledGrid className={className}>
      <>
        {
          CalendarHelper.WEEK_DAYS_2_LETTERS.map((weekDay) => {
            return (
              <StyledCalendarCell className="week-days" key={weekDay}>{weekDay}</StyledCalendarCell>
            )
          })
        }
      </>
      <>
        {
          dateValues.map(gridDay => {
            const isCurrentMonth = Number(gridDay.month) === selectedMonth
            const isCurrentDay = CalendarHelper.isSameDay(currentDate, gridDay.date)
            const allowedDate = CalendarHelper.isSameDay(gridDay.date) ||
              ((!onlyPast || CalendarHelper.isPast(gridDay.date)) &&
              (!onlyFuture || CalendarHelper.isFuture(gridDay.date))) &&
              (!startDate || CalendarHelper.isFuture(gridDay.date, startDate)) &&
              (!endDate || CalendarHelper.isPast(gridDay.date, endDate))
            const styles = `${isCurrentMonth && allowedDate ? '' : 'other-month'} 
                ${isCurrentDay ? 'current-day' : ''}`
            const selectDay = () => {
              if (isCurrentMonth && allowedDate) {
                onSelect(new Date(gridDay.date))
              }
            }

            return (
              <StyledCellContainer className="vb-flex-row-c-c" key={`${gridDay.day}-${gridDay.month}-${gridDay.year}`}>
                <StyledCalendarCell
                  className={styles}
                  onClick={selectDay}
                >
                  {gridDay.day}
                </StyledCalendarCell>
              </StyledCellContainer>
            )
          })
        }
      </>
    </StyledGrid>
  )
}

const StyledGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`

const StyledCellContainer = styled.div`
  cursor: pointer;
`

const StyledCalendarCell = styled.span`
  text-align: center;
  align-self: center;
  user-select: none;
  padding: .5rem;
  font-size: var(--font-size-normal);
  
  &:hover {
    background-color: var(--color-gray-light);
  }
  &.week-days {
    font-weight: bold;
  }
  &.other-month {
    color: var(--color-gray-dark);
  }
  &.current-day {
    font-weight: bold;
    background-color: var(--color-chosen-date);
    border-radius: 50%;
  }
`

export default memo(CalendarGrid)
