import { FC, MutableRefObject, ReactNode } from 'react'
import styled from 'styled-components'

interface ScrollContainerProps {
  className?: string
  scrollRef?: MutableRefObject<HTMLDivElement>
  children: ReactNode
}

const ScrollContainer: FC<ScrollContainerProps> = ({ className, scrollRef, children }) => {
  return (<StyledScrollContainer className={className} ref={scrollRef}>{children}</StyledScrollContainer>)
}

const StyledScrollContainer = styled.div`
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;  // IE and Edge
  scrollbar-width: none;  // Firefox
  
  &::-webkit-scrollbar {
    display: none;
  }
`

export default ScrollContainer
