import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import { GetCountriesDocument } from '@app/graphql'
import { SelectElementType } from '@app/types'

export const useGetCountryById = () => {
  const [ countriesSelect, setCountriesSelect ] = useState<SelectElementType[]>([])
  const [ countriesMap, setCountriesMap ] = useState<Map<string, string>>(new Map())
  const [ loading, setLoading ] = useState(true)
  const [ getCountries ] = useLazyQuery(GetCountriesDocument)

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true)
        const { data } = await getCountries()
        if (data?.countries?.length) {
          const newCountriesMap = new Map()
          const newCountriesSelect: SelectElementType[] = []
          data.countries.forEach(c => {
            newCountriesMap.set(c.id, c.name)
            newCountriesSelect.push({ value: c.id, label: c.name })
          })
          setCountriesMap(newCountriesMap)
          setCountriesSelect(newCountriesSelect)
        }
      } finally {
        setLoading(false)
      }
    }
    load()
  }, [])

  const getCountryById = (countryId: string) => countriesMap.get(countryId) || countryId || ''

  return { getCountryById, loading, countriesMap, countriesSelect }
}
