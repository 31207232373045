import { FC } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { Close as CloseIcon } from '@mui/icons-material'
import { useSnapshot } from 'valtio'
import { CurrentUserState } from '@app/storage'

interface TableHeaderProps {
  title: string
  createText?: string
  resetText?: string
  createRoute?: string
  handleClose?:()=> void
}

const TableHeader: FC<TableHeaderProps> = ({ title, createText, resetText, createRoute, handleClose }) => {
  const { isAdmin } = useSnapshot(CurrentUserState)
  return (
    <Box className="vb-flex-row-spb-c" sx={{ p: 1 }}>
      <Typography variant="h4" component="h4">{title}</Typography>
      <Box>
        {
          isAdmin && createText && createRoute ?
            <Link to={createRoute} className="vb-font not-decor">
              <Button variant="contained">{createText}</Button>
            </Link> :
            null
        }
        {
          resetText ?
            <Button sx={{ color: 'black',paddingLeft:5, paddingRight:5, marginLeft:3, marginRight:5 }}
              variant="contained" onClick={handleClose}>
              {resetText}
              <Box className="vb-flex-row-c-c" sx={{ ml: 1, color: 'black' }}>
                <CloseIcon/>
              </Box>
            </Button>
            : null
        }
      </Box>
    </Box>
  )
}

export default TableHeader
