import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { ROUTES } from '@app/constants'
import SuperadminCreatePage from './SuperadminCreatePage'
import SuperadminViewPage from './SuperadminViewPage'
import SuperadminListPage from './SuperadminListPage'

interface SuperadminRoutesProps {}

const SuperadminRoutes: FC<SuperadminRoutesProps> = () => (
  <Routes>
    <Route index element={<SuperadminListPage/>}/>
    <Route path={ROUTES.superadminEdit.short} element={<SuperadminCreatePage editMode/>}/>
    <Route path={ROUTES.superadminCreate.short} element={<SuperadminCreatePage/>}/>
    <Route path={ROUTES.superadminViewShort} element={<SuperadminViewPage/>}/>
  </Routes>
)

export default SuperadminRoutes
