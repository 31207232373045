import { FC } from 'react'
import { Select, MenuItem, SelectChangeEvent } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ALL_VALUES } from '@app/constants'
import { SelectElementType } from '@app/types'

interface TableSelectProps {
  elements: SelectElementType[]
  withAllValue?: boolean
  multiple?: boolean
  value: string
  onChange: (newValue: string | string[]) => void
}

const TableSelect: FC<TableSelectProps> = ({
  elements,
  withAllValue,
  multiple,
  value,
  onChange,
}) => {
  const { t } = useTranslation([ 'translation', 'forms' ])

  const handleChange = (e: SelectChangeEvent) => {
    if (e && onChange) {
      e.preventDefault()
      onChange(e.target?.value)
    }
  }

  return (
    <Select
      value={value}
      multiple={multiple}
      onChange={handleChange}
    >
      {
        withAllValue &&
          <MenuItem value={ALL_VALUES}>
            {t('All')}
          </MenuItem>
      }
      {
        elements.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item?.label || item.value?.toUpperCase?.()}
          </MenuItem>
        ))
      }
    </Select>
  )
}

export default TableSelect
