import { FC, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import {
  ChannelStatus,
  ChannelType,
  GetChannelsForTipsterPageDocument,
  GetCountriesDocument,
  TipsterStatus,
  UpdateTipsterChannelsDocument,
  UpdateUserDocument,
  UserRoles,
} from '@app/graphql'
import { PageLayout, TableTemplate } from '@app/components'
import { TableHelper } from '@app/helpers'
import { ROUTES, TableTipsterKeyField } from '@app/constants'
import { useLoadUsers } from '@app/hooks'
import {
  TableChangeItemValue,
  TableField,
  TableFilterFlags,
} from '@app/types'
import { CurrentUserState } from '@app/storage'
import { useSnapshot } from 'valtio'

interface TipsterListPageProps {}

const TipsterListPage: FC<TipsterListPageProps> = () => {
  const [ loading, setLoading ] = useState(false)
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const {
    loading: tipstersLoading,
    sortOptions,
    onSort,
    usersData,
    onPaginate,
    pageNumber,
    rowsPerPage,
    filterOptions,
    onFilter,
    fromChannelWithId,
    totalCount,
    loadUsers
  } = useLoadUsers({ role: UserRoles.Tipster })
  /* QUERIES AND MUTATIONS */
  const [ getCountries, { data: countriesData, loading: countriesLoading } ] =
    useLazyQuery(GetCountriesDocument)
  const [ getChannels, { data: channelsData, loading: channelsLoading } ] =
    useLazyQuery(GetChannelsForTipsterPageDocument, {
      fetchPolicy: 'network-only',
    })

  const [ updateUser ] = useMutation(UpdateUserDocument)
  const [ updateChannels ] = useMutation(UpdateTipsterChannelsDocument)
  const { isAdmin } = useSnapshot(CurrentUserState)

  useEffect(() => {
    getCountries()
    getChannels({
      variables: {
        filter: { channelStatus: ChannelStatus.Active },
        sort: {},
        limit: 10000,
        skip: 0,
      },
    })
  }, [])


  const onChange: TableChangeItemValue = async (field, id, newValue) => {
    try {
      if (!field || !id) return
      setLoading(true)
      let shouldUpdate = false
      switch (field) {
        case TableField.tipsterStatus: {
          const result = await updateUser({
            variables: {
              id,
              input: { tipsterStatus: newValue as TipsterStatus },
            },
          })
          shouldUpdate = Boolean(result.data.userUpdate.id)
          break
        }
        case TableField.country: {
          const result = await updateUser({
            variables: {
              id,
              input: { countryId: newValue as string },
            },
          })
          shouldUpdate = Boolean(result.data.userUpdate.id)
          break
        }
        case TableField.tipsterChannels: {
          const result = await updateChannels({
            variables: {
              channelIds: newValue,
              tipsterId: id,
            },
          })
          shouldUpdate = result.data.updateTipsterChannels.result
          break
        }
      }
      if (shouldUpdate) loadUsers()
    } finally {
      setLoading(false)
    }
  }

  const columns = useMemo(() => TableHelper.parseTipsterFields(isAdmin), [])
  const rows = useMemo(() => {
    if (usersData) {
      return usersData.map((user) => ({
        ...user,
        country: user.countryId,
        [TableField.tipsterChannels]: user.channelsWhereUserIsTipster?.map(
          (ch) => ch.id
        ),
      }))
    }
    return []
  }, [ usersData ])

  const selectElements = useMemo(
    () => ({
      elements: {
        [TableField.tipsterStatus]: Object.values(TipsterStatus).map((s) => ({
          value: s,
        })),
        [TableField.country]:
          countriesData?.countries?.map((c) => ({
            value: c.id,
            label: c.name,
          })) || [],
        [TableField.tipsterChannels]:
          channelsData?.channels?.channels
            ?.filter((c) => c.channelType === ChannelType.Private)
            ?.map((c) => ({ value: c.id, label: c.channelName })) || [],
      },
      multiple: {
        [TableField.tipsterChannels]: true,
      },
    }),
    [ countriesData, channelsData ]
  )
  const filterFlags: TableFilterFlags = fromChannelWithId
    ? null
    : {
      status: true,
      dateRange: true,
      search: true,
    }

  return (
    <PageLayout
      title={t('tipsterList', { ns: 'pageTitles' })}
      loading={
        tipstersLoading || loading || countriesLoading || channelsLoading
      }
    >
      <TableTemplate
        columns={columns}
        rows={rows}
        selectElements={selectElements}
        fieldKey={TableTipsterKeyField}
        title={t('tipsterList')}
        createText={null}
        createRoute={ROUTES.tipsterCreate.full}
        filterFlags={filterFlags}
        sortOptions={sortOptions}
        filterOptions={filterOptions}
        statuses={Object.values(TipsterStatus)}
        totalAmount={totalCount}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        onSort={onSort}
        onFilter={onFilter}
        onPaginate={onPaginate}
        onChange={onChange}
      />
    </PageLayout>
  )
}
export default TipsterListPage
