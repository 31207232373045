import { FC } from 'react'
import { useSnapshot } from 'valtio'
import { Navigate } from 'react-router-dom'

import { CurrentUserState } from '@app/storage'
import { TipsterStatus, UserRoles } from '@app/graphql'
import { ROUTES } from '@app/constants'
import { AuthHelper } from '@app/helpers'

interface ProtectedRouteProps {
  element: JSX.Element
  isLogin?: boolean
  onlyAdmin?: boolean
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  element,
  isLogin,
  onlyAdmin,
}) => {
  const { id, role, tipsterStatus } = useSnapshot(CurrentUserState)
  const allowedRole = AuthHelper.checkAllowedRole(role as UserRoles, tipsterStatus as TipsterStatus)

  if ((!id || !allowedRole) && !isLogin) {
    return <Navigate to={ROUTES.root} replace/>
  } else if (isLogin && id && allowedRole) {
    return <Navigate to={ROUTES.channels.short} replace/>
  } else if (role !== UserRoles.Admin && onlyAdmin) {
    return <Navigate to={ROUTES.users.short} replace/>
  }

  return element
}

export default ProtectedRoute
