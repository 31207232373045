import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { DialogConfirm } from '@app/ui'
import { useBlocker } from './useBlocker'
import { Blocker, Transition } from 'history'
import { useTranslation } from 'react-i18next'

export const useCallbackPrompt = (when: boolean) => {
  const [ lastLocation, setLastLocation ] = useState<Transition>(null)
  const [ showPrompt, setShowPrompt ] = useState(false)
  const [ confirmedNavigation, setConfirmedNavigation ] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation([ 'translation' ])

  useEffect(() => {
    let path = lastLocation?.location?.pathname
    if (path && lastLocation?.location?.search) path += lastLocation.location.search

    if (confirmedNavigation && path) navigate(path)
  }, [ confirmedNavigation, lastLocation ])

  const handleBlockedNavigation: Blocker = (nextLocation) => {
    if (when && !confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
      setLastLocation(nextLocation)
      setShowPrompt(true)
      return false
    }
    return true
  }
  useBlocker(handleBlockedNavigation, when && !confirmedNavigation)
  const onCancel = () => setShowPrompt(false)
  const onConfirm = () => {
    setShowPrompt(false)
    setConfirmedNavigation(true)
  }

  const dialog = (
    <DialogConfirm
      open={showPrompt}
      header={t('leavePage')}
      message={t('lostChangesIfLeave')}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  )

  return { dialog }
}
