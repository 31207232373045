import { ChangeEvent, FC, useMemo, useState, Fragment, FormEvent } from 'react'
import { Checkbox, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PermissionItem } from '@app/types'
import { ActionsBlock } from '@app/ui'
import { UserPermission } from '@app/graphql'

interface PermissionTabProps {
  permissions: PermissionItem[]
  list: UserPermission[]
  onSubmit: (list: UserPermission[]) => void
}

const PermissionTab: FC<PermissionTabProps> = ({
  permissions,
  list,
  onSubmit,
}) => {
  const [ checkedList, setCheckedList ] = useState<Set<UserPermission>>(new Set(list))
  const { t } = useTranslation([ 'translation', 'forms' ])
  const navigate = useNavigate()

  const permList = useMemo(() => {
    return permissions.map(p => ({
      ...p,
      checked: checkedList.has(p.id),
    }))
  }, [ checkedList, permissions ])

  const onChange = (id: UserPermission) => (e: ChangeEvent<HTMLInputElement>) => setCheckedList(prevState => {
    if (e.target.checked) {
      prevState.add(id)
    } else {
      prevState.delete(id)
    }

    return new Set(prevState)
  })
  const onCancel = () => navigate(-1)
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSubmit(Array.from(checkedList))
  }

  return (
    <Grid
      component="form"
      container
      spacing={2}
      sx={{ mt: 2 }}
      onSubmit={handleSubmit}
    >
      {
        permList.map(p => (
          <Fragment key={p.id}>
            <Grid item xs={6} className="vb-flex-row-fe-c">
              {t(p.name, { ns: 'forms' })}
            </Grid>
            <Grid item xs={6} className="vb-flex-row-fs-c vb-font xBig w700 black">
              <Checkbox checked={p.checked} onChange={onChange(p.id)}/>
            </Grid>
          </Fragment>
        ))
      }
      <Grid item xs={12} className="vb-flex-row-c-c">
        <ActionsBlock
          withSubmit
          onCancel={onCancel}
        />
      </Grid>
    </Grid>
  )
}

export default PermissionTab
