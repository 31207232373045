import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Box } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import * as yup from 'yup'

import { FormElementCreation, PageLayout } from '@app/components'
import { CreateAdminValues, FormFieldDataType, FormFieldType } from '@app/types'
import { ROUTES } from '@app/constants'
import { GetUserDocument, UpdateUserDocument, UserRoles } from '@app/graphql'
import { DialogResult } from '@app/ui'
import { useCallbackPrompt } from '@app/hooks'
import { FormHelper } from '@app/helpers'

const emailField = 'email'
const firstNameField = 'firstName'
const lastNameField = 'lastName'

interface SuperadminCreatePageProps {
  editMode?: boolean
}

const SuperadminCreatePage: FC<SuperadminCreatePageProps> = ({ editMode }) => {
  const [ blockLeaving, setBlockLeaving ] = useState(true)
  const [ successMessage, setSuccessMessage ] = useState<string>(null)
  const [ errorMessage, setErrorMessage ] = useState<string>(null)
  const [ loading, setLoading ] = useState(true)
  const { superadminId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation([ 'translation', 'pageTitles' ])
  const { dialog } = useCallbackPrompt(blockLeaving)
  const [ getUser, { data: userData, loading: userLoading, error: userError } ] = useLazyQuery(GetUserDocument)
  const [ updateAdmin ] = useMutation(UpdateUserDocument)

  useEffect(() => {
    if (editMode) getUser({ variables: { id: superadminId } })
    setLoading(false)
  }, [])

  const onSubmit = async (values: CreateAdminValues) => {
    try {
      setLoading(true)
      setBlockLeaving(false)
      if (editMode && userData?.user?.role === UserRoles.Admin) {
        const { data, errors } = await updateAdmin({
          variables: {
            id: userData?.user?.id,
            input: {
              emailAddress: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
            },
          },
        })
        if (data?.userUpdate?.id && !errors) {
          setSuccessMessage(t('updatingSuccess'))
        } else {
          throw new Error()
        }
      } else {
        // todo: add creation of new admin (awaiting backend)
        throw new Error()
      }
    } catch (e) {
      setBlockLeaving(true)
      setErrorMessage(editMode ? t('updatingFailure') : t('creationFailure'))
    } finally {
      setLoading(false)
    }
  }
  const onCancel = () => navigate(-1)
  const onCloseDialogResult = () => {
    const success = Boolean(successMessage)
    setSuccessMessage(null)
    setErrorMessage(null)

    if (success) {
      navigate(ROUTES.superadmins.short)
    }
  }

  const pageTitle = editMode ? t('editSuperadmin', { ns: 'pageTitles' }) :
    t('createSuperadmin', { ns: 'pageTitles' })
  const title = editMode ? t('editSuperadmin') : t('createNewSuperadmin')
  const fields: FormFieldDataType[] = [
    {
      name: emailField,
      label: t('email'),
      type: FormFieldType.text,
      initValue: userData?.user?.emailAddress || '',
      validation: FormHelper.getEmailSchemaField(),
      required: true,
    },
    {
      name: firstNameField,
      label: t('firstName'),
      type: FormFieldType.text,
      initValue: userData?.user?.firstName || '',
      validation: yup.string().max(100, t('max100LengthError', { ns: 'forms' })),
    },
    {
      name: lastNameField,
      label: t('lastName'),
      type: FormFieldType.text,
      initValue: userData?.user?.lastName || '',
      validation: yup.string().max(100, t('max100LengthError', { ns: 'forms' })),
    },
  ]

  if (editMode && (!superadminId || userError)) navigate(-1)

  return (
    <PageLayout title={pageTitle} loading={(editMode && userLoading) || loading}>
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h4" gutterBottom>
          {title}
        </Typography>
        <Box sx={{ width: '700px' }}>
          <FormElementCreation
            fields={fields}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </Box>
      </Box>

      {/* DIALOG */}
      <DialogResult
        open={Boolean(successMessage || errorMessage)}
        type={errorMessage ? 'error' : 'success'}
        message={errorMessage || successMessage}
        onClose={onCloseDialogResult}
      />
      { dialog }
    </PageLayout>
  )
}

export default SuperadminCreatePage
