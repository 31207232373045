import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { ROUTES } from '@app/constants'
import ChannelListPage from './ChannelListPage'
import ChannelCreatePage from './ChannelCreatePage'
import ChannelViewPage from './ChannelViewPage'

interface ChannelRoutesProps {
  isAdmin: boolean
}

const ChannelRoutes: FC<ChannelRoutesProps> = (isAdmin) => {

  return <Routes>
    <Route index element={<ChannelListPage/>}/>
    {
      isAdmin?
        (
          <>
            <Route path={ROUTES.channelEdit.short} element={<ChannelCreatePage editMode/>}/>
            <Route path={ROUTES.channelCreate.short} element={<ChannelCreatePage/>}/>
          </>
        ): null
    }
    <Route path={ROUTES.channelViewShort} element={<ChannelViewPage/>}/>
  </Routes>
}


export default ChannelRoutes
