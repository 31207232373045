import { FC, useEffect } from 'react'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormHelperText,
  SxProps,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { ALL_VALUES } from '@app/constants'

interface CurrencySelectProps {
  value?: string | string[];
  withAll?: boolean;
  sxProps?: SxProps;
  formik?: ReturnType<typeof useFormik<any>>;
  fieldName?: string;
  required?: boolean;
  onChange?: (e: SelectChangeEvent) => void;
  multiple?: boolean;
  currencies: any[];
  labelId: string;
  labelTitle: string;
}

const CurrencySelect: FC<CurrencySelectProps> = ({
  withAll,
  value,
  sxProps,
  formik,
  fieldName,
  required,
  onChange,
  multiple,
  currencies,
  labelId,
  labelTitle,
}) => {
  const { t } = useTranslation([ 'forms' ])

  const withError = formik
    ? formik.touched[fieldName] && Boolean(formik.errors[fieldName])
    : false
  const helperText = formik
    ? formik.touched[fieldName] && t(formik.errors[fieldName] as string)
    : ''
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  return (
    <FormControl fullWidth error={withError} sx={sxProps} required={required}>
      <InputLabel id={labelId}>{t(labelTitle, { ns: 'forms' })}</InputLabel>
      <Select
        id={fieldName}
        name={fieldName}
        labelId={labelId}
        label={t(labelTitle, { ns: 'forms' })}
        value={
          formik && fieldName
            ? formik.values[fieldName]
            : multiple
              ? [ ...(value as string[]) ]
              : value
        }
        error={withError}
        required={required}
        multiple={multiple}
        onChange={formik ? formik.handleChange : onChange ? onChange : null}
        onBlur={formik ? formik.handleBlur : null}
        renderValue={(selected: unknown) => {
          if (Array.isArray(selected)) {
            return currencies
              ?.filter(({ node }) => selected.includes(node?.id))
              .map(( { node:{ name } } ) => name)
              .join(', ')
          }
        }}
        MenuProps={MenuProps}
      >
        {withAll && <MenuItem value={ALL_VALUES}>{t('All')}</MenuItem>}
        {currencies?.map(({ node: { name, id } }) => {
          return !multiple ? (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ) : (
            <MenuItem key={id} value={id}>
              <Checkbox checked={value.includes(id)} />
              <ListItemText primary={name} />
            </MenuItem>
          )
        })}
      </Select>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  )
}

export default CurrencySelect
